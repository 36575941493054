export const state = {
  patternList: []
}
export const getters = {
  getPatternList(state) {
    return state.patternList
  }
}
export const mutations = {
  setPatternList(state, patternArray) {
    state.patternList = patternArray
  },
  addPattern(state, pattern) {
    state.patternList.push(pattern)
  },
  updatePattern(state, pattern) {
    for (let item of state.patternList) {
      if (item.patternName == pattern.patternName) {
        item = pattern
      }
    }
  }
}
export const actions = {
  setPatternList({ commit }, patternArray) {
    commit('setPatternList', patternArray)
  },
  addPattern({ commit }, pattern) {
    commit('addPattern', pattern)
  },
  updatPattern({ commit }, pattern) {
    commit('updatePattern', pattern)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
