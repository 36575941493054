import TokenUtils from '@/services/auth/Token'
import ConfigUtil from '@/services/common/Config'
import ServiceExec from '@/common/utils/ServiceExec'
import axios from 'axios'

const API_URL = ConfigUtil.getServiceUrl() + 'drone/'

class DroneUtils extends ServiceExec {
  async createDrone(droneItem) {
    const token = await TokenUtils.getAccessToken()
    const result = await axios({
      method: 'POST',
      url: API_URL,
      data: { info: droneItem, value: droneItem },
      headers: { gcsAcToken: token }
    })
    return result
  }

  async updateDrone(droneItem) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'PUT',
        url: API_URL,
        headers: { gcsAcToken: token },
        data: {
          info: droneItem.info,
          value: droneItem.value
        }
      })

      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  // hud에서 사용
  updateDroneByType(droneItem) {
    return new Promise(function(resolve, reject) {
      TokenUtils.getAccessToken().then(token => {
        axios({
          method: 'PUT',
          url: API_URL + droneItem.type,
          headers: { gcsAcToken: token },
          data: {
            info: droneItem.info,
            value: droneItem.value,
            type: droneItem.type
          }
        })
          .then(function(res) {
            resolve(res)
          })
          .catch(function(err) {
            reject(err)
          })
      })
    })
  }

  async deleteDrone(deleteDrone) {
    try {
      const token = await TokenUtils.getAccessToken()
      await axios({
        method: 'DELETE',
        url: API_URL,
        headers: { gcsAcToken: token },
        params: {
          droneItem: deleteDrone
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  async setCurrentPattern(data, drone) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        url: API_URL + 'pattern/' + drone.id,
        method: 'PUT',
        headers: { gcsAcToken: token },
        data: {
          name: data.name,
          path: data.path,
          drone: drone
        }
      })
      return result
    } catch (error) {
      console.log(error)
    }
  }

  async updateControlDrone(control, drone) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        url: API_URL + 'info/' + drone.id,
        method: 'PUT',
        headers: { gcsAcToken: token },
        data: {
          control: control,
          drone: drone
        }
      })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  async getDrones() {
    const token = await TokenUtils.getAccessToken()
    const droneList = await axios({
      method: 'GET',
      url: API_URL,
      headers: { gcsactoken: token }
    })
    return droneList.data
  }

  async getRfDrones(rfData) {
    const token = await TokenUtils.getAccessToken()
    const droneList = await axios({
      method: 'GET',
      url: API_URL + 'rfDrone',
      headers: { gcsactoken: token },
      params: {
        host: rfData.host,
        droneName: rfData.name
      }
    })
    return droneList.data
  }

  async getsubImg() {
    const token = await TokenUtils.getAccessToken()
    const droneList = await axios({
      method: 'POST',
      url: API_URL + 'subImg',
      headers: { gcsactoken: token },
      body: {
        droneName: 'SYNC_FC01',
        photogrfSn: '112'
      }
    })
    return droneList.data
  }

  async initDrone(mobiusData) {
    try {
      const token = await TokenUtils.getAccessToken()
      const userEmail = JSON.parse(localStorage.getItem('userData'))
      const result = await axios({
        url: API_URL + 'init',
        method: 'POST',
        headers: { gcsAcToken: token },
        data: {
          URL: mobiusData.host,
          ae: mobiusData.ae,
          u_e: userEmail.u_e
        }
      })
      return result
    } catch (error) {
      console.log(error)
      return error
    }
  }

  async unlinkDrone(mobiusData) {
    try {
      const token = await TokenUtils.getAccessToken()
      const userEmail = JSON.parse(localStorage.getItem('userData'))
      const result = await axios({
        url: API_URL + 'unlink',
        method: 'POST',
        headers: { gcsAcToken: token },
        data: {
          URL: mobiusData.host,
          ae: mobiusData.ae,
          u_e: userEmail.u_e
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async sendCaptureMessage(droneData, message) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'POST',
        headers: { gcsAcToken: token },
        url: API_URL + 'capture',
        data: { droneData: droneData, message: message }
      })
    } catch (error) {
      throw new Error(error)
    }
  }
}
// rtmp = 유콘
export default new DroneUtils()
