<template>
  <div class="modal-dim on">
    <!--'on 추가'-->
    <div class="set-window-wrap">
      <div class="set-window" style="width: 350px;">
        <div class="set-window-top">
          <div class="set-window-sub">
            <div class="sub">
              <img src="@/assets/static/images/lx-color.png" style="width: 45px; height: 20px" />
            </div>
            <div class="close" @click="fnCallBackCancel">
              <i class="i-close-26"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con" v-if="lxStep == 0">
          <div class="set-sub"><h3>LX 로그인</h3></div>
          <div class="sect">
            <div class="form column">
              <p class="form-sub">아이디</p>
              <label class="label">
                <input type="text" placeholder="id" v-model="lxUserId" />
              </label>
            </div>
            <div class="form column">
              <p class="form-sub">비밀번호*</p>
              <label class="label">
                <input type="password" placeholder="password" v-model="lxAuthKey" />
              </label>
            </div>
          </div>
        </div>
        <div class="set-window-con" v-if="lxStep == 1">
          <div class="set-sub"><h3>Photo Plan List</h3></div>
          <div class="sect" style="overflow: scroll">
            <div class="table" style="width: 895px;">
              <div class="table-sub">
                <div class="item" style="width: 0%"></div>
                <div class="item" style="width: 9%">
                  <span>촬영계획 번호</span>
                </div>
                <div class="item" style="width: 31%">
                  <span>촬영계획 이름</span>
                </div>
                <div class="item" style="width: 17%">
                  <span>조종사</span>
                </div>
                <div class="item" style="width: 16%">
                  <span>목적</span>
                </div>
              </div>
              <!--              해당 부분에 v-for 걸어서 사용하기 -->
              <template v-if="!$Util.isEmpty(photoPlanList)">
                <div class="table-con" v-for="plan in photoPlanList" :key="plan.potogrf_sn">
                  <div class="item" style="width: 0%">
                    <label class="checkbox">
                      <input type="radio" @change="radioChecked($event)" :value="plan.potogrf_sn" />
                      <span class="label"></span>
                    </label>
                  </div>
                  <div class="item" style="width: 9%">
                    <p>{{ plan.potogrf_sn }}</p>
                  </div>
                  <div class="item" style="width: 37%">
                    <p>{{ plan.potogrf_nm }}</p>
                  </div>
                  <div class="item" style="width: 17%">
                    <p>{{ plan.user_nm }}</p>
                  </div>
                  <div class="item" style="width: 15%">
                    <p>{{ plan.potogrf_purps }}</p>
                  </div>
                </div>
              </template>
              <div class="table-con" id="noData" v-else>
                Photogrf 목록이 없습니다.
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <button class="btn color" @click.prevent.stop="lxLogout" v-if="lxStep">
            로그아웃
          </button>
          <button class="btn color" @click.prevent.stop="fnConfirm">
            확인
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { useModalState } from '@/common/state/ModalState'
import axios from 'axios'
import $ from 'jquery'

export default {
  name: 'lx-login',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    param: {},
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert, fnBaseConfirm } = useModalState()

    const page = reactive({
      lxUserId: 'lxuser01',
      lxAuthKey: 'LX2022',
      lxStep: 0, // 0 = not logged in, 1 = logged in
      photoPlanList: [],
      checked: {}
    })
    watch(
      () => page.lxStep,
      val => {
        if (val == 0) {
          $('#wrap > div.modal-dim.on > div > div').css('width', '350px')
        } else if (val == 1) {
          $('#wrap > div.modal-dim.on > div > div').css('width', '950px')
        }
      }
    )
    onMounted(() => {
      const lxAcToken = localStorage.getItem(`${props.param.name}_lxAcToken`)
      if (lxAcToken) {
        page.lxStep = 1
        getPhotoPlan()
      }
    })

    const fnConfirm = async event => {
      try {
        // 로그인이 안되어 있을때
        if (page.lxStep == 0) {
          const isLoggedin = await lxLogin()
          if (isLoggedin.status == 200) {
            await getPhotoPlan()
          } else {
            await fnModalAlert('알람', '로그인을 할 수 없습니다. 다시 확인해주세요.', '')
          }
          return
          // 로그인 되어 있을때
        } else if (page.lxStep == 1) {
          let textParam
          const isFlightPlan = await flightInfo()
          if (isFlightPlan) {
            await startMission()
            fnCallBackCancel()
          } else {
            textParam = {
              title: '비행 계획',
              photo_no: page.checked.potogrf_sn,
              contents: '선택한 촬영계획은 비행계획이 없습니다.'
            }
            await fnBaseConfirm(textParam, true)
          }
          return
        }
      } catch (error) {
        throw new Error(error)
      }
    }
    const lxLogin = async () => {
      try {
        const droneInfo = props.param
        const result = await proxy.$LxUtils.login({
          id: page.lxUserId,
          password: page.lxAuthKey
        })
        localStorage.setItem(`${droneInfo.name}_lxAcToken`, result.data.lxAcToken)
        proxy.$store.dispatch('sse/saveLxAccount', { lxuser: page.lxUserId, droneName: props.param.name })
        return result
      } catch (error) {
        throw new Error(error)
      }
    }

    const lxLogout = async () => {
      try {
        const droneInfo = props.param
        await proxy.$LxUtils.logout(droneInfo)

        page.lxStep = 0
        localStorage.removeItem(`${props.param.name}_lxAcToken`)
        fnCallBackCancel()
      } catch (error) {
        console.log('logout error', error)
      }
    }

    const getPhotoPlan = async () => {
      try {
        const droneInfo = props.param
        const result = await proxy.$LxUtils.getPhotoPlan(droneInfo)

        page.lxStep = 1
        page.photoPlanList = result.data.photoPlanList
      } catch (error) {
        throw new Error(error)
      }
    }

    const flightInfo = async () => {
      try {
        const droneInfo = props.param
        const result = await proxy.$LxUtils.flightInfo(droneInfo, page.checked.potogrf_sn)

        if (result.status == 200) return true
        else return false
      } catch (error) {
        if (error) return false
      }
    }

    const startMission = async () => {
      try {
        const droneInfo = props.param
        const result = await proxy.$LxUtils.start(0, droneInfo.name, page.checked.potogrf_sn)
        return result
      } catch (error) {
        throw new Error(error)
      }
    }

    const fnCallBackCancel = event => {
      props.callBackCancel(proxy, event)
    }

    const radioChecked = event => {
      const selected = parseInt(event.target.value)
      page.checked = page.photoPlanList.find(item => (item.potogrf_sn = selected))
    }

    return {
      ...toRefs(page),
      fnConfirm,
      fnCallBackCancel,
      lxLogin,
      lxLogout,
      startMission,
      radioChecked,
      flightInfo
    }
  }
}
</script>

<style scoped></style>
