const text = {
  GEO: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: 'Geo Fence를 수정합니다',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  CONTROL: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 각 채널로 PWM 제어 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  ALTITUDE: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 고도 변경 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  INTEREST: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: `비행체에 지정한 위치를 관심 위치로 설정하도록<br>명령을 전송합니다.`,
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  RETURN: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 귀환 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  MODE: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 모드 변경 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  TURN: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 지정한 위치로 선회하도록 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  SPEED: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 속도 변경 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  START: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 시동 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  DEPART: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 이륙 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  MISSION2: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 임무 위치 기억하기 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  MISSION: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 임무 이어하기 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  AUTO: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 비행경로 다운로드 후<br> 자동 비행 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  STOP: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 정지 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  EXIT: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 종료 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  MOVE: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 지점 이동 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  LANDING: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 착륙 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  DROP: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 투하 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  SETTING: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '비행체에 파라미터 설정 명령을 전송합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  },
  PATTERN: {
    title: '명령 전송 확인',
    name: '기체명 위치',
    contents: '패턴을 설정합니다.',
    doneText: 'Run',
    cancelText: 'Cancel'
  }
}

export default text
