<template>
  <div class="order-tab">
    <components :tabMenu="`${tabMenu}`" :map="map" :layers="layers" :mainState="mainState" @close="fnCallBackCancel" @reset="resetSelectedWp" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import components from '@/components/common/modal/mode/components'
import { getCurrentInstance, reactive, toRefs } from 'vue'
export default {
  name: 'mode-index',
  components: { components },
  props: {
    map: {},
    layers: {},
    mainState: {}
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      tabMenu: props.mainState.tabMenu
    })
    const fnCallBackCancel = async val => {
      state.tabMenu = val
      proxy.$emit('update', state.tabMenu)
    }

    const resetSelectedWp = () => {
      proxy.$emit('reset')
    }

    return {
      ...toRefs(state),
      fnCallBackCancel,
      resetSelectedWp
    }
  }
}
</script>

<style scoped></style>
