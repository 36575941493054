<template>
  <div class="main-inner" style="z-index: 1200">
    <div class="order-wrap">
      <div class="order-tab">
        <div class="order-head">
          <div class="drone-select"></div>
          <div class="close" @click="fnCancel">
            <i class="i-close-20"></i>
          </div>
        </div>
        <div class="order-con">
          <div class="con-box">
            <div class="drone-tab">
              <object data="@/assets/static/images/i_drone.svg" class="red"></object>
              <span>명령전송</span>
            </div>
            <ul class="mode-list">
              <li v-for="(item, index) in 20" :key="index" class="mode-list-li tooltip" @click="fnModalAlertPopUp(item - 1)">
                <button class="btn grey">모달창: {{ index }}</button>
                <span class="tooltip-text">Tooltip text</span>
              </li>
            </ul>
          </div>
          <div class="btn-wrap">
            <button class="btn color" @click="fnCancel">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useModalState } from '@/common/state/ModalState'
import text from './text'
import { useStore } from 'vuex'
import { getCurrentInstance } from 'vue'

export default {
  name: 'extra-index',
  props: {
    index: {
      type: [String]
    }
  },
  setup() {
    const store = useStore()
    // const { proxy } = getCurrentInstance()
    const { fnModalConfirmCustom } = useModalState()
    const fnModalAlertPopUp = async index => {
      const textParams = text[index]
      await fnModalConfirmCustom(textParams, () => {})
    }
    const fnCancel = () => {
      store.dispatch('layout/closeModalAlertComponent', { index: 0 })
    }
    return {
      fnModalAlertPopUp,
      fnCancel
    }
  }
}
</script>
