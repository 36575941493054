<template>
  <!--우측 정보 모달-->
  <div class="notice-modal-wrap" v-if="routeLayout.rightNav">
    <div class="notice-modal" id="nav-top" v-if="routeLayout.rightNavTop">
      <div class="modal-head">
        <h2>공역정보</h2>
        <i class="i-close-20" @click="fnCancel('nav-top')"></i>
      </div>
      <div class="modal-con">
        <div class="checkbox-wrap">
          <label class="checkbox">
            <input type="checkbox" :checked="checked('noFlyZoneLayer')" @click="setAirSpaceLayer($event, 'noFlyZoneLayer')" />
            <span class="label"><span class="color pink"></span>비행 금지 구역</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" :checked="checked('flightRestrictedLayer')" @click="setAirSpaceLayer($event, 'flightRestrictedLayer')" />
            <span class="label"><span class="color deep-orange"></span>비행 제한 구역</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" :checked="checked('controlLayer')" @click="setAirSpaceLayer($event, 'controlLayer')" />
            <span class="label"><span class="color cyan"></span>관제권</span>
          </label>
          <label class="checkbox">
            <input
              type="checkbox"
              :checked="checked('lightVehiclePermissionedLayer')"
              @click="setAirSpaceLayer($event, 'lightVehiclePermissionedLayer')"
            />
            <span class="label"><span class="color blue-grey"></span>초경량비행장치 비행 공역</span>
          </label>
        </div>
        <div class="checkbox-wrap">
          <label class="checkbox">
            <input type="checkbox" :checked="checked('militaryOperationLayer')" @click="setAirSpaceLayer($event, 'militaryOperationLayer')" />
            <span class="label"><span class="color green"></span>군 작전 구역</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" :checked="checked('trainingZoneLayer')" @click="setAirSpaceLayer($event, 'trainingZoneLayer')" />
            <span class="label"><span class="color amber"></span>훈련 구역</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" :checked="checked('dangerZoneLayer')" @click="setAirSpaceLayer($event, 'dangerZoneLayer')" />
            <span class="label"><span class="color error"></span>위험 구역</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" :checked="checked('borderZoneLayer')" @click="setAirSpaceLayer($event, 'borderZoneLayer')" />
            <span class="label"><span class="color yellow"></span>경계 구역</span>
          </label>
          <label class="checkbox">
            <input type="checkbox" :checked="checked('geofenceLayer')" @click="setAirSpaceLayer($event, 'geofenceLayer')" />
            <span class="label"><span class="color purple"></span>지오펜스</span>
          </label>
        </div>
      </div>
    </div>
    <div class="notice-modal" id="nav-bot" v-if="routeLayout.rightNavBot">
      <div class="modal-head">
        <h2>기상 정보</h2>
        <i class="i-close-20" @click="fnCancel('nav-bot')"></i>
      </div>
      <div class="modal-con">
        <div class="weather-intel-wrap">
          <div class="weather-intel">
            <div class="ttl">좌표</div>
            <p style="margin-bottom:1.5rem;">
              <span>{{ weather.lon.toFixed(6) }}</span
              >˚ &nbsp;:&nbsp; <span>{{ weather.lat.toFixed(6) }}</span
              >˚
            </p>
          </div>
          <div class="weather-intel">
            <ul style="grid-template: unset">
              <li>
                <p class="ttl">자기장지수</p>
                <p class="value">{{ weather.magnetic }}</p>
              </li>
            </ul>
          </div>
          <div class="weather-intel">
            <div style="margin-bottom:1.5rem;">
              <span class="ttl">온도/습도 </span>
              <span
                >&nbsp;<span>{{ weather.temperature }}</span> , <span>{{ weather.humidity }}</span></span
              >
            </div>
            <div style="margin-bottom:1.5rem;">
              <span class="ttl">풍향/풍속 </span>
              <span class="value"
                >&nbsp;<i class="i_arrow"></i><span>{{ weather.windDirection }}</span> , <span>{{ weather.windSpeed }}</span></span
              >
            </div>
            <div>
              <span class="ttl">운량</span>
              <span class="cloud-gauge">
                <span class="cloud"><span class="gauge"></span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--//우측 정보 모달 끝!-->
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, toRefs } from 'vue'
const layoutState = () => {
  const { getters, dispatch } = useStore()
  const state = reactive({
    layoutList: getters['layout/getActiveLayers']
  })
  const routeLayout = computed(() => {
    return getters['layout/getRouteLayout']
  })
  const map = computed(() => {
    return getters['layout/getMap']
  })

  const weather = computed(() => {
    return getters['weather/getWeather']
  })

  const checked = name => {
    let result
    if (state.layoutList.find(item => item == name)) {
      result = true
    } else {
      result = false
    }
    return result
  }
  const fnCancel = async index => {
    const payload = {}
    if (index === 'nav-bot') {
      payload.rightNavBot = false
    } else {
      payload.rightNavTop = false
    }
    await dispatch('layout/updateRouteLayout', payload)
  }

  const setAirSpaceLayer = async (event, type) => {
    const mapVal = map.value
    let layer
    mapVal.getLayers().forEach(item => {
      if (item.get('name') == type) {
        layer = item
      }
    })
    const name = layer.get('name')

    if (event.srcElement.checked) {
      state.layoutList.push(name)
      await dispatch('layout/updateActiveLayers', state.layoutList)
      layer.setVisible(true)
    } else {
      state.layoutList = state.layoutList.filter(item => item !== name)
      await dispatch('layout/updateActiveLayers', state.layoutList)
      layer.setVisible(false)
    }
  }

  return {
    ...toRefs(state),
    weather,
    routeLayout,
    setAirSpaceLayer,
    map,
    fnCancel,
    checked
  }
}

export default {
  name: 'right-nav',
  description: '우측 네비게이션',
  emits: ['height'],
  setup() {
    const state = reactive({})

    return { ...layoutState(), ...toRefs(state) }
  }
}
</script>

<style scoped>
.notice-modal-wrap {
  top: 8rem;
}
.notice-modal {
  width: 22rem;
}
.notice-modal .modal-con .checkbox-wrap {
  padding: 0 0 1rem 1rem;
}
.notice-modal .modal-con .checkbox-wrap:last-child {
  padding-top: 1rem;
}
</style>
