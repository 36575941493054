<template>
  <div class="modal-dim" v-show-modal-alert style="z-index: 1000">
    <div class="set-window-wrap">
      <div class="set-window" style="background: white;">
        <div style="position: relative; background: #fafcfb; border-radius: 1.2rem;">
          <div class="set-window-sub" style="background: #0f5a2c; color: #fff; padding: 1.5rem 2rem; border-radius: 1.2rem 1.2rem 0 0;">
            <div class="sub">
              <i class="i-setting"></i>
              <h2>설정</h2>
            </div>
            <div class="close" @click="closeModal">
              <i class="i-close-26"></i>
            </div>
          </div>
          <div class="set-connect-area">
            <div class="form column">
              <p class="form-sub" style="color: #074d22; font-size: 16px;">HOST *</p>
              <label class="label">
                <input
                  type="text"
                  v-model="host"
                  placeholder="내용을 입력하세요"
                  :disabled="mobius_connected === 'connected'"
                  @keyup.enter="connectMobius"
                />
              </label>
            </div>
            <div class="form column">
              <p class="form-sub" style="color: #074d22; font-size: 16px;">GCS *</p>
              <label class="label">
                <input
                  type="text"
                  v-model="ae"
                  placeholder="내용을 입력하세요"
                  :disabled="mobius_connected === 'connected'"
                  @keyup.enter="connectMobius"
                />
              </label>
            </div>
            <div class="btn-wrap">
              <button class="btn color-light" style="background: #00a23e; color: white;"
              @click.prevent.stop="connectMobius" v-if="mobius_connected !== 'connected'">
                연결
              </button>
              <button class="btn color-light"  style="background: #00a23e; color: white;"
              @click.prevent.stop="disconnectMobius" v-else>
                연결해제
              </button>
            </div>
          </div>
          
          <ul class="set-menu" style="margin: 4rem 3.8rem 0; bottom: -3rem">
            <li>
              <p style="color: #074d22">드론 추가</p>
              <div class="set-ico" @click.prevent.stop="fnViewMore" v-if="viewMore">
                <a href="#" style="background: #00a23e;">
                  <i class="i-set-list"></i>
                </a>
              </div>

              <div class="set-ico active" v-else-if="!viewMore">
                <a href="#" class="on" @click.prevent.stop="fnViewMore">
                  <i class="i-set-close"></i>
                </a>
                <div class="set-detail" @click.prevent.stop="addDrone">
                  <a href="#" class="">
                    <i class="i-set-add"></i>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div> 
        <div class="set-window-con" v-if="showParam === 0" style="position:relative; background: white;">
          <div class="set-sub" style="border: none; color: #074d22; font-size: 16px; font-weight: 500;">드론 목록</div>
          <section class="sect drone-list">
            <div class="table" style="padding: 0;">
              <div class="table-sub" style="background: #dff0db; padding: 0; margin: 0; height: 6rem; color: #1c3727; border-top: 2px solid #6f9c80;">
                <div class="item">
                  <label class="checkbox">
                    <input type="checkbox" @click="checkAllDrone($event)" />
                    <span class="label"></span>
                  </label>
                </div>
                <div class="item">
                  <span>색상</span>
                </div>
                <div class="item">
                  <span>드론 이름</span>
                  <!-- <i class="i-toggle-down"></i> -->
                </div>
                <div class="item">
                  <span>Mobius ID</span>
                  <!-- <i class="i-toggle-down"></i> -->
                </div>
                <div class="item">
                  <span>FC 유형</span>
                </div>
                <div class="item">
                  <span>시스템 ID</span>
                  <!-- <i class="i-toggle-down"></i> -->
                </div>
                <div class="item">
                  <span>배터리 셀</span>
                </div>
                <div class="item"></div>
              </div>
              <template v-if="mobius_connected === 'connected' && !$Util.isEmpty(droneListArray)">
                <div class="table-con" v-for="drone in droneListArray" :key="drone.id">
                  <div class="item">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        v-model="drone.control"
                        :true-value="1"
                        :false-value="-1"
                        v-on:click="controlCheckToggle($event, drone)"
                      />
                      <span class="label"></span>
                    </label>
                  </div>
                  <div class="item">
                    <div class="color-box" :style="{ background: drone.color }"></div>
                  </div>
                  <div class="item">
                    <p>{{ drone.name }}</p>
                  </div>
                  <div class="item">
                    <p>{{ drone.id }}</p>
                  </div>
                  <div class="item">
                    <p>{{ drone.fcType }}</p>
                  </div>
                  <div class="item">
                    <p>{{ drone.sysId }}</p>
                  </div>
                  <div class="item">
                    <p>{{ drone.cell }}</p>
                  </div>
                  <div class="item">
                    <button class="btn-ico">
                      <i class="i-set-modify" @click="modifyDrone(drone)"></i>
                    </button>
                    <button class="btn-ico">
                      <i class="i-set-delete" @click="deleteDrone(drone)"></i>
                    </button>
                  </div>
                </div>
              </template>
              <div class="table-con" id="noData" v-else>
                No data Available
              </div>
            </div>
            <!-- 페이지네이션 -->
            <div class="sect-list">
              <button class="prev" @click="prevPage" :disabled="pageNum === 0"></button>
              <div class="list-num" v-for="item in pageCount" :key="item">
                <a href="#" @click.prevent.stop="fnChange(item - 1)" :class="{ on: pageNum + 1 === item }" :id="`page${item}`">{{ item }}</a>
              </div>
              <button :disabled="pageNum >= pageCount - 1" class="next" @click.prevent.stop="nextPage"></button>
            </div>
            <!-- 페이지네이션 -->
          </section>
        </div>
        <div class="set-window-con" v-else-if="showParam === 1">
          <div class="set-sub"><h3>설정</h3></div>
          <section class="sect drone-set">
            <div class="sect mt24">
              <div class="form column mb24">
                <p class="form-sub">Input Option</p>
                <label class="label">
                  <select name="" id="">
                    <option value="">기본옵션</option>
                  </select>
                </label>
              </div>
              <div class="form column">
                <p class="form-sub">Toggle Option</p>
                <div class="toggle">
                  <span class="ttl">OFF</span>
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="slider"></span>
                  </label>
                  <span class="ttl">ON</span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="set-window-con" v-else-if="showParam === 2"></div>
        <div class="set-window-con" v-else-if="showParam === 3"></div>
        <div class="set-window-con" v-else-if="showParam === 4"></div>
        <div class="set-window-con" v-else-if="showParam === 5">
          <div class="set-sub"><h3>RF 드론 설정</h3></div>
          <section class="sect drone-set">
            <div class="form column rfDrone" v-if="rfData['channel'] !== 'RF'">
              <p class="form-sub">드론 이름</p>
              <label class="label">
                <select v-model="selectedRFDrone">
                  <option v-for="item in droneList" :key="item" :value="{ name: item.name, host: host }">{{ item.name }}</option>
                </select>
              </label>
            </div>
          </section>
        </div>
        <div class="set-window-con" v-else-if="showParam === 6"></div>
        <div class="set-window-con" v-else-if="showParam === 7">
          <div class="set-sub">
            <h3>파라미터 설정</h3>
            <p class="valid">{{ paramSettingMsg }}</p>
          </div>
          <section class="sect drone-set">
            <div class="sect parameter">
              <div class="left">
                <div class="form column">
                  <p class="form-sub">드론 이름</p>
                  <label class="label">
                    <select v-model="selectedName">
                      <option v-for="item in parameterinfo.receivedDrones" :key="item" :value="item">{{ item }}</option>
                    </select>
                  </label>
                </div>
              </div>
              <div class="right">
                <div class="form column" v-if="selectedName">
                  <p class="form-sub">파라미터 검색</p>
                  <label class="label">
                    <vue3-simple-typeahead
                      id="typeahead_id"
                      placeholder="변경하고 싶은 파라미터 검색를 검색해주세요."
                      :items="parameterinfo.paramNameList"
                      :minInputLength="1"
                      :itemProjection="itemProjectionFunction"
                      @selectItem="selectItem"
                    >
                      <template #list-item-text="slot">
                        <span style="color: black; font-size: 10px" v-html="slot.boldMatchText(slot.itemProjection(slot.item))"></span>
                      </template>
                    </vue3-simple-typeahead>
                  </label>
                </div>
              </div>
            </div>
            <div class="sect parameter" v-if="selectedName">
              <div class="left">
                <div class="form column">
                  <p class="form-sub">파라미터 이름</p>
                  <label class="label">
                    <input type="text" v-model="modifyData.param_id" readonly placeholder="파라미터를 입력하세요." />
                  </label>
                </div>
                <p class="valid">변경하고 싶은 파라미터를 입력해주세요.</p>
              </div>
              <div class="right">
                <div class="form column">
                  <p class="form-sub">파라미터 값</p>
                  <label class="label">
                    <input type="text" v-model="modifyData.param_value" placeholder="파라미터를 입력하세요." />
                  </label>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="btn-wrap">
          <button class="btn color" style="background:#16753c;" v-if="mobius_connected === 'connected'" @click="confirm">확인</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed, watch, onMounted } from 'vue'
import { useModalState } from '@/common/state/ModalState'
import { modalAlertNames } from '@/components/common/globalComponent'
import { useStore } from 'vuex'
import $ from 'jquery'

export default {
  name: 'drone-list',
  setup() {
    const { proxy } = getCurrentInstance()
    const { modalPopUp, fnModalAlert, fnBaseConfirm } = useModalState()
    const { dispatch, getters } = useStore()

    //* pagination variable
    const page = reactive({
      showParam: 0,
      viewMore: true,
      pageInfo: {},
      pageNum: 0,
      pageSize: 5,
      start: 0,
      end: 0,
      pageCount: computed(() => {
        let listLeng = state.droneList !== null ? state.droneList.length : 0 // 드론 전체 개수
        let listSize = page.pageSize // 1페이지에 몇개의 개수를 보여줄지
        let num = Math.floor(listLeng / listSize)
        if (listLeng % listSize > 0) num += 1
        return num
      }),
      droneListArray: [],
      newDroneList: [],
      checkAll: false,
      params: {
        page: 1,
        limit: 10,
        totalPage: 5
      },
      // paramter settings
      selectedName: '',
      selectedParam: '',
      modifyData: { param_id: '', param_value: '', param_type: '' },
      droneParamArray: computed(() => getters['drone/getParams']),
      parameterinfo: { receivedDrones: [], paramNameList: [] },
      paramSettingMsg: '',
      // rf settings
      rfData: computed(() => getters['user/getRfData']),
      selectedRFDrone: { name: '', host: '' }
    })
    const state = reactive({
      mobius_connected: computed(() => getters['local/getMobiusConnected']),
      droneList: computed(() => getters['drone/getDroneList']),
      ctrlDroneList: computed(() => getters['drone/getCtrlDroneList']),
      mainCom: computed(() => getters['layout/getMainComponent'])
    })
    const mobiusData = reactive({
      host: getters['local/getHost'] ? getters['local/getHost'] : '', //52.79.250.192 '52.79.251.218' 'gcs.iotocean.org'
      ae: getters['local/getAe'] ? getters['local/getAe'] : '' //'UTM_UVARC' 'UMACAIR'
    })
    watch(
      () => state.droneList,
      () => {
        if (state.droneList == null) {
          return
        } else {
          paginatedData()
        }
      }
    )

    onMounted(() => {
    })

    const paginatedData = async () => {
      if (state.droneList) {
        if (JSON.parse(JSON.stringify(state.droneList)).length < 0) return
      } else {
        return
      }

      state.droneList.sort((a, b) => (a.name < b.name ? -1 : a.name < b.name ? 1 : 0))
      state.droneList.sort((a, b) => (a.control > b.control ? -1 : a.control < b.control ? 1 : 0))

      const start = page.pageNum * page.pageSize
      const end = start + page.pageSize
      if (page.pageCount == 1) page.pageNum = 0
      page.droneListArray = state.droneList.slice(start, end)
      return state.droneList.slice(start, end)
    }

    paginatedData()

    const fnViewMore = () => {
      page.showParam = 0
      page.viewMore = !page.viewMore
    }

    const selectItem = item => {
      page.selectedParam = item
      for (let item of page.droneParamArray) {
        if ((page.selectedName = item.droneName)) {
          item.parameters.forEach(param => {
            if (param.param_id == page.selectedParam) {
              page.modifyData.param_id = param.param_id
              page.modifyData.param_value = param.param_value
              page.modifyData.param_type = param.param_type
            }
          })
        }
      }
    }

    const fnParamBtn = async index => {
      page.viewMore = true
      page.showParam = index
    }

    const callBack = async () => {
      // const payload = {}
      // payload.component = proxy.$modalAlertNames.DRONELIST
      // await dispatch('layout/pushModalAlertComponent', payload)
    }

    const fnChange = async val => {
      page.pageNum = val
      paginatedData()
    }

    const nextPage = async () => {
      page.pageNum += 1
      paginatedData()
    }

    const prevPage = async () => {
      page.pageNum -= 1
      paginatedData()
    }

    const modifyDrone = async item => {
      item.isEdit = true
      await modalPopUp(modalAlertNames.DRONEADD, text => callBack(text), item)
    }

    const closeModal = async () => {
      proxy.$emit('close', proxy.$props.index)
    }

    const addDrone = async () => {
      var connect = localStorage.getItem('mobius_connected')
      if (connect === 'connected') await modalPopUp(modalAlertNames.DRONEADD, text => callBack(text))
      else await fnModalAlert('알람', 'Mobius가 연결되지 않았습니다.', '')
    }

    const connectMobius = async () => {
      if (mobiusData.ae !== '') {
        const isMobius = await proxy.$store.dispatch('local/connect', mobiusData)
        if (!isMobius) {
          const droneList = await proxy.$store.dispatch('drone/getDroneList')
          proxy.$store.dispatch('drone/setDroneList', droneList)
          let ctrlDroneList = proxy.$Util.setCtrlDroneList(droneList)
          ctrlDroneList.sort((a, b) => {
            return a.sysId - b.sysId
          })
          proxy.$store.dispatch('drone/setCtrlDroneList', ctrlDroneList)
          paginatedData()
          state.mainCom.fnConnectMobiusCheck()
        } else if (isMobius==1) {
          await fnModalAlert('알람', '유효하지 않은 HOST입니다.', '')
        } else if (isMobius==2) {
          await fnModalAlert('알람', 'HOST에 등록된 AE가 없습니다.', '')
        }
      } else {
        await fnModalAlert('알람', 'HOST 와 AE 정보를 확인 바랍니다.', '')
      }
    }

    const disconnectMobius = async () => {
      page.droneListArray = []
      proxy.$store.dispatch('local/disconnect', mobiusData)
      proxy.$store.dispatch('drone/setCtrlDroneList', [])
      state.mainCom.hudScroll = false
    }

    const deleteDrone = async item => {
      await fnBaseConfirm('알람', '삭제하시겠습니까?', '', async () => {
        await proxy.$DroneUtils.deleteDrone(item)
        const droneList = await dispatch('drone/getDroneList')
        await dispatch('drone/setDroneList', droneList)
        let ctrlDroneList = proxy.$Util.setCtrlDroneList(droneList)
        ctrlDroneList.sort((a, b) => {
          return a.sysId - b.sysId
        })
        await proxy.$store.dispatch('drone/setCtrlDroneList', ctrlDroneList)
        paginatedData()
        await fnModalAlert('알람', '해당 드론이 삭제되었습니다.', '')
      })
    }

    const controlCheckToggle = (event, item) => {
      if (event.target.checked) {
        for (let drone of state.droneList) {
          if (drone.name == item.name) {
            drone.control = 1
            drone.change = true
          }
        }
      } else {
        for (let drone of state.droneList) {
          if (drone.name == item.name) {
            drone.control = -1
            drone.change = true
          }
        }
      }
    }

    const checkAllDrone = event => {
      if (event.target.checked) {
        for (let drone of state.droneList) {
          drone.control = 1
          drone.change = true
        }
      } else {
        for (let drone of state.droneList) {
          drone.control = -1
          drone.change = true
        }
      }
    }

    const confirm = async () => {
      if (page.showParam == 7) {
        await applyParameter()
        return proxy.$emit('close', proxy.$props.index)
      } else if (page.showParam == 5) {
        dispatch('user/updateRfData', { type: 'drone', data: page.selectedRFDrone })
        proxy.$emit('close', proxy.$props.index)
        return await proxy.$AuthUtils.setUserRFData(page.rfData)
      }
      var changedDroneList = []
      for (let drone of state.droneList) {
        if (drone.change) {
          changedDroneList.push(drone)
        }
      }

      for (let changedDrone of changedDroneList) {
        const queryParam = {
          ae: changedDrone.ae,
          id: changedDrone.id,
          name: changedDrone.name
        }
        await proxy.$DroneUtils.updateControlDrone(changedDrone.control, queryParam)
      }
      var ctrlList = []
      for (let drone of state.droneList) {
        if (drone.control == 1) {
          ctrlList.push(drone)
        }
      }
      await dispatch('drone/setCtrlDrones', ctrlList)
      paginatedData()
      proxy.$emit('close', proxy.$props.index)
      if (state.mainCom.modeMinimize) {
        //small
        $('.hud-component .component-box div .component-box').css('height', '498px')
        $('.hud-component .component-box div .component').css('height', '482px')
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud').css('height', '348px')
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud > div').css('height', '348px')
        // $('.status-bar').css('height', '38rem')
        $('.hud_background').css('top', '-65.5%')
        $('.pitch_background').css('top', '21%')
        $('.pitch_background').css('left', '32.5%')
        $('.hudHeading').css('left', '-199px')
        $('.pitch_background').css('height', '215px')
        $('.valbox_head .hedcont').css('height', '30px')
        $('.valbox_head .hedcont').css('line-height', '28px')
        $('.armed_upsize').addClass('armed_default')
        $('.armed_default').removeClass('armed_upsize')
      } else {
        //big
        $('.hud-component .component-box div .component-box').css('height', '600px')
        $('.hud-component .component-box div .component').css('height', '600px')
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud').css('height', '468px')
        $('.hud-component .component-box div .component .component-inner .component-inner-wrap .default_hud > div').css('height', '468px')
        // $('.status-bar').css('height', '50rem')
        $('.hud_background').css('top', '-36%')
        $('.pitch_background').css('top', '28%')
        $('.pitch_background').css('left', '37.5%')
        $('.hudHeading').css('left', '-279px')
        $('.pitch_background').css('height', '265px')
        $('.valbox_head .hedcont').css('height', '40px')
        $('.valbox_head .hedcont').css('line-height', '36px')
        $('.armed_default').addClass('armed_upsize')
        $('.armed_upsize').removeClass('armed_default')
      }
    }
    // send updated parameter data to drone
    const applyParameter = async () => {
      try {
        if (!page.droneParamArray) return proxy.$emit('close', proxy.$props.index)
        let droneInfo
        for (let drone of state.ctrlDroneList) {
          if (drone.name == page.selectedName) {
            droneInfo = drone
          }
        }
        await proxy.$MavlinkUtils.changeDroneParam(droneInfo, page.modifyData)
        proxy.$emit('close', proxy.$props.index)
      } catch (error) {
        throw new Error(error)
      }
    }

    return {
      ...toRefs(page),
      ...toRefs(state),
      ...toRefs(mobiusData),

      fnParamBtn,
      fnViewMore,
      paginatedData,
      fnChange,
      nextPage,
      prevPage,
      modifyDrone,
      closeModal,
      addDrone,
      connectMobius,
      disconnectMobius,
      deleteDrone,
      controlCheckToggle,
      confirm,
      checkAllDrone,
      selectItem,
      applyParameter
    }
  }
}
</script>

<style scoped>
#noData {
  text-align: center;
  display: flow-root;
}
.set-window-con {
  height: 435px;
}
#typeahead_id_wrapper > div > div {
  background: black !important;
  color: black !important;
}
span.simple-typeahead-list-item-text {
  color: black !important;
}

.set-window-con .parameter .right .form:last-of-type {
  width: calc(100% - 1.8rem);
}
.serial-p {
  font-size: 14px;
  margin-top: 10px;
  margin-left: 30px;
}
.rfDrone {
  margin-left: 3.5% !important;
  max-width: 432px !important;
  margin-top: 15px !important;
}
</style>
