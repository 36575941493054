import * as Cesium from 'cesium'
import 'cesium/Build/Cesium/Widgets/widgets.css'
import ElevationUtils from '@/services/openlayers/open-elevation'
import configService from '../common/Config'
let terrainProvider

class CesiumUtils {
  init(map, centerView) {
    Cesium.Ion.defaultAccessToken =
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjdkZmVmNy0xY2Y4LTQwOGUtOWE2Zi01M2EyZjIyMDhkY2IiLCJpZCI6MTEzMTQ3LCJpYXQiOjE2NjcyNjQ3OTl9.QYIXcS3YVvPBh1Bq2Ms5TyvXyNHWbwhguAvC4LAwHOg'

    let viewer = new Cesium.Viewer('realMap', {
      infoBox: false,
      selectionIndicator: false,
      shadows: false,
      shouldAnimate: false,
      geocoder: false,
      navigationHelpButton: false,
      homeButton: false,
      //baseLayerPicker: false,
      scene3DOnly: true,
      // requestRenderMode: true,
      maximumRenderTimeChange: Infinity,
      terrainProvider: Cesium.createWorldTerrain()
    })

    //내장산
    let tileset3 = new Cesium.Cesium3DTileset({
      url: configService.getServiceUrl() + '3d_tile/Production_NJS.json',
      maximumScreenSpaceError: 1
    })
    viewer.scene.primitives.add(tileset3)

    tileset3.readyPromise.then(function (tileset) {
      var heightOffset = 60;
      var boundingSphere = tileset.boundingSphere;
      var cartographic = Cesium.Cartographic.fromCartesian(boundingSphere.center);
      var surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
      var offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, heightOffset);
      var translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      tileset.show = true;
    });

    viewer.animation.container.style.visibility = 'hidden'
    viewer.timeline.container.style.visibility = 'hidden'
    viewer.camera.setView({
      //내장산
      destination: Cesium.Cartesian3.fromDegrees(126.88768892046679, 35.45292028478967, 20000)
    })

    map.viewer = viewer

    var scene = viewer.scene
    scene.skyBox.show = false
    scene.moon.show = false

    // Explicitly render a new frame
    scene.requestRender()
    var handler = new Cesium.ScreenSpaceEventHandler(scene.canvas)

    handler.setInputAction(move => {
      const cartesian = viewer.camera.pickEllipsoid(move.endPosition, scene.globe.ellipsoid)
      if (cartesian) {
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian)
        const lon = Cesium.Math.toDegrees(cartographic.longitude)
        const lat = Cesium.Math.toDegrees(cartographic.latitude)
        // console.log('lon', lon)
        // console.log('lat', lat)
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    //test
    // const cesiumPosition = Cesium.Cartesian3.fromDegrees(127.0304122, 37.4909218, 56)
    // viewer.entities.add({
    //   id: droneId,
    //   position: cesiumPosition,
    //   model: {
    //       uri: 'http://203.253.128.177:7595/model/drone2.gltf',
    //       scale: 1
    //   }
    // })
  }

  //9: The terrain level-of-detail from which to query terrain heights.
  async getTerrainHeight(position) {
    let terrainProvider = Cesium.createWorldTerrain()
    const positions = [Cesium.Cartographic.fromDegrees(position.longitude, position.latitude)]
    const terrain = await Cesium.sampleTerrain(terrainProvider, 9, positions)
    return terrain[0].height
  }

  deleteDroneModel(viewer, droneId) {
    viewer.entities.remove(viewer.entities.getById(droneId))
  }

  async createDroneModel(viewer, droneId, droneData, droneColor, elevation) {
    const { position, heading, attitude, relative_alt } = droneData
    const cesiumPosition = Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, 0)

    const cesiumHPR = new Cesium.HeadingPitchRoll(heading, attitude.pitch, attitude.roll)
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(cesiumPosition, cesiumHPR)
    // console.log(droneData)

    let cesiumColor = Cesium.Color.RED
    switch (droneColor) {
      case '#F44336':
        cesiumColor = Cesium.Color.RED
        break;
      case '#E91E63':
        cesiumColor = Cesium.Color.DEEPPINK
        break;
      case '#673AB7':
        cesiumColor = Cesium.Color.BLUEVIOLET
        break;
      case '#9C27B0':
        cesiumColor = Cesium.Color.PURPLE
        break;
      case '#3F51B5':
        cesiumColor = Cesium.Color.DARKBLUE
        break;
      case '#2196F3':
        cesiumColor = Cesium.Color.DODGERBLUE
        break;
      case '#03A9F4':
        cesiumColor = Cesium.Color.DEEPSKYBLUE
        break;
      case '#009688':
        cesiumColor = Cesium.Color.TEAL
        break;
      case '#4CAF50':
        cesiumColor = Cesium.Color.LIMEGREEN
        break;
      case '#CDDC39':
        cesiumColor = Cesium.Color.LIME
        break;
      case '#FFEB3B':
        cesiumColor = Cesium.Color.YELLOW
        break;
      case '#FFC107':
        cesiumColor = Cesium.Color.ORANGE
        break;
      case '#FF9800':
        cesiumColor = Cesium.Color.DARKORANGE
        break;
      case '#FF5722':
        cesiumColor = Cesium.Color.ORANGERED
        break;
      case '#795548':
        cesiumColor = Cesium.Color.SADDLEBROWN
        break;
      case '#607D8B':
        cesiumColor = Cesium.Color.LIGHTSLATEGREY
        break;
    }

    //3D모델
    const entity = new Cesium.Entity({
      id: droneId,
      position: cesiumPosition,
	orientation: orientation, 


      model: {
        uri: configService.getServiceUrl() + '3d_model/drone3.gltf',
        scale: 17,
        color: cesiumColor,
        silhouetteColor: Cesium.Color.YELLOW,
        silhouetteSize: 2,
      }
    })
    viewer.entities.add(entity)
  }

  async liveDrone3d(viewer, droneData, elevation) {
    const { position, heading, attitude, relative_alt } = droneData

    const cesiumPosition = Cesium.Cartesian3.fromDegrees(position.longitude, position.latitude, elevation + relative_alt)

    const cesiumHPR = new Cesium.HeadingPitchRoll(heading, attitude.pitch / 10, attitude.roll / 10)
    const orientation = Cesium.Transforms.headingPitchRollQuaternion(cesiumPosition, cesiumHPR)





    console.log(`alt: ${elevation+relative_alt}`)

    viewer.entities.getById(droneData.id).orientation = orientation
    viewer.entities.getById(droneData.id).position = cesiumPosition
  }

  setCameraEntity(viewer, droneId) {
    let { position } = viewer.entities.getById(droneId)
    function toDegrees(cartesian3Pos) {
      let pos = Cesium.Cartographic.fromCartesian(cartesian3Pos)
      return [pos.longitude / Math.PI * 180, pos.latitude / Math.PI * 180]
    }
    let location = toDegrees(position._value)
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(location[0], location[1], 1500.0),
      orientation: {
        pitch: Cesium.Math.toRadians(-90), //0:수평, -90:수직하향
      },
    })
  }

  setCameraPosition(viewer, position) {
    viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(position[0], position[1], 5000.0),
      orientation: {
        pitch: Cesium.Math.toRadians(-90), //0:수평, -90:수직하향
      },
    })
  }

  changeBaseLayer(viewer, isAerialMap) {
    let mapIndex = 0
    if (isAerialMap) mapIndex = 0
    else mapIndex = 2
    let baseLayerPickerViewModel = viewer.baseLayerPicker.viewModel;
    baseLayerPickerViewModel.selectedImagery = baseLayerPickerViewModel.imageryProviderViewModels[mapIndex]
  }

  async createWpModel(viewer, modelInfo) {
    let wp3dImg = color => {
      return `<svg width="37" height="41" viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.2011 30.2941L21.5053 39.6986C21.0813 40.1111 20.5776 40.4384 20.023 40.6617C19.4684 40.885 18.8738 41 18.2733 41C17.6728 41 17.0782 40.885 16.5236 40.6617C15.969 40.4384 15.4653 40.1111 15.0414 39.6986L5.61996 30.2941C3.06066 27.8129 2.07255 24.6512 1.36599 21.2089C0.659428 17.7667 -0.0183955 14.1985 1.36599 10.9557C2.75037 7.71287 5.09515 4.94112 8.10377 2.991C11.1124 1.04089 14.6497 0 18.2682 0C21.8868 0 25.4241 1.04089 28.4327 2.991C31.4413 4.94112 33.7861 7.71287 35.1705 10.9557C36.5549 14.1985 36.9167 17.7667 36.2101 21.2089C35.5036 24.6512 33.7604 27.8129 31.2011 30.2941Z" fill="white"/>
        <path d="M27.3101 29.5795L18.8212 38.7783C18.4666 39.1756 18.0314 39.4936 17.5443 39.7114C17.0571 39.9291 16.529 40.0417 15.9948 40.0417C15.4606 40.0417 14.9325 39.9291 14.4454 39.7114C13.9582 39.4936 13.523 39.1756 13.1685 38.7783L4.68945 29.5795C2.41969 27.0893 0.900685 24.0143 0.30614 20.7061C-0.308878 17.3466 0.00649322 13.884 1.2185 10.689C2.39144 7.60165 4.43713 4.9173 7.10919 2.95921C9.6816 1.06451 12.7983 0.041748 15.9998 0.041748C19.2012 0.041748 22.3179 1.06451 24.8903 2.95921C27.5729 4.92997 29.6226 7.63224 30.791 10.7383C31.9955 13.918 32.3074 17.3636 31.6934 20.7061C31.0999 24.0146 29.5807 27.0899 27.3101 29.5795Z" fill="${color}"/>
        <path d="M15.8701 22.5308C20.0123 22.5308 23.3701 19.1729 23.3701 15.0308C23.3701 10.8886 20.0123 7.53076 15.8701 7.53076C11.728 7.53076 8.37012 10.8886 8.37012 15.0308C8.37012 19.1729 11.728 22.5308 15.8701 22.5308Z" fill="white"/>
        </svg>
        `
    }
    const positions = [Cesium.Cartographic.fromDegrees(modelInfo.wpInfo.lng, modelInfo.wpInfo.lat)]
    await Cesium.sampleTerrainMostDetailed(terrainProvider, positions)
    var position = Cesium.Cartesian3.fromDegrees(modelInfo.wpInfo.lng, modelInfo.wpInfo.lat, parseInt(modelInfo.wpInfo.alt) + positions[0].height)
    const setImage = 'data:image/svg+xml;base64,' + btoa(wp3dImg(modelInfo.wpInfo.color))
    modelInfo.wpInfo.terrainHeight = positions[0].height
    viewer.entities.add({
      id: modelInfo.wpInfo.id,
      droneName: modelInfo.wpInfo.drone,
      info: modelInfo.wpInfo,
      position: position,
      billboard: {
        image: setImage,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        eyeOffset: new Cesium.Cartesian3(0, 1, 0),
        scale: 0.4
      },
      label: {
        text: `${modelInfo.wpInfo.cuAt} ${modelInfo.wpInfo.alt}`,
        font: '10px sans-serif',
        showBackground: true,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        pixelOffset: new Cesium.Cartesian2(0, -30)
      }
    })
  }

  createMissionPole(viewer, modelInfo) {
    var position = Cesium.Cartesian3.fromDegrees(
      modelInfo.wpInfo.lng,
      modelInfo.wpInfo.lat,
      parseInt(modelInfo.wpInfo.alt + modelInfo.wpInfo.terrainHeight) / 2
    )
    viewer.entities.add({
      name: 'draw-pole',
      id: 'pole_' + modelInfo.wpInfo.id,
      position: position,
      cylinder: {
        length: parseInt(modelInfo.wpInfo.alt + modelInfo.wpInfo.terrainHeight),
        topRadius: 2,
        bottomRadius: 2,
        material: Cesium.Color.YELLOWGREEN.withAlpha(0.8),
        outline: true,
        outlineColor: Cesium.Color.BLACK,
        numberOfVerticalLines: 0
      }
    })
  }

  createWpline(viewer, state) {
    let wpArr = []
    for (let drone of state.ctrlDroneList) {
      for (let wpInfo of state.waypointList) {
        if (wpInfo.drone === drone.name) {
          wpArr.push(wpInfo.lng, wpInfo.lat, parseInt(wpInfo.alt + wpInfo.terrainHeight))
        }
      }
      //* waypoint가 두개 이상 없을 시 return
      if (wpArr.length < 2) return
      const color = this.hexToRgbA(drone.color)
      viewer.entities.add({
        name: `${drone.name} poly line`,
        id: `${drone.name}-polyline`,
        polyline: {
          positions: Cesium.Cartesian3.fromDegreesArrayHeights(wpArr),
          width: 5,
          material: new Cesium.PolylineOutlineMaterialProperty({
            color: new Cesium.Color(Cesium.Color.byteToFloat(color[0]), Cesium.Color.byteToFloat(color[1]), Cesium.Color.byteToFloat(color[2]))
          })
        }
      })
      wpArr = []
    }
  }

  async createPatternModel(viewer, patternData) {
    let wp3dImg = color => {
      return `<svg width="37" height="41" viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.2011 30.2941L21.5053 39.6986C21.0813 40.1111 20.5776 40.4384 20.023 40.6617C19.4684 40.885 18.8738 41 18.2733 41C17.6728 41 17.0782 40.885 16.5236 40.6617C15.969 40.4384 15.4653 40.1111 15.0414 39.6986L5.61996 30.2941C3.06066 27.8129 2.07255 24.6512 1.36599 21.2089C0.659428 17.7667 -0.0183955 14.1985 1.36599 10.9557C2.75037 7.71287 5.09515 4.94112 8.10377 2.991C11.1124 1.04089 14.6497 0 18.2682 0C21.8868 0 25.4241 1.04089 28.4327 2.991C31.4413 4.94112 33.7861 7.71287 35.1705 10.9557C36.5549 14.1985 36.9167 17.7667 36.2101 21.2089C35.5036 24.6512 33.7604 27.8129 31.2011 30.2941Z" fill="white"/>
        <path d="M27.3101 29.5795L18.8212 38.7783C18.4666 39.1756 18.0314 39.4936 17.5443 39.7114C17.0571 39.9291 16.529 40.0417 15.9948 40.0417C15.4606 40.0417 14.9325 39.9291 14.4454 39.7114C13.9582 39.4936 13.523 39.1756 13.1685 38.7783L4.68945 29.5795C2.41969 27.0893 0.900685 24.0143 0.30614 20.7061C-0.308878 17.3466 0.00649322 13.884 1.2185 10.689C2.39144 7.60165 4.43713 4.9173 7.10919 2.95921C9.6816 1.06451 12.7983 0.041748 15.9998 0.041748C19.2012 0.041748 22.3179 1.06451 24.8903 2.95921C27.5729 4.92997 29.6226 7.63224 30.791 10.7383C31.9955 13.918 32.3074 17.3636 31.6934 20.7061C31.0999 24.0146 29.5807 27.0899 27.3101 29.5795Z" fill="${color}"/>
        <path d="M15.8701 22.5308C20.0123 22.5308 23.3701 19.1729 23.3701 15.0308C23.3701 10.8886 20.0123 7.53076 15.8701 7.53076C11.728 7.53076 8.37012 10.8886 8.37012 15.0308C8.37012 19.1729 11.728 22.5308 15.8701 22.5308Z" fill="white"/>
        </svg>
        `
    }
    const droneList = patternData.droneList
    for (let drone of droneList) {
      for (let i = 0; i < drone.path.length; i++) {
        const positions = [Cesium.Cartographic.fromDegrees(drone.path[i][0], drone.path[i][1])]
        await Cesium.sampleTerrainMostDetailed(terrainProvider, positions)
        var wpPosition = Cesium.Cartesian3.fromDegrees(drone.path[i][0], drone.path[i][1], patternData.alt + positions[0].height)
        drone.path[i][2] = positions[0].height

        const setImage = 'data:image/svg+xml;base64,' + btoa(wp3dImg(drone.color))
        viewer.entities.add({
          id: `${drone.name}-${patternData.patternName}-point${i}`,
          position: wpPosition,
          billboard: {
            image: setImage,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            eyeOffset: new Cesium.Cartesian3(0, 1, 0),
            scale: 0.4
          }
        })
        // const cylinderHeight = patternData.alt / 2 + positions[0].height

        var polePosition = Cesium.Cartesian3.fromDegrees(drone.path[i][0], drone.path[i][1], (patternData.alt + positions[0].height) / 2)
        viewer.entities.add({
          name: 'draw-pole',
          id: `${drone.name}-${patternData.patternName}-pole${i}`,
          position: polePosition,
          cylinder: {
            length: patternData.alt + positions[0].height,
            topRadius: 2,
            bottomRadius: 2,
            material: Cesium.Color.YELLOWGREEN.withAlpha(0.8),
            outline: true,
            outlineColor: Cesium.Color.BLACK,
            numberOfVerticalLines: 0
          }
        })
      }
    }
  }

  createPatternPole(viewer, patternData) {
    const pathList = patternData.path
    for (let i = 0; i < pathList.length; i++) {
      const positions = [Cesium.Cartographic.fromDegrees(pathList[i][0], pathList[i][1]), Cesium.Cartographic.fromDegrees(87.0, 28.0)]
      Cesium.sampleTerrainMostDetailed(terrainProvider, positions).then(updatedPositions => {
        var position = Cesium.Cartesian3.fromDegrees(pathList[i][0], pathList[i][1], (patternData.alt + positions[0].height) / 2)
        viewer.entities.add({
          name: 'draw-pole',
          id: 'pole_' + i,
          position: position,
          cylinder: {
            length: patternData.alt + positions[0].height,
            topRadius: 2,
            bottomRadius: 2,
            material: Cesium.Color.YELLOWGREEN.withAlpha(0.8),
            outline: true,
            outlineColor: Cesium.Color.BLACK,
            numberOfVerticalLines: 0
          }
        })
      })
    }
  }

  createPatternline(viewer, state) {
    for (let patternData of state.patternList) {
      const droneList = patternData.droneList
      for (let drone of droneList) {
        let lineArray = []
        drone.path.forEach(item => {
          lineArray.push(item[0])
          lineArray.push(item[1])
          lineArray.push(item[2] + patternData.alt)
        })
        const color = this.hexToRgbA(drone.color)
        viewer.entities.add({
          id: `${patternData.patternName}-${drone.name}-line`,
          polyline: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights(lineArray),
            width: 6,
            material: new Cesium.PolylineOutlineMaterialProperty({
              color: new Cesium.Color(Cesium.Color.byteToFloat(color[0]), Cesium.Color.byteToFloat(color[1]), Cesium.Color.byteToFloat(color[2]))
            })
          }
        })
      }
    }
  }

  hexToRgbA(hex) {
    var c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('')
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]]
      }
      c = '0x' + c.join('')
      return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
    }
    throw new Error('Bad Hex')
  }
}

export default new CesiumUtils()
