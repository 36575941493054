import TokenUtils from '@/services/auth/Token'
import ConfigUtil from '@/services/common/Config'
import axios from 'axios'

const API_URL = ConfigUtil.getServiceUrl() + 'offline/'

class OfflineUtils {
  async getMapByOSM(osm) {
    try {
      const result = await axios({
        method: 'GET',
        url: 'http://localhost:7586/offline/',
        params: {
          osm: osm
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }
  async saveOfflineMap(mapData) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'POST',
        url: API_URL,
        headers: { gcsAcToken: token },
        data: {
          mapData: mapData
        }
      })
      console.log('result', result)
    } catch (error) {
      throw new Error(error)
    }
  }

  async deleteOfflineMap() {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'DELETE',
        url: API_URL,
        headers: { gcsAcToken: token }
      })
      console.log('result', result)
    } catch (error) {
      throw new Error(error)
    }
  }
}
export default new OfflineUtils()
