import { createStore } from 'vuex'
import layout from '@/store/modules/layout/index'
import user from '@/store/modules/user/index'
import local from '@/store/modules/local/index'
import drone from '@/store/modules/drone/index'
import weather from '@/store/modules/weather/index'
import pattern from '@/store/modules/pattern/index'
import sse from '@/store/modules/sse/index'
import createPersistedState from 'vuex-persistedstate'

const persistStore = createPersistedState({
  paths: ['sse']
})

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout,
    user,
    local,
    drone,
    weather,
    pattern,
    sse
  },
  plugins: [persistStore]
})
