<template>
  <div
    class="marker-generate context-menu"
    ref="popper"
    v-show="isVisible"
    tabindex="-1"
    id="marker-generate"
    style="z-index:999;"
    @contextmenu.capture.prevent
  >
    <div class="btn-wrap" :class="{ on: createIndex === 1 }" @click="selectWpEvent((createIndex = 1))">
      <button class="btn color-light">마커생성</button>
    </div>
    <div class="btn-wrap" :class="{ on: createIndex === 2 }" @click="selectWpEvent((createIndex = 2))">
      <button class="btn color-light">패턴 생성</button>
    </div>
    <div class="btn-wrap" :class="{ on: createIndex === 3 }" @click="selectWpEvent((createIndex = 3))">
      <button class="btn color-light">지오펜스 생성</button>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, computed, toRefs, onMounted } from 'vue'
import { useModalState } from '@/common/state/ModalState'
import { useStore } from 'vuex'
import Popper from 'popper.js'
import $ from 'jquery'

export default {
  name: 'created-index',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    mainState: {},
    allLayers: {},
    coordinates: {},
    boundariesElement: {
      type: String,
      default: 'body'
    }
  },
  data() {
    return {
      opened: false,
      contextData: {}
    }
  },
  computed: {
    isVisible() {
      return this.opened
    }
  },
  methods: {
    open(evt, contextData, is3d) {
      if (is3d) return
      this.opened = true
      this.contextData = contextData

      if (this.popper) {
        this.popper.destroy()
      }
      this.popper = new Popper(this.referenceObject(evt), this.$refs.popper, {
        placement: 'right-start',
        modifiers: {
          preventOverflow: {
            boundariesElement: document.querySelector(this.boundariesElement)
          }
        }
      })
      // Recalculate position
      this.$nextTick(() => {
        this.popper.scheduleUpdate()
      })
    },
    close() {
      this.opened = false
      this.contextData = null
    },
    referenceObject(evt) {
      let modifyVal = 0
      //if (window.innerWidth < 1081) modifyVal = 760
      const left = evt.clientX
      const top = evt.clientY - modifyVal
      const right = left + 1
      const bottom = top + 1
      const clientWidth = 1
      const clientHeight = 1

      function getBoundingClientRect() {
        return {
          left,
          top,
          right,
          bottom
        }
      }

      const obj = {
        getBoundingClientRect,
        clientWidth,
        clientHeight
      }
      return obj
    }
  },
  beforeUnmount() {
    if (this.popper !== undefined) {
      this.popper.destroy()
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert } = useModalState()
    const { dispatch, getters } = useStore()
    const state = reactive({
      createIndex: 1,
      host: computed(() => {
        return getters['local/getHost']
      }),
      ae: computed(() => {
        return getters['local/getAe']
      })
    })
    onMounted(() => {
      document.addEventListener('click', function(e) {
        if (e.target.className === 'marker-generate context-menu') return
        proxy.opened = false
        proxy.contextData = null
      })
    })

    //* 패턴, waypoint 생성 모달 팝업
    const selectWpEvent = createIndex => {
      state.createIndex = createIndex
      if (createIndex === 1) createWaypoint()
      else if (createIndex === 2) createSurvey()
      else if (createIndex === 3) createGeofence()
    }

    //* 패턴 생성
    const createSurvey = async () => {
      //* ctrlDroneList가 없을 시 패턴 생성 불가
      if (!props.mainState.ctrlDroneList.length) return

      const patternData = {
        patternName: generateRandomString(),
        droneList: [props.mainState.ctrlDroneList[0]],
        host: state.host,
        aeName: state.ae,
        angle: 0,
        gap: 55,
        coordinates: [parseFloat(props.coordinates.lng), parseFloat(props.coordinates.lat)],
        focalLength: 16,
        Hsensor: 15.6,
        Wsensor: 23.5,
        multiplicity: 75,
        alt: 150,
        speed: 5,
        shootPeriod: 7,
        waypoints: [],
        version: 'new'
      }
      const result = await proxy.$PatternUtils.savePattern(patternData)
      await proxy.$OlUtils.makeDefaultPattern(props.state, props.allLayers, result.data, false)
      dispatch('pattern/addPattern', result.data)
    }

    //* waypoint 생성 모달
    const createWaypoint = async () => {
      await dispatch('layout/closeModalAlertComponent')
      const payload = {
        wpInfo: {},
        component: proxy.$modalAlertNames.MARKER,
        coordinates: props.coordinates,
        layer: props.allLayers,
        modalType: '생성',
        mainState: props.mainState,
        callBackMarkerCancel: proxy => proxy.$emit('close', props.index)
      }
      await dispatch('layout/pushModalAlertComponent', payload)
    }

    //* 지오팬스 생성
    const createGeofence = async () => {
      await dispatch('layout/closeModalAlertComponent')
      const payload = {
        figureInfo: {},
        component: proxy.$modalAlertNames.GEOFENCE,
        coordinates: props.coordinates,
        modalType: '생성',
        layer: props.allLayers,
        mainState: props.mainState,
        GeofenceUpdate: () => proxy.$emit('update')
      }
      await dispatch('layout/pushModalAlertComponent', payload)
    }

    //* 랜덤 이름 생성
    const generateRandomString = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let randomName = ''
      const charactersLength = characters.length
      for (let i = 0; i < 4; i++) {
        randomName += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return randomName
    }

    return {
      ...toRefs(state),
      selectWpEvent,
      createWaypoint,
      createSurvey,
      createGeofence,
      generateRandomString
    }
  }
}
</script>

<style scoped></style>
