import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/static/css/main.scss'
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'

import Util from '@/common/utils/Util'
import ConstCode from '@/common/constants/ConstCode'
import BaseInput from '@/components/common/layouts/BaseInput'

import AuthUtils from '@/services/auth/Auth'
import TokenUtils from '@/services/auth/Token'
import ConfigUtils from '@/services/common/Config'
import SocketUtils from '@/services/common/Socket'
import DroneUtils from '@/services/drone/Drone'
import MavlinkUtils from '@/services/drone/Mavlink'
import OlUtils from '@/services/openlayers/Ol'
import CesiumUtils from '@/services/openlayers/Cesium'
import ElevationUtils from '@/services/openlayers/open-elevation'
import GeofenceUtils from '@/services/geofence/Geofence'
import PatternUtils from '@/services/pattern/Pattern'
import SystemUtils from '@/services/common/System'
import OfflineUtils from '@/services/common/Offline'
import LxUtils from '@/services/common/Lx'

// external import
import HudPanel from '@/components/views/main/hud/index.vue'
import ComponentDrone from '@/components/common/layouts/svg/Svg.vue'
import { modalAlertNames, modalNames } from './components/common/globalComponent'
import SimpleTypeahead from 'vue3-simple-typeahead'
import dayjs from 'dayjs'
import lodash from 'lodash'
import axios from 'axios'

import '@/common/utils/NativeCall'

import CommonSvc from '@/common/service/CommonSvc'

/** swiper **/
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
import 'jquery-toast-plugin/dist/jquery.toast.min.css'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
/** swiper **/

import VueScrollTo from 'vue-scrollto'

import { ObserveVisibility } from 'vue-observe-visibility'
const app = createApp(App)
app.config.globalProperties.$Util = Util
app.config.globalProperties.$ConstCode = ConstCode
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$_ = lodash
app.config.globalProperties.axios = axios
app.config.globalProperties.$CommonSvc = CommonSvc

app.config.globalProperties.$modalAlertNames = modalAlertNames
app.config.globalProperties.$modalNames = modalNames

// 전역 컴포넌트 등록
for (let key in modalAlertNames) {
  app.component(modalAlertNames[key].name, modalAlertNames[key])
}
for (let key in modalNames) {
  app.component(modalNames[key].name, modalNames[key])
}

// 전역 유틸리티 등록
app.config.globalProperties.$AuthUtils = AuthUtils
app.config.globalProperties.$ConfigUtils = ConfigUtils
app.config.globalProperties.$DroneUtils = DroneUtils
app.config.globalProperties.$SocketUtils = SocketUtils
app.config.globalProperties.$TokenUtils = TokenUtils
app.config.globalProperties.$MavlinkUtils = MavlinkUtils
app.config.globalProperties.$OlUtils = OlUtils
app.config.globalProperties.$CesiumUtils = CesiumUtils
app.config.globalProperties.$ElevationUtils = ElevationUtils
app.config.globalProperties.$GeofenceUtils = GeofenceUtils
app.config.globalProperties.$PatternUtils = PatternUtils
app.config.globalProperties.$SystemUtils = SystemUtils
app.config.globalProperties.$OfflineUtils = OfflineUtils
app.config.globalProperties.$LxUtils = LxUtils 

app.config.warnHandler = function(msg, vm, trace) {
  return null
}
app.component(Swiper.name, Swiper)
app.component(SwiperSlide.name, SwiperSlide)
app.component(BaseInput)
app.component('HudPanel', HudPanel)
app.component(ComponentDrone.name, ComponentDrone)

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind
})

app.directive('show-modal-alert', {
  mounted: el => {
    setTimeout(() => {
      el.classList.add('on')
    }, 20)
  },
  beforeUnmount: el => {
    setTimeout(() => {
      el.classList.remove('on')
    }, 20)
  }
})

app.directive('show-modal', {
  mounted: el => {
    setTimeout(() => {
      el.classList.remove('off')
    }, 20)
  },
  beforeUnmount: el => {
    setTimeout(() => {
      el.classList.add('off')
    }, 20)
  }
})

app
  .use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
  })
  .use(store)
  .use(router)
  .use(SimpleTypeahead)
  .mount('#app')
