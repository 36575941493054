<template>
  <div class="modal-dim on" style="z-index: 1003">
    <div class="set-window-wrap">
      <div class="set-window sub-color" id="dev01">
        <div class="set-window-top gray">
          <div class="set-window-sub">
            <div class="sub">
              <i class="i-add"></i>
              <h2>Add New Color</h2>
            </div>
            <div class="close">
              <i class="i-close-20" @click="fnCancel"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con">
          <div class="color-set-box">
            <div class="color-now">
              <div class="palate-box">
                <div class="palate" style="background-color: #000;">
                  <!--현재 선택한 색 박스-->
                </div>
                <div class="palate" style="background-color: #fff;">
                  <!--기존에 선택한 색 박스-->
                </div>
              </div>
              <div class="form">
                <label for="" class="label">
                  <input type="text" placeholder="#000000" />
                  <button class="btn color">조회</button>
                </label>
              </div>
            </div>
            <div class="row-box">
              <div class="color-palate">
                <div class="sect">
                  <h2 class="color-txt">Sample Color</h2>
                  <div class="sample-color">
                    <div class="row">
                      <div style="background-color: #FC0D1B;"></div>
                      <div style="background-color: #FC5F20;"></div>
                      <div style="background-color: #FDBA2C;"></div>
                      <div style="background-color: #FEE233;"></div>
                      <div style="background-color: #ADF030;"></div>
                      <div style="background-color: #2ED92E;"></div>
                      <div style="background-color: #25D8FD;"></div>
                      <div style="background-color: #105BFB;"></div>
                      <div style="background-color: #0B24FB;"></div>
                      <div style="background-color: #5F24FB;"></div>
                      <div style="background-color: #FC23DB;"></div>
                      <div style="background-color: #FC1580;"></div>
                      <div style="background-color: #000;"></div>
                      <div style="background-color: #FCFCFC;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #FED8D8;"></div>
                      <div style="background-color: #F9E0D5;"></div>
                      <div style="background-color: #FAEBC7;"></div>
                      <div style="background-color: #FAF3C2;"></div>
                      <div style="background-color: #E4F6BD;"></div>
                      <div style="background-color: #CFFACB;"></div>
                      <div style="background-color: #D5F4F9;"></div>
                      <div style="background-color: #D9E5FE;"></div>
                      <div style="background-color: #DADAFE;"></div>
                      <div style="background-color: #E8DAFE;"></div>
                      <div style="background-color: #FEDAF9;"></div>
                      <div style="background-color: #FED9EC;"></div>
                      <div style="background-color: #F6F6F6;"></div>
                      <div style="background-color: #EAEAEA;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #FDA7A8;"></div>
                      <div style="background-color: #FEC1A1;"></div>
                      <div style="background-color: #FEDF91;"></div>
                      <div style="background-color: #F9EC84;"></div>
                      <div style="background-color: #CFF080;"></div>
                      <div style="background-color: #B9EFB3"></div>
                      <div style="background-color: #B4EBF3;"></div>
                      <div style="background-color: #B3CDFD;"></div>
                      <div style="background-color: #B5B4FD;"></div>
                      <div style="background-color: #D1B4FD;"></div>
                      <div style="background-color: #FEB4F4;"></div>
                      <div style="background-color: #FEB3D9;"></div>
                      <div style="background-color: #D5D5D5;"></div>
                      <div style="background-color: #BDBDBD;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #EF6062;"></div>
                      <div style="background-color: #F09666;"></div>
                      <div style="background-color: #F1C865;"></div>
                      <div style="background-color: #E4D765;"></div>
                      <div style="background-color: #BDE365;"></div>
                      <div style="background-color: #89E383;"></div>
                      <div style="background-color: #62D1E3;"></div>
                      <div style="background-color: #699BFC;"></div>
                      <div style="background-color: #6C6BFB;"></div>
                      <div style="background-color: #A46CFC;"></div>
                      <div style="background-color: #F166DA;"></div>
                      <div style="background-color: #F164A6;"></div>
                      <div style="background-color: #A6A6A6;"></div>
                      <div style="background-color: #8C8C8C;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #CA3E41;"></div>
                      <div style="background-color: #CA7243;"></div>
                      <div style="background-color: #CBA546;"></div>
                      <div style="background-color: #C3B646;"></div>
                      <div style="background-color: #A0C747;"></div>
                      <div style="background-color: #4DC647;"></div>
                      <div style="background-color: #44B7CB;"></div>
                      <div style="background-color: #4677D6;"></div>
                      <div style="background-color: #4748D6;"></div>
                      <div style="background-color: #7F48D6;"></div>
                      <div style="background-color: #D748C3;"></div>
                      <div style="background-color: #D7458C;"></div>
                      <div style="background-color: #747474;"></div>
                      <div style="background-color: #5D5D5D;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #96040B;"></div>
                      <div style="background-color: #97380E;"></div>
                      <div style="background-color: #986F16;"></div>
                      <div style="background-color: #98891A;"></div>
                      <div style="background-color: #6C981A;"></div>
                      <div style="background-color: #359C30;"></div>
                      <div style="background-color: #128298;"></div>
                      <div style="background-color: #063796;"></div>
                      <div style="background-color: #081196;"></div>
                      <div style="background-color: #3C0E94;"></div>
                      <div style="background-color: #971083;"></div>
                      <div style="background-color: #97084C;"></div>
                      <div style="background-color: #4C4C4C;"></div>
                      <div style="background-color: #353535;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #660205;"></div>
                      <div style="background-color: #652507;"></div>
                      <div style="background-color: #654A0B;"></div>
                      <div style="background-color: #665B0E;"></div>
                      <div style="background-color: #48650E;"></div>
                      <div style="background-color: #247220;"></div>
                      <div style="background-color: #085765;"></div>
                      <div style="background-color: #032464;"></div>
                      <div style="background-color: #040864;"></div>
                      <div style="background-color: #2A0864;"></div>
                      <div style="background-color: #650757;"></div>
                      <div style="background-color: #761F49;"></div>
                      <div style="background-color: #212121;"></div>
                      <div style="background-color: #191919;"></div>
                    </div>
                    <div class="gradient"></div>
                  </div>
                </div>

                <div class="sect">
                  <h2 class="color-txt">사용자 지정 색</h2>
                  <div class="sample-color">
                    <div class="row">
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                    </div>
                    <div class="row">
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                      <div style="background-color: #fff;"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="color-code">
                <h2 class="color-txt center">색상 코드</h2>
                <div class="code-row">
                  <p class="color-txt">RGB</p>
                  <div class="form">
                    <p class="color-txt">R</p>
                    <label class="label">
                      <input type="text" placeholder="0~255" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">G</p>
                    <label class="label">
                      <input type="text" placeholder="0~255" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">B</p>
                    <label class="label">
                      <input type="text" placeholder="0~255" />
                    </label>
                  </div>
                </div>
                <div class="code-row">
                  <p class="color-txt">HSB</p>
                  <div class="form">
                    <p class="color-txt">H</p>
                    <label class="label">
                      <input type="text" placeholder="0~255" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">S</p>
                    <label class="label">
                      <input type="text" placeholder="0~100" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">B</p>
                    <label class="label">
                      <input type="text" placeholder="0~100" />
                    </label>
                  </div>
                </div>
                <div class="code-row row4">
                  <p class="color-txt">CMYK</p>
                  <div class="form">
                    <p class="color-txt">C</p>
                    <label class="label">
                      <input type="text" placeholder="0~100" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">M</p>
                    <label class="label">
                      <input type="text" placeholder="0~100" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">Y</p>
                    <label class="label">
                      <input type="text" placeholder="0~100" />
                    </label>
                  </div>
                  <div class="form">
                    <p class="color-txt">K</p>
                    <label class="label">
                      <input type="text" placeholder="0~100" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button class="btn gray" @click="fnCancel">Cancel</button>
            <button class="btn color" @click="fnCallBackDone">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'drone-color',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    doneText: {
      type: [String],
      default: 'CONFIRM'
    },
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { dispatch, getters } = useStore()
    const { proxy } = getCurrentInstance()

    const state = reactive({
      getModalAlertComponents: computed(() => {
        return getters['local/getModalAlertComponents']
      })
    })
    const fnCallBackCancel = async () => {
      await dispatch('layout/closeModalAlertComponent')
    }
    const fnCancel = async () => {
      await dispatch('layout/closeModalAlertComponent')
    }
    const fnCallBackDone = async () => {
      await dispatch('layout/closeModalAlertComponent')
      // props.callBackDone(proxy, event)
    }

    return {
      ...toRefs(state),
      fnCallBackCancel,
      fnCallBackDone,
      fnCancel
    }
  }
}
</script>

<style scoped>
#dev01 {
  width: auto;
}
</style>
