<template>
  <div>
    <div class="order-con" style="position:relative; max-height: 300px; padding-bottom: 0px">
      <div v-for="(data, droneIdx) of droneListData" :key="data.droneId" style="margin-bottom: 30px;">
        <!-- 모드 -->
        <div class="con-box" v-if="`${tabMenu}` === 'mode'">
          <div>
            <div class="drone-tab">
              <ComponentDrone :color="data.color" />
              <span>{{ data.droneName }}</span>
            </div>
            <div>
              <button class="modePlus" @click="() => (data.moreList = !data.moreList)" :value="(data.moreButton = data.moreList ? '-' : '+')">
                {{ data.moreButton }}
              </button>
            </div>
          </div>
          <ul class="mode-list">
            <li class="mode-list-li tooltip" v-for="(item, index, idx) in text" :key="index">
              <button
                v-if="!data.moreList && idx < 6"
                class="btn"
                :class="data.mode === `${item.name}` ? 'color' : 'gray'"
                @click="changeMode(`${item.name}`, `${data.droneName}`)"
                style="letter-spacing: 0em;"
                :value="item"
              >
                {{ item.name }}
              </button>
              <button
                v-else-if="data.moreList"
                class="btn"
                :class="data.mode === `${item.name}` ? 'color' : 'gray'"
                @click="changeMode(`${item.name}`, `${data.droneName}`)"
                style="letter-spacing: 0em;"
                :value="item"
              >
                {{ item.name }}
              </button>
              <span class="tooltip-text">Tooltip text</span>
            </li>
          </ul>
        </div>

        <!-- 시동 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'arm'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <p class="order-txt">비행체에 시동 명령을 전송합니다.</p>
        </div>

        <!-- 이륙 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'takeoff'" style="display:block">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form">
            <div class="form-wrap row">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">이륙고도(m)</p>
                <label class="label">
                  <input
                    type="number"
                    placeholder=""
                    v-model="data.globalAlt"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalAlt = parseInt(data.globalAlt) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalAlt = parseInt(data.globalAlt) - 1)"></button>
              </div>
            </div>

            <div class="form-wrap row">
              <div class="form column" style="width:14rem;">
                <p class="form-sub">시동 후 지연 시간(초)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.takeoffDeleyTime"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.takeoffDeleyTime = parseInt(data.takeoffDeleyTime) + 1)"></button>
                <button class="btn_down" @click="() => (data.takeoffDeleyTime = parseInt(data.takeoffDeleyTime) - 1)"></button>
              </div>
            </div>
          </div>
          <p class="valid" v-if="validText">{{ validText }}</p>
        </div>

        <!-- 정지 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'stop'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <p class="order-txt">비행체에 정지 명령을 전송합니다.</p>
        </div>

        <!-- 착륙 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'land'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <p class="order-txt">비행체에 착륙 명령을 전송합니다.</p>
        </div>

        <!-- 종료 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'disarm'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <p class="order-txt">비행체에 종료 명령을 전송합니다.</p>
        </div>

        <!-- 이동 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'move'" style="display:block">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form" style="overflow: scroll">
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">이동설정</p>
                <label class="label" style="margin: 0;">
                  <input type="hidden" :id="data.droneName" value="" />
                  <select v-model="data.moveRadio">
                    <option value="0">바로 이동</option>
                    <option value="1">고도 변경 후 이동</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="form-wrap row">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">이동고도(m)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.wpInfo.alt"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalAlt = parseInt(data.globalAlt) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalAlt = parseInt(data.globalAlt) - 1)"></button>
              </div>
            </div>
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">이동속도(m/s)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.wpInfo.spd"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalSpeed = parseInt(data.globalSpeed) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalSpeed = parseInt(data.globalSpeed) - 1)"></button>
              </div>
            </div>
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">지점거리(m)</p>
                <label class="label">
                  <input type="text" v-model="data.moveDistance" readonly />
                </label>
              </div>
            </div>
          </div>
          <p class="valid" v-if="validText">{{ validText }}</p>
        </div>

        <!--고도-->
        <div class="con-box" v-else-if="`${tabMenu}` === 'alt'" style="display:block">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form">
            <div class="form-wrap row">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">비행고도(m)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.globalAlt"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalAlt = parseInt(data.globalAlt) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalAlt = parseInt(data.globalAlt) - 1)"></button>
              </div>
            </div>
          </div>
          <p class="valid" v-if="validText">{{ validText }}</p>
        </div>

        <!-- 속도 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'speed'" style="display:block">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form">
            <div class="form-wrap row">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">비행속도(m)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.globalSpeed"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalSpeed = parseInt(data.globalSpeed) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalSpeed = parseInt(data.globalSpeed) - 1)"></button>
              </div>
            </div>
          </div>
          <p class="valid" v-if="validText">{{ validText }}</p>
        </div>

        <!-- 귀환 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'return'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <!-- <div class="order-form">
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">귀환속도(m/s)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.globalAlt"
                    disabled
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalAlt = parseInt(data.globalAlt) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalAlt = parseInt(data.globalAlt) - 1)"></button>
              </div>
            </div>
          </div> -->
          <p class="order-txt">비행체에 귀환 명령을 전송합니다.</p>
        </div>

        <!-- 관심 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'INTEREST'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form">
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">지점거리(m)</p>
                <label class="label">
                  <input type="text" v-model="data.interestDistance" readonly />
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- 선회 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'loiter'" style="display:block">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form" style="overflow: scroll">
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">선회 방향</p>
                <label class="label" style="margin: 0;">
                  <input type="hidden" :id="data.droneName" value="" />
                  <select v-model="data.turnRadio">
                    <option value="0">시계방향</option>
                    <option value="1">반시계방향</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">선회고도(m)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.globalAlt"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalAlt = parseInt(data.globalAlt) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalAlt = parseInt(data.globalAlt) - 1)"></button>
              </div>
            </div>
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">반지름(m)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.turnRadius"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.turnRadius = parseInt(data.turnRadius) + 1)"></button>
                <button class="btn_down" @click="() => (data.turnRadius = parseInt(data.turnRadius) - 1)"></button>
              </div>
            </div>
            <div class="form-wrap row">
              <div class="form column" style="width:11rem;">
                <p class="form-sub">선회속도(m/s)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.turnSpeed"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.turnSpeed = parseInt(data.turnSpeed) + 1)"></button>
                <button class="btn_down" @click="() => (data.turnSpeed = parseInt(data.turnSpeed) - 1)"></button>
              </div>
            </div>
          </div>
          <p class="valid" v-if="validText">{{ validText }}</p>
        </div>

        <!-- 패턴 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'PATTERN'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form">
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">패턴 선택</p>
                <label class="label" style="margin: 0;">
                  <select v-model="data.selectedPattern">
                    <option value="none" selected>해당없음</option>
                    <option v-for="pattern of data.patternList" :key="pattern" :value="pattern">{{ pattern.patternName }}</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">임무 연결</p>
                <label class="label" style="margin: 0;">
                  <select v-model="data.missionControl">
                    <option value="none">해당없음</option>
                    <option value="stop">중단하기</option>
                    <option value="continue">이어하기</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">방위각 선택</p>
                <label class="label" style="margin: 0;">
                  <select v-model="data.azimuthControl">
                    <option value="lock">기수 고정</option>
                    <option value="unlock">고정 해제</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>

        <!-- 자동 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'autoMission'" style="display:block">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form" style="overflow: scroll">
            <div class="form-wrap" style="margin-right:2.5rem;">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">관심설정</p>
                <label class="label" style="margin: 0;">
                  <select v-model="data.useRoi">
                    <option value="0">미사용</option>
                    <option value="1">사용</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="form-wrap row" style="margin-right:2.5rem;">
              <div class="form column" style="width:8rem;">
                <p class="form-sub">Start Index</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder="1"
                    v-model="data.startIndex"
                  />
                </label>
              </div>
            </div>
            <div class="form-wrap row" style="margin-right:2.5rem;">
              <div class="form column" style="width:8rem;">
                <p class="form-sub">End Index</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    :placeholder="data.wpList.length"
                    v-model="data.endIndex"
                  />
                </label>
              </div>
            </div>
            <!-- <div class="form-wrap row" style="margin-right:1rem;">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">비행고도(m)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.globalAlt"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalAlt = parseInt(data.globalAlt) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalAlt = parseInt(data.globalAlt) - 1)"></button>
              </div>
            </div>
            <div class="form-wrap row" style="margin-right:1rem;">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">비행속도(m/s)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.globalSpeed"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.globalSpeed = parseInt(data.globalSpeed) + 1)"></button>
                <button class="btn_down" @click="() => (data.globalSpeed = parseInt(data.globalSpeed) - 1)"></button>
              </div>
            </div> -->
            <div class="form-wrap row" style="margin-right:1rem;">
              <div class="form column" style="width:10rem;">
                <p class="form-sub">지점대기(sec)</p>
                <label class="label">
                  <input
                    type="number"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                    placeholder=""
                    v-model="data.autoDelay"
                  />
                </label>
              </div>
              <div class="btn-wrap">
                <button class="btn_up" @click="() => (data.autoDelay = parseInt(data.autoDelay) + 1)"></button>
                <button class="btn_down" @click="() => (data.autoDelay = parseInt(data.autoDelay) - 1)"></button>
              </div>
            </div>
          </div>
          <p class="valid" v-if="validText">{{ validText }}</p>
        </div>

        <!-- 지오펜스 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'loadFence'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form">
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem">지오펜스</p>
                <label class="label" style="margin: 0;">
                  <select class="unknown" @change="zoomToGeofence($event)">
                    <option value="" selected>unknown</option>
                    <option v-for="(item, index) in geofenceNames[droneIdx]" :key="index" :value="item._id">{{ item.name }} ({{ index+1 }})</option>
                  </select>
                </label>
              </div>
              <!-- <p class="valid">유효성 확인 문구</p> -->
            </div>
            <!-- <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem">방향</p>
                <label class="label" style="margin: 0;">
                  <select v-model="data.inclusion">
                    <option :value="false">진입 불가</option>
                    <option :value="true">진출 불가</option>
                  </select>
                </label>
              </div>
              <p class="valid">유효성 확인 문구</p>
            </div> -->
          </div>
        </div>

        <!-- 촬영 -->
        <div class="con-box" v-else-if="`${tabMenu}` === 'capture'">
          <div class="drone-tab">
            <ComponentDrone :color="data.color" />
            <span>{{ data.droneName }}</span>
          </div>
          <div class="order-form" style="overflow: scroll">
            <div class="form-wrap">
              <div class="form column">
                <p class="form-sub" style="margin-bottom:0.5rem;">촬영 명령</p>
                <label class="label" style="margin: 0;">
                  <select v-model="data.capture">
                    <option value="start">촬영 시작</option>
                    <option value="stop">촬영 종료</option>
                  </select>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn-wrap"
      style="bottom:0; left:0; flex-flow:row; margin-top: 0px!important; padding: 1rem 0; width:100%; background:rgba(97, 97, 97, 1); border-radius:0 0 1.5rem 1.5rem"
    >
      <button class="btn gray" style="width:20rem; margin-right:2rem;" @click="close">취소</button>
      <button class="btn color" style="width:20rem;" @click="startMission(index, input)">
        확인
      </button>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, onMounted, reactive, toRefs, watch } from 'vue'
import { useModalState } from '@/common/state/ModalState'
import { useStore } from 'vuex'
import extra from './extra'

export default {
  name: 'mode-components',
  props: {
    map: {},
    layers: {},
    tabMenu: {},
    mainState: {}
  },
  setup(props) {
    const { missionConfirm, fnModalAlert } = useModalState()
    const { proxy } = getCurrentInstance()
    const { getters, commit, dispatch } = useStore()

    const state = reactive({
      text: extra,
      patternName: '',
      mode: 'STABILIZE',
      tabDroneName: '',
      geofenceName: '',
      droneListData: [],
      geofenceNames: [],
      waypointList: computed(() => getters['local/getWpList']),
      initialAlt: computed(() => getters['drone/getGlobalAlt']),
      initialSpeed: computed(() => getters['drone/getGlobalSpeed']),
      missionDroneList: computed(() => getters['drone/getMissionDroneList']),
      patternList: props.mainState.patternList !== null ? props.mainState.patternList : []
    })

    const valid = reactive({
      validText: ''
    })

    //* 미션 드론 추가 되었을 시 droneListData sync
    watch(
      () => state.missionDroneList.length,
      () => {
        state.droneListData = []
        missionDataInit()
      }
    )

    //* 선택된 waypoint 관련 move, loiter, interest 데이터 설정
    watch(
      () => props.mainState.selectedWps.length,
      val => {
        if (!val) return
        state.droneListData.forEach(drone => {
          for (let selected of props.mainState.selectedWps) {
            if (selected.drone == drone.droneName) {
              if (drone.param == 'loiter') {
                drone.wpInfo['lng'] = selected.lng
                drone.wpInfo['lat'] = selected.lat
                drone.wpInfo['rpm'] = selected.turnNum // Revolution per mission
                drone.wpInfo['radius'] = selected.radius
                drone.wpInfo['speed'] = selected.spd
                drone.wpInfo['lat'] = selected.lat
              } else if (drone.param == 'move') {
                drone.wpInfo['lng'] = selected.lng
                drone.wpInfo['lat'] = selected.lat
                drone.wpInfo['spd'] = selected.spd
                drone.wpInfo['alt'] = selected.alt
                drone.moveDistance = `${selected.distance}m`
              } else if (drone.param == 'INTEREST') {
                drone.wpInfo['lng'] = selected.lng
                drone.wpInfo['lat'] = selected.lat
                drone.interestDistance = `${selected.distance}m`
              }
            }
          }
        })
        proxy.$emit('reset')
      }
    )

    watch(
      () => state.droneListData,
      () => {
        if (state.droneListData.length > 0) {
          getGeofence()
        }
      }
    )

    onMounted(() => {
      missionDataInit()
      //* 생성한 pattern 가져오기
      if (props.mainState.patternList !== null) {
        let names = []
        state.patternList.forEach(pattern => {
          names.push(pattern.name)
        })
        state.patternName = names.join()
      }
      // if (props.mainState.ctrlDroneList !== null) {
      //   props.layers.geofenceLayer
      //     .getSource()
      //     .getFeatures()
      //     .forEach(feature => {
      //       const geofence = feature.get('name')
      //       state.geofenceNames.push(geofence)
      //     })
      // }
      if (state.droneListData.length > 0) {
        getGeofence()
      }
    })

    const getGeofence = async () => {
      let droneListData = state.droneListData.map(data => data.droneName)
      await proxy.$GeofenceUtils.getGeofence(droneListData).then(res => {
        state.geofenceNames = res.data
      })
    }

    //* 모드 탭에서 모드 값 변경
    const changeMode = (index, droneName) => {
      for (let missionData of state.droneListData) {
        if (missionData.droneName == droneName && missionData.mode !== index) missionData.mode = index
      }
    }

    const startMission = () => {
      if (!fnValidDroneListData(state.droneListData[0])) return
      dispatch('drone/globalAlt', state.droneListData[0].globalAlt)
      dispatch('drone/globalSpeed', state.droneListData[0].globalSpeed)

      let title, name, contents
      let droneNameArr = []

      //* 임무에 맞는 제목 및 설명 지정
      switch (props.tabMenu) {
        case 'mode':
          title = `모드 변경`
          contents = `비행체에 [${state.mode}] 모드 변경 명령을 전송합니다.`
          break
        case 'arm':
          title = `시동`
          contents = `비행체에 시동 변경 명령을 전송합니다.`
          break
        case 'disarm':
          title = `종료`
          contents = `비행체에 종료 명령을 전송합니다.`
          break
        case 'takeoff':
          title = `이륙`
          contents = `비행체에 이륙 명령을 전송합니다.`
          break
        case 'alt':
          title = `고도`
          contents = `비행체에 고도 변경 명령을 전송합니다.`
          break
        case 'move':
          title = `이동`
          contents = `비행체에 지정 위치 이동 명령을 전송합니다.`
          break
        case 'loiter':
          title = `선회`
          contents = `비행체에  명령을 전송합니다.`
          break
        case 'speed':
          title = `속도`
          contents = `비행체에 속도 변경 명령을 전송합니다.`
          break
        case 'stop':
          title = `정지`
          contents = `비행체에 정지 명령을 전송합니다.`
          break
        case 'land':
          title = `착륙`
          contents = `비행체에 착륙 명령을 전송합니다.`
          break
        case 'return':
          title = `귀환`
          contents = `비행체에 귀환 명령을 전송합니다.`
          break
        case 'autoMission':
          title = `자동`
          contents = `비행체에 시작, 도착 지점을 전송하고, 자동 비행 명령을 전송합니다.`
          break
        case 'loadFence':
          title = `지오펜스`
          contents = `비행체에 지오펜스 정보를 전송합니다.`
          break
        case 'PATTERN':
          title = `패턴`
          contents = `비행체에 패턴 정보를 전송합니다.`
          break
        case 'INTEREST':
          title = `관심`
          contents = `비행체에 관심 포인트를 전송합니다.`
          break
        case 'capture':
          title = `촬영`
          contents = `비행체에 촬영 명령을 전송합니다.`
          break
      }

      for (let drone of state.missionDroneList) {
        droneNameArr.push(drone.name)
        for (let data of state.droneListData) {
          if (drone.name == data.droneName) data.selectedDrone = drone
          if (props.tabMenu == 'autoMission') {
            if (data.useRoi == 1) {
              const roiInfo = props.mainState.waypointList.find(wp => wp.pointType == 'roi' && wp.drone == drone.name)
              data.roiPoint = roiInfo.roiPoint
              if (roiInfo == undefined) {
                fnModalAlert('알람', '관심 포인트를 저장해주세요.', '')
                close()
                return
              }
            }
          }
        }
      }

      let textParams = {
        title: title,
        contents: contents,
        name: droneNameArr.join()
      }
      missionConfirm(textParams, state.droneListData, props.mainState, close, () => {})
    }

    const fnChange = (index, input, type) => {
      commit('local/set' + `${props.tabMenu}`, { input, type })
    }

    const zoomToGeofence = event => {
      dispatch('layout/updateActiveLayers', ['geofenceLayer'])
      const geofenceList = props.layers.geofenceLayer.getSource().getFeatures()
      const gotoGeofence = geofenceList.find(feature => {
        let result
        if (feature.get('prop').id == event.target.value) {
          result = feature
        }
        return result
      })
      const geofenceInfo = gotoGeofence.get('prop')
      //! 멀티변경
      state.droneListData[0].vertex = geofenceInfo.position
      state.droneListData[0].geofenceType = geofenceInfo.type
      state.droneListData[0].radius = geofenceInfo.radius
      state.droneListData[0].inclusion = geofenceInfo.inclusion
      // state.droneListData[0].violationAct = 4
      // state.droneListData[0].violationPosition = 1
      const coord = gotoGeofence.getGeometry()
      props.map.getView().fit(coord, { duration: 500, maxZoom: 16 })
    }

    const close = () => {
      props.layers.assistLayer.getSource().clear()
      proxy.$emit('close', '')
    }

    const fnSlider = (min, max, val) => {
      return `linear-gradient(90deg, #E0E0E0 0%, #E0E0E0 ${((val - min) / (max - min)) * 100}%, #FFE5C7 ${((val - min) / (max - min)) *
        100}%, #FFE5C7 100%) !important`
    }

    const fnEnd = () => {
      const el = proxy.$refs.scroll
      el.style = 'overflow-x: auto'
    }

    const fnStart = () => {
      const el = proxy.$refs.scroll
      el.style = 'overflow-x: scroll '
    }

    const fnDown = index => {
      console.log('down:: ', index)
    }

    const fnSetValue = value => {
      console.log(value)
    }

    const fnTabShow = index => {
      const _tag = document.getElementById(index).className === 'select-tab on'
      if (state.showMode[index]) {
        state.showMode[index] = _tag
      } else {
        state.showMode[index] = !_tag
      }
    }

    const fnSelect = (id, idx) => {
      state.selectedVersion = 'v' + idx
      state.showMode[id] = false
    }

    const fnValidDroneListData = droneListData => {
      let passNumber = /^[0-9]/g
      let passNumberText = '0 이상의 숫자로 입력해주세요.'
      let twoThousandRangeText = '0 ~ 2000 범위 내, 숫자로 입력해주세요.'
      let oneHundredRangeText = '0 ~ 100 범위 내, 숫자로 입력해주세요.'

      switch (props.tabMenu) {
        case 'takeoff':
          if (droneListData.globalAlt < 0 || droneListData.globalAlt > 2000) {
            valid.validText = twoThousandRangeText
          } else if (!passNumber.test(droneListData.takeoffDeleyTime)) {
            valid.validText = passNumberText
          } else {
            valid.validText = ''
          }
          break
        case 'move':
          if (droneListData.globalAlt < 0 || droneListData.globalAlt > 2000) {
            valid.validText = twoThousandRangeText
          } else if (droneListData.globalSpeed < 0 || droneListData.globalSpeed > 100) {
            valid.validText = oneHundredRangeText
          } else {
            valid.validText = ''
          }
          break
        case 'alt':
          if (droneListData.globalAlt < 0 || droneListData.globalAlt > 2000) {
            valid.validText = twoThousandRangeText
          } else {
            valid.validText = ''
          }
          break
        case 'speed':
          if (droneListData.globalSpeed < 0 || droneListData.globalSpeed > 100) {
            valid.validText = oneHundredRangeText
          } else {
            valid.validText = ''
          }
          break
        case 'loiter':
          if (droneListData.globalAlt < 0 || droneListData.globalAlt > 2000) {
            valid.validText = twoThousandRangeText
          } else if (droneListData.turnSpeed < 0 || droneListData.turnSpeed > 2000) {
            valid.validText = twoThousandRangeText
          } else if (droneListData.turnRadius < 0 || droneListData.turnRadius > 2000) {
            valid.validText = twoThousandRangeText
          } else {
            valid.validText = ''
          }
          break
        case 'autoMission':
          if (!droneListData.startIndex || !droneListData.endIndex) {
            valid.validText = 'Index 번호를 입력해주세요.'
          } else if (droneListData.globalAlt < 0 || droneListData.globalAlt > 2000) {
            valid.validText = twoThousandRangeText
          } else if (droneListData.globalSpeed < 0 || droneListData.globalSpeed > 100) {
            valid.validText = oneHundredRangeText
          } else if (!passNumber.test(droneListData.autoDelay)) {
            valid.validText = passNumberText
          } else {
            valid.validText = ''
          }
          break
      }

      if (!valid.validText) return true
      else return false
    }

    const initializeMissionData = (item, props, state) => {
      const waypoints = state.waypointList.filter(wp => wp.drone == item.name)
      const relatedPattern = state.patternList.filter(pattern => pattern.droneList.some(drone => drone.name == item.name))
      const missionData = {
        droneName: item.name,
        color: item.color,
        wpList: waypoints,
        param: props.tabMenu,
        globalAlt: state.initialAlt,
        globalSpeed: state.initialSpeed,
        takeoffDeleyTime: 7,
        mode: state.mode,
        patternList: relatedPattern,
        selectedPattern: relatedPattern.length !== 0 ? relatedPattern[0] : 'none',
        missionControl: 'none',
        azimuthControl: 'lock',
        moveRadio: 0,
        moveDistance: 0,
        wpInfo: { alt: 0, spd: 0 },
        turnRadio: 0,
        turnNum: 0,
        turnRadius: 0,
        turnSpeed: 0,
        autoDelay: 0,
        endIndex: null,
        startIndex: null,
        useRoi: 0,
        interestDistance: 0,
        vertex: null,
        inclusion: true,
        geofenceType: null,
        violationAct: 4,
        violationPosition: 1
      }

      return missionData
    }

    const missionDataInit = () => {
      for (const item of state.missionDroneList) {
        const missionData = initializeMissionData(item, props, state)
        state.droneListData.push(missionData)
      }
    }

    return {
      ...toRefs(state),
      ...toRefs(valid),
      getGeofence,
      changeMode,
      missionConfirm,
      startMission,
      fnDown,
      fnChange,
      fnSetValue,
      fnStart,
      fnEnd,
      zoomToGeofence,
      close,
      fnSlider,
      fnTabShow,
      fnSelect,
      fnValidDroneListData,
      missionDataInit
    }
  }
}
</script>

<style scoped>
select option[value=''][disabled] {
  display: none;
}
.droneSvg {
  width: 2.6rem;
  height: 2.2rem;
  margin-right: 0.4rem;
}
.modePlus {
  position: absolute;
  background-color: white;
  top: -2rem;
  left: 17rem;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  width: 5%;
  height: 36px;
}
</style>
