import VectorLayer from 'ol/layer/Vector'
import TileLayer from 'ol/layer/Tile'
import XYZ from 'ol/source/XYZ'
import Feature from 'ol/Feature'
import { Icon, Fill, Stroke, Text, Style, Circle as CircleStyle, RegularShape } from 'ol/style'
import { Point, LineString, Circle, Polygon } from 'ol/geom'
import VectorSource from 'ol/source/Vector'
import MultiPolygon from 'ol/geom/MultiPolygon'
import LayerGroup from 'ol/layer/Group'
import { Select, Modify, Translate, DoubleClickZoom } from 'ol/interaction'
import { transform, transformExtent } from 'ol/proj'
import 'ol-contextmenu/dist/ol-contextmenu.min.css'
import * as olExtent from 'ol/extent'
import { singleClick, click } from 'ol/events/condition'
import { getDistance, getArea, getLength } from 'ol/sphere'

import * as turf from '@turf/turf'
import $ from 'jquery'
import axios from 'axios'

// svg 파일 이미지
// import patternStartImg from '@/assets/static/images/pattern-start.png'
import patternStartImg from '@/assets/static/images/start-button.png'
import arrowImg from '@/assets/static/images/mapicon/arrow.png'
import ConfigUtils from '@/services/common/Config'
import TokenUtils from '@/services/auth/Token'
import { isUndefined } from 'underscore'
import { Collection } from 'ol'

const API_URL = ConfigUtils.getServiceUrl()

class OlUtils {
  //* VectorLayer 생성
  createVectorLayer(layerId, layerName, style) {
    var layer = new VectorLayer({
      id: layerId,
      name: layerId,
      info: layerName,
      style: function(feature, resolution) {
        //eslint-disable-line no-unused-vars
        var styleOption = {}
        if (style.fillColor) {
          styleOption.fill = new Fill({
            color: style.fillColor
          })
        }
        if (style.strokeColor) {
          styleOption.stroke = new Stroke({
            color: style.strokeColor,
            width: style.strokeWidth
          })
        }
        if (style.text) {
          var fontWeight = ''
          if (style.fontWeight) fontWeight = style.fontWeight
          var fontColor = '#000'
          if (style.fontColor) fontColor = style.fontColor
          var fontSize = 12
          if (style.fontSize) fontSize = style.fontSize
          var offsetY = 10
          if (style.offsetY) offsetY = style.offsetY
          var text = ''
          if (feature.get(style.text) != undefined) text = feature.get(style.text).toString()
          styleOption.text = new Text({
            font: fontWeight + ' ' + fontSize + 'px dotum,arial',
            fill: new Fill({
              color: fontColor
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 2
            }),
            offsetY: offsetY,
            text: text
          })
        }
        return new Style(styleOption)
      }
    })
    layer.setSource(new VectorSource())
    return layer
  }

  //* TileLayer 생성
  createTileLayer(proxy, state) {
    let layer
    if (state.rfChannel == 'RF') {
      layer = new TileLayer({
        source: new XYZ({
          maxZoom: 20,
          minZoom: 18,
          tileUrlFunction: async tileCoord => {
            return ''
          },
          tileLoadFunction: async (imageTile, src) => {
            const coordinates = imageTile.getTileCoord()
            const url = 'OSM_' + coordinates[0] + '_' + coordinates[1] + '_' + (-coordinates[2] - 1)
            let row = await proxy.$OfflineUtils.getMapByOSM(url)

            if (row.data.length !== 0) {
              imageTile.getImage().src = row.data[0].image
            } else {
              imageTile.getImage().src = src
            }
          }
        })
      })
    } else {
      layer = new TileLayer({
        source: new XYZ({ url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}' })
      })
    }
    return layer
  }

  downloadOfflineMapTile(state, osmextent, zoomLevel) {
    let source = new XYZ({ url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}' })
    let extent = transformExtent(osmextent, 'EPSG:4326', 'EPSG:3857')
    for (let i = 0; i <= 21 - zoomLevel.toFixed(0); i++) {
      const zoom = parseInt(zoomLevel.toFixed(0)) + i
      source.getTileGrid().forEachTileCoord(extent, zoom, tileCoord => {
        let img = document.createElement('img')
        img.onload = () => {
          let canvas = document.createElement('canvas')
          canvas.width = source.getTileGrid().getTileSize(zoomLevel)
          canvas.height = source.getTileGrid().getTileSize(zoomLevel)
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0)
          state.mapItem.push({ osm: 'OSM_' + tileCoord[0] + '_' + tileCoord[1] + '_' + (-tileCoord[2] - 1), image: canvas.toDataURL() })
          img.remove()
          canvas.remove()
        }
        img.crossOrigin = 'Anonymous'
        img.src = source.getTileUrlFunction()(tileCoord)
      })
    }
  }

  b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = Buffer.from(b64Data, 'base64')
    const byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  //* 드론 feature 생성
  createDroneFeature(droneInfo, mapType) {
    let styleOption
    if (mapType == 1) {
      let basicDroneImg = color => {
        return `<svg width="53" height="44" viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_355_73435)">
        <path d="M8.54422 15.8316C12.4356 15.8316 15.5902 12.723 15.5902 8.88845C15.5902 5.05386 12.4356 1.94531 8.54422 1.94531C4.65286 1.94531 1.49829 5.05386 1.49829 8.88845C1.49829 12.723 4.65286 15.8316 8.54422 15.8316Z" stroke="${color}" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M42.4451 15.8316C46.3365 15.8316 49.491 12.723 49.491 8.88845C49.491 5.05386 46.3365 1.94531 42.4451 1.94531C38.5537 1.94531 35.3992 5.05386 35.3992 8.88845C35.3992 12.723 38.5537 15.8316 42.4451 15.8316Z" stroke="${color}" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M8.60642 41.7082C12.7973 41.7082 16.1948 38.3604 16.1948 34.2306C16.1948 30.1008 12.7973 26.7529 8.60642 26.7529C4.41549 26.7529 1.01807 30.1008 1.01807 34.2306C1.01807 38.3604 4.41549 41.7082 8.60642 41.7082Z" stroke="${color}" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M10.5965 8.52514L9.58374 10.6499L18.2086 14.642L19.2214 12.5172L10.5965 8.52514Z" fill="${color}"/>
        <path d="M40.5768 8.52156L31.9519 12.5137L32.9647 14.6384L41.5896 10.6463L40.5768 8.52156Z" fill="${color}"/>
        <path d="M34.6206 25.8516L33.0557 28.2785L40.1767 33.9034L42.4509 31.9821L34.6206 25.8516Z" fill="${color}"/>
        <path d="M16.5519 25.8516L8.72168 31.9821L10.9958 33.9034L18.1169 28.2785L16.5519 25.8516Z" fill="${color}"/>
        <path d="M20.1271 19.1056L22.8795 17.3558C24.6937 16.202 27.0265 16.202 28.8425 17.3558L31.5949 19.1056C32.1593 19.465 32.6412 19.9182 33.0297 20.4347L32.3334 12.8866C32.2967 12.4983 32.1703 12.1209 31.9669 11.7995L28.6831 6.60435C28.3734 6.11319 27.8676 5.81885 27.3398 5.81885H24.0927C23.5649 5.81885 23.0646 6.11319 22.7623 6.60435L19.5627 11.7995C19.3648 12.1209 19.2439 12.4965 19.2146 12.8866L18.6355 20.5069C19.0331 19.9579 19.5353 19.4812 20.1271 19.1038V19.1056Z" fill="#F9F9F9" stroke="${color}" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M33.0299 20.4345C32.6414 19.918 32.1595 19.4648 31.5951 19.1054L28.8427 17.3556C27.0285 16.2018 24.6957 16.2018 22.8797 17.3556L20.1273 19.1054C19.5354 19.481 19.0333 19.9595 18.6357 20.5085C17.9797 21.415 17.6113 22.5111 17.6113 23.6577V28.2498C17.6113 30.0899 18.5587 31.8035 20.1273 32.8021L22.8797 34.5519C24.6939 35.7058 27.0267 35.7058 28.8427 34.5519L31.5951 32.8021C33.1637 31.8035 34.1111 30.0899 34.1111 28.2498V23.6577C34.1111 22.4804 33.7208 21.3554 33.0299 20.4345V20.4345Z" fill="#F9F9F9" stroke="${color}" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M10.8515 9.24368C11.0501 7.98829 10.1783 6.81195 8.90431 6.61626C7.63033 6.42057 6.43658 7.27963 6.23799 8.53502C6.03941 9.79041 6.91118 10.9667 8.18516 11.1624C9.45913 11.3581 10.6529 10.4991 10.8515 9.24368Z" fill="${color}"/>
        <path d="M11.293 4.19718C10.8551 3.94798 10.2943 4.09605 10.0414 4.52763L5.4602 12.3466C5.20731 12.7781 5.35758 13.3307 5.79554 13.5799C6.23351 13.8291 6.79425 13.681 7.04714 13.2494L11.6284 5.43051C11.8813 4.99893 11.731 4.44637 11.293 4.19718V4.19718Z" fill="${color}"/>
        <path d="M43.1325 11.0901C44.3647 10.7161 45.056 9.42854 44.6764 8.21428C44.2968 7.00003 42.9902 6.31888 41.758 6.6929C40.5258 7.06692 39.8345 8.35446 40.2141 9.56872C40.5937 10.783 41.9003 11.4641 43.1325 11.0901Z" fill="${color}"/>
        <path d="M45.5292 12.3466L40.948 4.52763C40.6951 4.09605 40.1344 3.94798 39.6964 4.19718C39.2585 4.44637 39.1082 4.99893 39.3611 5.43051L43.9423 13.2494C44.1952 13.681 44.7559 13.8291 45.1939 13.5799C45.6319 13.3307 45.7821 12.7781 45.5292 12.3466V12.3466Z" fill="${color}"/>
        <path d="M8.54414 36.7131C9.97013 36.7131 11.1261 35.574 11.1261 34.1688C11.1261 32.7636 9.97013 31.6245 8.54414 31.6245C7.11815 31.6245 5.96216 32.7636 5.96216 34.1688C5.96216 35.574 7.11815 36.7131 8.54414 36.7131Z" fill="${color}"/>
        <path d="M4.31446 29.8676L4.1784 30.0017C3.82059 30.3543 3.82059 30.926 4.1784 31.2786L11.4775 38.4711C11.8353 38.8237 12.4154 38.8237 12.7732 38.4711L12.9093 38.3371C13.2671 37.9845 13.2671 37.4128 12.9093 37.0602L5.61022 29.8676C5.25241 29.515 4.67227 29.515 4.31446 29.8676Z" fill="${color}"/>
        <path d="M42.8117 36.7136C44.2377 36.7136 45.3937 35.5745 45.3937 34.1693C45.3937 32.7641 44.2377 31.625 42.8117 31.625C41.3857 31.625 40.2297 32.7641 40.2297 34.1693C40.2297 35.5745 41.3857 36.7136 42.8117 36.7136Z" fill="${color}"/>
        <path d="M45.7448 29.8653L38.4457 37.0579C38.0879 37.4105 38.0879 37.9821 38.4457 38.3347L38.5818 38.4688C38.9396 38.8214 39.5197 38.8214 39.8776 38.4688L47.1766 31.2762C47.5344 30.9236 47.5344 30.3519 47.1766 29.9993L47.0406 29.8653C46.6827 29.5127 46.1026 29.5127 45.7448 29.8653Z" fill="${color}"/>
        <path d="M50.096 37.0916C51.6998 33.2761 49.8612 28.9019 45.9893 27.3215C42.1173 25.7411 37.6784 27.553 36.0746 31.3684C34.4708 35.1838 36.3095 39.558 40.1814 41.1384C44.0533 42.7188 48.4922 40.907 50.096 37.0916Z" stroke="${color}" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M19.6765 23.6235V28.0693C19.6765 29.1256 20.2226 30.108 21.1278 30.6768L24.2193 32.6216C25.2491 33.2698 26.5667 33.2698 27.5984 32.6216L30.6898 30.6768C31.5932 30.108 32.1411 29.1256 32.1411 28.0693V23.6235C32.1411 22.5671 31.595 21.5848 30.6898 21.016L27.5984 19.0712C26.5685 18.4229 25.251 18.4229 24.2193 19.0712L21.1278 21.016C20.2244 21.5848 19.6765 22.5671 19.6765 23.6235V23.6235Z" fill="${color}"/>
        <path style="mix-blend-mode:multiply" opacity="0.75" d="M30.6587 20.9436L27.5966 19.0186C26.5448 18.3577 25.1997 18.3577 24.1497 19.0186L21.0876 20.9436C20.1658 21.5232 19.6069 22.5254 19.6069 23.6035V28.0203C19.6069 29.0966 20.1658 30.1006 21.0876 30.6802L24.1497 32.6052C25.2015 33.2661 26.5466 33.2661 27.5966 32.6052L30.6587 30.6802C31.5804 30.1006 32.1393 29.0984 32.1393 28.0203V23.6035C32.1393 22.5272 31.5804 21.5232 30.6587 20.9436ZM31.1645 27.4082C31.1645 28.4844 30.6056 29.4884 29.6838 30.0681L27.5966 31.379C26.5448 32.04 25.1997 32.04 24.1497 31.379L22.0625 30.0681C21.1407 29.4884 20.5818 28.4862 20.5818 27.4082V24.2156C20.5818 23.1394 21.1407 22.1354 22.0625 21.5557L24.1497 20.2447C25.2015 19.5838 26.5466 19.5838 27.5966 20.2447L29.6838 21.5557C30.6056 22.1354 31.1645 23.1376 31.1645 24.2156V27.4082V27.4082Z" fill="url(#paint0_linear_355_73435)"/>
        <path d="M20.7979 13.5343C21.7142 12.6495 22.6414 11.7755 23.5448 10.8781C24.1679 10.2569 24.7341 9.58154 25.3791 8.98383C25.5276 8.8466 26.0187 8.80687 26.1341 8.92424C27.7174 10.5296 29.2659 12.171 30.8143 13.807C30.8656 13.8612 30.8546 13.9713 30.873 14.0562H28.1737C27.3875 13.1804 26.6014 12.3028 25.7768 11.3855C24.8935 12.3444 24.1037 13.2003 23.3158 14.0562C22.6267 14.0562 21.9286 13.9966 21.2487 14.0743C20.677 14.1411 20.8052 13.8215 20.7961 13.5343H20.7979Z" fill="${color}"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_355_73435" x1="22.5884" y1="21.7309" x2="30.2347" y2="31.5102" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stop-color="white"/>
        <stop offset="0.46" stop-color="#939393"/>
        <stop offset="0.93" stop-color="#242424"/>
        </linearGradient>
        <clipPath id="clip0_355_73435">
        <rect width="53" height="44" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        
          `
      }
      styleOption = {
        image: new Icon({
          src: 'data:image/svg+xml;utf8,' + escape(basicDroneImg(droneInfo.color)),
          scale: 0.85,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction'
        }),
        text: new Text({
          fill: new Fill({
            color: 'white'
          }),
          backgroundStroke: new Stroke({
            color: 'black',
            width: 3.5
          }),
          backgroundFill: new Fill({
            color: 'black',
            opacity: 0.5
          }),
          offsetY: 38,
          offsetX: -2.5,
          text: '  ' + droneInfo.name + '  '
        })
      }
    } else if (mapType == 2) {
      let stlDroneImg = color => {
        return `<svg width="61" height="52" viewBox="0 0 61 52" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_355_72445)">
        <path d="M13.2506 19.2273C17.0961 18.6012 19.7075 14.967 19.0833 11.11C18.459 7.25303 14.8356 4.63387 10.9901 5.25994C7.14452 5.88602 4.53313 9.52025 5.15735 13.3772C5.78157 17.2342 9.40502 19.8534 13.2506 19.2273Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M52.577 14.9518C54.0679 11.3417 52.3587 7.20303 48.7594 5.70771C45.1601 4.21239 41.0336 5.9267 39.5427 9.53672C38.0519 13.1467 39.7611 17.2854 43.3604 18.7808C46.9597 20.2761 51.0861 18.5618 52.577 14.9518Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M12.1811 45.6868C16.3769 45.6868 19.7783 42.2753 19.7783 38.067C19.7783 33.8587 16.3769 30.4473 12.1811 30.4473C7.98534 30.4473 4.58398 33.8587 4.58398 38.067C4.58398 42.2753 7.98534 45.6868 12.1811 45.6868Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M14.1739 11.8725L13.1599 14.0376L21.7948 18.1056L22.8088 15.9404L14.1739 11.8725Z" fill="white"/>
        <path d="M44.1881 11.8681L35.5532 15.936L36.5672 18.1012L45.2021 14.0332L44.1881 11.8681Z" fill="white"/>
        <path d="M38.2255 29.5273L36.6587 32.0022L43.788 37.7341L46.0648 35.7762L38.2255 29.5273Z" fill="white"/>
        <path d="M20.3216 29.5273L12.4805 35.7762L14.7572 37.7341L21.8865 32.0022L20.3216 29.5273Z" fill="white"/>
        <path d="M23.7155 22.6545L26.4711 20.8715C28.2874 19.6957 30.6229 19.6957 32.441 20.8715L35.1966 22.6545C35.7616 23.0207 36.2441 23.4825 36.6331 24.0088L35.9359 16.3173C35.8992 15.9217 35.7726 15.5371 35.569 15.2096L32.2814 9.91567C31.9713 9.41517 31.465 9.11523 30.9366 9.11523H27.6857C27.1573 9.11523 26.6564 9.41517 26.3537 9.91567L23.1505 15.2096C22.9523 15.5371 22.8313 15.9198 22.8019 16.3173L22.2222 24.0824C22.6203 23.523 23.123 23.0372 23.7155 22.6527V22.6545Z" fill="${color}" stroke="white" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M36.6293 24.0343C36.2404 23.5081 35.7579 23.0462 35.1928 22.68L32.4372 20.897C30.621 19.7212 28.2855 19.7212 26.4674 20.897L23.7118 22.68C23.1192 23.0628 22.6165 23.5504 22.2184 24.1098C21.5616 25.0335 21.1929 26.1504 21.1929 27.3189V31.9982C21.1929 33.8732 22.1414 35.6194 23.7118 36.637L26.4674 38.42C28.2837 39.5958 30.6191 39.5958 32.4372 38.42L35.1928 36.637C36.7633 35.6194 37.7117 33.8732 37.7117 31.9982V27.3189C37.7117 26.1191 37.321 24.9728 36.6293 24.0343V24.0343Z" fill="${color}" stroke="white" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M13.2875 10.2121C12.1684 9.56439 10.7393 9.94896 10.0935 11.0696C9.4477 12.192 9.83113 13.6254 10.9484 14.2731C12.0657 14.9208 13.4967 14.5363 14.1425 13.4157C14.7883 12.2932 14.4048 10.8598 13.2875 10.2121V10.2121Z" fill="white"/>
        <path d="M14.871 7.46071C14.4325 7.20678 13.8711 7.35766 13.6179 7.79744L9.03139 15.765C8.77821 16.2047 8.92865 16.7678 9.36712 17.0217C9.80559 17.2757 10.367 17.1248 10.6202 16.685L15.2067 8.71748C15.4599 8.2777 15.3094 7.71464 14.871 7.46071V7.46071Z" fill="white"/>
        <path d="M46.0592 14.5874C47.3501 14.5874 48.3965 13.5379 48.3965 12.2432C48.3965 10.9485 47.3501 9.89893 46.0592 9.89893C44.7684 9.89893 43.7219 10.9485 43.7219 12.2432C43.7219 13.5379 44.7684 14.5874 46.0592 14.5874Z" fill="white"/>
        <path d="M49.1469 15.7674L44.5603 7.79988C44.3072 7.3601 43.7458 7.20922 43.3073 7.46315C42.8688 7.71708 42.7184 8.28014 42.9716 8.71992L47.5581 16.6874C47.8113 17.1272 48.3727 17.2781 48.8112 17.0242C49.2496 16.7702 49.4001 16.2072 49.1469 15.7674Z" fill="white"/>
        <path d="M12.1189 40.5955C13.5465 40.5955 14.7039 39.4347 14.7039 38.0028C14.7039 36.5709 13.5465 35.4102 12.1189 35.4102C10.6913 35.4102 9.53394 36.5709 9.53394 38.0028C9.53394 39.4347 10.6913 40.5955 12.1189 40.5955Z" fill="white"/>
        <path d="M7.88495 33.6205L7.74873 33.7572C7.3905 34.1164 7.3905 34.699 7.74873 35.0583L15.0562 42.3875C15.4145 42.7468 15.9953 42.7468 16.3535 42.3875L16.4897 42.2509C16.848 41.8916 16.848 41.3091 16.4897 40.9498L9.18221 33.6205C8.82398 33.2612 8.24318 33.2612 7.88495 33.6205Z" fill="white"/>
        <path d="M46.426 40.5955C47.8537 40.5955 49.011 39.4347 49.011 38.0028C49.011 36.5709 47.8537 35.4102 46.426 35.4102C44.9984 35.4102 43.8411 36.5709 43.8411 38.0028C43.8411 39.4347 44.9984 40.5955 46.426 40.5955Z" fill="white"/>
        <path d="M49.3644 33.6198L42.0568 40.949C41.6986 41.3083 41.6986 41.8909 42.0568 42.2502L42.1931 42.3868C42.5513 42.7461 43.1321 42.7461 43.4903 42.3868L50.7978 35.0575C51.1561 34.6982 51.1561 34.1157 50.7978 33.7564L50.6616 33.6198C50.3034 33.2605 49.7226 33.2605 49.3644 33.6198Z" fill="white"/>
        <path d="M46.7014 45.6868C50.8972 45.6868 54.2985 42.2753 54.2985 38.067C54.2985 33.8587 50.8972 30.4473 46.7014 30.4473C42.5056 30.4473 39.1042 33.8587 39.1042 38.067C39.1042 42.2753 42.5056 45.6868 46.7014 45.6868Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>
        <path d="M23.2146 27.3926V31.9228C23.2146 32.9993 23.7613 34.0003 24.6676 34.5799L27.7626 36.5617C28.7937 37.2222 30.1128 37.2222 31.1456 36.5617L34.2406 34.5799C35.1451 34.0003 35.6937 32.9993 35.6937 31.9228V27.3926C35.6937 26.3161 35.1469 25.3151 34.2406 24.7355L31.1456 22.7537C30.1146 22.0932 28.7955 22.0932 27.7626 22.7537L24.6676 24.7355C23.7632 25.3151 23.2146 26.3161 23.2146 27.3926Z" fill="${color}"/>
        <path style="mix-blend-mode:multiply" opacity="0.75" d="M34.2423 24.6986L31.1767 22.737C30.1236 22.0636 28.777 22.0636 27.7257 22.737L24.6601 24.6986C23.7373 25.2892 23.1777 26.3105 23.1777 27.409V31.9098C23.1777 33.0065 23.7373 34.0296 24.6601 34.6202L27.7257 36.5818C28.7788 37.2552 30.1254 37.2552 31.1767 36.5818L34.2423 34.6202C35.1651 34.0296 35.7247 33.0083 35.7247 31.9098V27.409C35.7247 26.3123 35.1651 25.2892 34.2423 24.6986ZM34.7487 31.286C34.7487 32.3827 34.1891 33.4058 33.2663 33.9964L31.1767 35.3323C30.1236 36.0058 28.777 36.0058 27.7257 35.3323L25.6361 33.9964C24.7133 33.4058 24.1537 32.3845 24.1537 31.286V28.0328C24.1537 26.9361 24.7133 25.913 25.6361 25.3223L27.7257 23.9864C28.7788 23.313 30.1254 23.313 31.1767 23.9864L33.2663 25.3223C34.1891 25.913 34.7487 26.9342 34.7487 28.0328V31.286Z" fill="url(#paint0_linear_355_72445)"/>
        <path d="M24.4183 16.8342C25.3356 15.9326 26.2639 15.042 27.1684 14.1275C27.7921 13.4945 28.359 12.8063 29.0048 12.1972C29.1534 12.0574 29.6451 12.0169 29.7607 12.1365C31.3458 13.7723 32.896 15.4449 34.4463 17.1121C34.4977 17.1673 34.4866 17.2795 34.505 17.366H31.8026C31.0156 16.4736 30.2285 15.5793 29.4029 14.6445C28.5186 15.6216 27.7279 16.4938 26.939 17.366C26.2492 17.366 25.5502 17.3053 24.8696 17.3844C24.2972 17.4525 24.4256 17.1268 24.4164 16.8342H24.4183Z" fill="${color}"/>
        </g>
        <defs>
        <filter id="filter0_d_355_72445" x="0" y="0" width="61" height="52" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_72445"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_72445" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_355_72445" x1="26.1627" y1="25.5008" x2="33.9864" y2="35.3299" gradientUnits="userSpaceOnUse">
        <stop offset="0.02" stop-color="white"/>
        <stop offset="0.46" stop-color="#939393"/>
        <stop offset="0.93" stop-color="#242424"/>
        </linearGradient>
        </defs>
        </svg>
        
        `
      }
      styleOption = {
        image: new Icon({
          src: 'data:image/svg+xml;utf8,' + escape(stlDroneImg(droneInfo.color)),
          scale: 0.85,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction'
        }),
        text: new Text({
          fill: new Fill({
            color: 'white'
          }),
          backgroundStroke: new Stroke({
            color: 'black',
            width: 3.5
          }),
          backgroundFill: new Fill({
            color: 'black',
            opacity: 0.5
          }),
          offsetY: 38,
          offsetX: -2.5,
          text: '  ' + droneInfo.name + '  '
        })
      }
    }

    var feature = new Feature({
      geometry: new Point([0, 0]),
      layer: 'droneLayer',
      droneId: droneInfo.id, // 사용안하면 삭제
      name: droneInfo.name,
      ae: droneInfo.ae,
      sysId: droneInfo.sysId,
      autopilot: droneInfo.data.autopilot,
      color: droneInfo.color
    })
    feature.setId(droneInfo.id)
    feature.setStyle(new Style(styleOption))
    return feature
  }

  //* 드론 경로 생성
  dronePath(ctrlDrone, layers) {
    layers.dronePathLayer
      .getSource()
      .getFeatures()
      .forEach(feature => {
        if (feature.get('drone') == ctrlDrone.name) {
          layers.dronePathLayer.getSource().removeFeature(feature)
        }
      })
    const styleOption = {
      stroke: new Stroke({
        color: ctrlDrone.color,
        width: 5
      })
    }
    const line = new Feature({
      geometry: new LineString(ctrlDrone.flightPath),
      layer: 'dronePathLayer',
      drone: ctrlDrone.name
    })
    line.setStyle(new Style(styleOption))
    layers.dronePathLayer.getSource().addFeature(line)
  }

  //* waypoint feature 생성
  createWayPointFeature(wpInfo) {
    let basicWpImg = color => {
      return (
        '<svg width="44" height="65" viewBox="0 0 44 65" fill="' +
        color +
        '" xmlns="http://www.w3.org/2000/svg">' +
        '<g clip-path="url(#clip0_350_70512)">' +
        '<g filter="url(#filter0_d_350_70512)">' +
        '<path d="M22 60.001C31.9411 60.001 40 56.4193 40 52.001C40 47.5827 31.9411 44.001 22 44.001C12.0589 44.001 4 47.5827 4 52.001C4 56.4193 12.0589 60.001 22 60.001Z" fill="' +
        color +
        '"/>' +
        '</g>' +
        '<path d="M35.2013 30.2941L25.5055 39.6986C25.0816 40.1111 24.5779 40.4384 24.0233 40.6617C23.4687 40.885 22.8741 41 22.2736 41C21.6731 41 21.0785 40.885 20.5239 40.6617C19.9693 40.4384 19.4656 40.1111 19.0416 39.6986L9.6202 30.2941C7.0609 27.8129 6.07279 24.6512 5.36623 21.2089C4.65967 17.7667 3.98185 14.1985 5.36623 10.9557C6.75061 7.71287 9.0954 4.94112 12.104 2.991C15.1126 1.04089 18.6499 0 22.2685 0C25.8871 0 29.4243 1.04089 32.433 2.991C35.4416 4.94112 37.7864 7.71287 39.1707 10.9557C40.5551 14.1985 40.9169 17.7667 40.2104 21.2089C39.5038 24.6512 37.7606 27.8129 35.2013 30.2941Z" fill="white"/>' +
        '<path d="M31.3101 29.5798L22.8212 38.7786C22.4666 39.1758 22.0314 39.4938 21.5443 39.7116C21.0571 39.9294 20.529 40.042 19.9948 40.042C19.4606 40.042 18.9325 39.9294 18.4454 39.7116C17.9582 39.4938 17.523 39.1758 17.1685 38.7786L8.68945 29.5798C6.41969 27.0895 4.90069 24.0145 4.30614 20.7063C3.69112 17.3468 4.00649 13.8843 5.2185 10.6892C6.39144 7.6019 8.43713 4.91755 11.1092 2.95946C13.6816 1.06475 16.7983 0.0419922 19.9998 0.0419922C23.2012 0.0419922 26.3179 1.06475 28.8903 2.95946C31.5729 4.93022 33.6226 7.63248 34.791 10.7385C35.9955 13.9183 36.3074 17.3638 35.6934 20.7063C35.0999 24.0148 33.5807 27.0901 31.3101 29.5798Z" fill="' +
        color +
        '"/>' +
        '<path d="M19.8701 22.5308C24.0123 22.5308 27.3701 19.1729 27.3701 15.0308C27.3701 10.8886 24.0123 7.53076 19.8701 7.53076C15.728 7.53076 12.3701 10.8886 12.3701 15.0308C12.3701 19.1729 15.728 22.5308 19.8701 22.5308Z" fill="white"/>' +
        '</g>' +
        '<defs>' +
        '<filter id="filter0_d_350_70512" x="0" y="42.001" width="44" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
        '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
        '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
        '<feOffset dy="2"/>' +
        '<feGaussianBlur stdDeviation="2"/>' +
        '<feComposite in2="hardAlpha" operator="out"/>' +
        '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>' +
        '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_350_70512"/>' +
        '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_350_70512" result="shape"/>' +
        '</filter>' +
        '<clipPath id="clip0_350_70512">' +
        '<rect width="44" height="65" fill="white"/>' +
        '</clipPath>' +
        '</defs>' +
        '</svg>'
      )
    }

    let color = '#7b7b7b'
    const index = wpInfo.cuAt.toString()

    if (index !== '0') color = wpInfo.color
    const wpStyle = {
      image: new Icon({
        src: 'data:image/svg+xml;utf8,' + escape(basicWpImg(color)),
        scale: 0.5,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      }),
      text: new Text({
        fill: new Fill({
          color: 'white'
        }),
        backgroundStroke: new Stroke({
          color: 'black',
          width: 3.5
        }),
        backgroundFill: new Fill({
          color: 'black',
          opacity: 0.5
        }),
        offsetY: -27,
        offsetX: -2.5,
        text: '  ' + index + ' : ' + wpInfo.alt + '  '
      })
    }
    var feature = new Feature({
      geometry: new Point([wpInfo.lng, wpInfo.lat]),
      layer: 'wayPointLayer',
      prop: wpInfo
    })
    feature.setStyle(new Style(wpStyle))
    return feature
  }

  //* waypoint 경로 생성
  waypointPath(state, layers) {
    layers.wpPathLayer.getSource().clear()
    let wpArr = []

    for (let drone of state.ctrlDroneList) {
      for (let wpInfo of state.waypointList) {
        if (wpInfo.drone === drone.name && wpInfo.pointType !== 'roi') {
          wpArr.push([wpInfo.lng, wpInfo.lat])
        }
      }
      let setDistance
      for (let i = 0; i < wpArr.length - 1; i++) {
        const distance = getDistance(wpArr[i], wpArr[i + 1])
        if (distance > 1000) {
          setDistance = (distance / 1000).toFixed(2) + 'km'
        } else {
          setDistance = distance.toFixed(0) + 'm'
        }

        const wpPathStyle = {
          stroke: new Stroke({
            color: this.addAlpha(drone.color, 0.5),
            width: 10
          }),
          text: new Text({
            fill: new Fill({
              color: 'white'
            }),
            backgroundStroke: new Stroke({
              color: 'black',
              width: 3.5
            }),
            backgroundFill: new Fill({
              color: 'black',
              opacity: 0.5
            }),
            font: '15px',
            offsetY: -20,
            offsetX: -2.5,
            text: setDistance
          })
        }
        const line = new Feature({
          geometry: new LineString([wpArr[i], wpArr[i + 1]]),
          layer: 'wpPathLayer'
        })
        line.setStyle(new Style(wpPathStyle))
        layers.wpPathLayer.getSource().addFeature(line)
      }
      wpArr = []
    }
  }

  //* 홈포지션, roiter 제외 모든 feature 선택 이벤트 관리
  setSelectInteraction(proxy, state, layers, fnModalAlert) {
    const me = this
    let controlModify
    state.map.getInteractions().forEach(interaction => {
      if (interaction instanceof DoubleClickZoom) state.map.removeInteraction(interaction)
    })

    var selectInteraction = new Select({
      layers: layer => {
        const allowedLayers = ['droneLayer', 'wayPointLayer', 'banLayer', 'limitLayer', 'geofenceLayer', 'patternLayer', 'roiLayer']
        const selectedLayerId = layer.get('id')
        if (allowedLayers.includes(selectedLayerId)) {
          return true
        } else {
          return false
        }
      },
      style: feature => {
        const allowedLayers = ['banLayer', 'limitLayer']
        const selectedLayerId = feature.get('layer')
        if (allowedLayers.includes(selectedLayerId)) {
          fnModalAlert('알람', '비행금지구역 입니다.', '')
          return false
        }

        let layer = me.getLayer(state.map, feature)
        let styleOption = {}
        if (layer.get('id') == 'droneLayer') {
          controlModify = false
          let clickDroneImg = color => {
            return (
              '<svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">' +
              '<circle cx="38" cy="38" r="35" stroke="#00FF00" stroke-width="5" stroke-dasharray="10 10"/>' +
              '<g clip-path="url(#clip0_355_73434)">' +
              '<path d="M21.5442 31.8316C25.4356 31.8316 28.5902 28.723 28.5902 24.8885C28.5902 21.0539 25.4356 17.9453 21.5442 17.9453C17.6529 17.9453 14.4983 21.0539 14.4983 24.8885C14.4983 28.723 17.6529 31.8316 21.5442 31.8316Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>' +
              '<path d="M55.4451 31.8316C59.3365 31.8316 62.491 28.723 62.491 24.8885C62.491 21.0539 59.3365 17.9453 55.4451 17.9453C51.5537 17.9453 48.3992 21.0539 48.3992 24.8885C48.3992 28.723 51.5537 31.8316 55.4451 31.8316Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>' +
              '<path d="M21.6064 57.7082C25.7973 57.7082 29.1948 54.3604 29.1948 50.2306C29.1948 46.1008 25.7973 42.7529 21.6064 42.7529C17.4155 42.7529 14.0181 46.1008 14.0181 50.2306C14.0181 54.3604 17.4155 57.7082 21.6064 57.7082Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>' +
              '<path d="M23.5965 24.5251L22.5837 26.6499L31.2086 30.642L32.2214 28.5172L23.5965 24.5251Z" fill="white"/>' +
              '<path d="M53.5768 24.5216L44.9519 28.5137L45.9647 30.6384L54.5896 26.6463L53.5768 24.5216Z" fill="white"/>' +
              '<path d="M47.6206 41.8516L46.0557 44.2785L53.1767 49.9034L55.4509 47.9821L47.6206 41.8516Z" fill="white"/>' +
              '<path d="M29.5519 41.8516L21.7217 47.9821L23.9958 49.9034L31.1169 44.2785L29.5519 41.8516Z" fill="white"/>' +
              '<path d="M33.1271 35.1056L35.8795 33.3558C37.6937 32.202 40.0265 32.202 41.8425 33.3558L44.5949 35.1056C45.1593 35.465 45.6412 35.9182 46.0297 36.4347L45.3334 28.8866C45.2967 28.4983 45.1703 28.1209 44.9669 27.7995L41.6831 22.6044C41.3734 22.1132 40.8676 21.8188 40.3398 21.8188H37.0927C36.5649 21.8188 36.0646 22.1132 35.7623 22.6044L32.5627 27.7995C32.3648 28.1209 32.2439 28.4965 32.2146 28.8866L31.6355 36.5069C32.0331 35.9579 32.5353 35.4812 33.1271 35.1038V35.1056Z" fill="white" stroke="' +
              color +
              '" stroke-width="2" stroke-miterlimit="100"/>' +
              '<path d="M46.0299 36.4345C45.6414 35.918 45.1595 35.4648 44.5951 35.1054L41.8427 33.3556C40.0285 32.2018 37.6957 32.2018 35.8797 33.3556L33.1273 35.1054C32.5354 35.481 32.0333 35.9595 31.6357 36.5085C30.9797 37.415 30.6113 38.5111 30.6113 39.6577V44.2498C30.6113 46.0899 31.5587 47.8035 33.1273 48.8021L35.8797 50.5519C37.6939 51.7058 40.0267 51.7058 41.8427 50.5519L44.5951 48.8021C46.1637 47.8035 47.1111 46.0899 47.1111 44.2498V39.6577C47.1111 38.4804 46.7208 37.3554 46.0299 36.4345V36.4345Z" fill="white" stroke="' +
              color +
              '" stroke-width="2" stroke-miterlimit="100"/>' +
              '<path d="M23.8515 25.2437C24.0501 23.9883 23.1783 22.812 21.9043 22.6163C20.6303 22.4206 19.4366 23.2796 19.238 24.535C19.0394 25.7904 19.9112 26.9667 21.1852 27.1624C22.4591 27.3581 23.6529 26.4991 23.8515 25.2437Z" fill="white"/>' +
              '<path d="M24.293 20.1972C23.8551 19.948 23.2943 20.0961 23.0414 20.5276L18.4602 28.3466C18.2073 28.7781 18.3576 29.3307 18.7955 29.5799C19.2335 29.8291 19.7943 29.681 20.0471 29.2494L24.6284 21.4305C24.8813 20.9989 24.731 20.4464 24.293 20.1972V20.1972Z" fill="white"/>' +
              '<path d="M56.1325 27.0901C57.3647 26.7161 58.056 25.4285 57.6764 24.2143C57.2968 23 55.9902 22.3189 54.758 22.6929C53.5258 23.0669 52.8345 24.3545 53.2141 25.5687C53.5937 26.783 54.9003 27.4641 56.1325 27.0901Z" fill="white"/>' +
              '<path d="M58.5292 28.3466L53.948 20.5276C53.6951 20.0961 53.1344 19.948 52.6964 20.1972C52.2585 20.4464 52.1082 20.9989 52.3611 21.4305L56.9423 29.2494C57.1952 29.681 57.7559 29.8291 58.1939 29.5799C58.6319 29.3307 58.7821 28.7781 58.5292 28.3466V28.3466Z" fill="white"/>' +
              '<path d="M21.5441 52.7131C22.9701 52.7131 24.1261 51.574 24.1261 50.1688C24.1261 48.7636 22.9701 47.6245 21.5441 47.6245C20.1182 47.6245 18.9622 48.7636 18.9622 50.1688C18.9622 51.574 20.1182 52.7131 21.5441 52.7131Z" fill="white"/>' +
              '<path d="M17.3145 45.8676L17.1784 46.0017C16.8206 46.3543 16.8206 46.926 17.1784 47.2786L24.4775 54.4711C24.8353 54.8237 25.4154 54.8237 25.7732 54.4711L25.9093 54.3371C26.2671 53.9845 26.2671 53.4128 25.9093 53.0602L18.6102 45.8676C18.2524 45.515 17.6723 45.515 17.3145 45.8676Z" fill="white"/>' +
              '<path d="M55.8117 52.7136C57.2377 52.7136 58.3937 51.5745 58.3937 50.1693C58.3937 48.7641 57.2377 47.625 55.8117 47.625C54.3857 47.625 53.2297 48.7641 53.2297 50.1693C53.2297 51.5745 54.3857 52.7136 55.8117 52.7136Z" fill="white"/>' +
              '<path d="M58.7448 45.8653L51.4457 53.0579C51.0879 53.4105 51.0879 53.9821 51.4457 54.3347L51.5818 54.4688C51.9396 54.8214 52.5197 54.8214 52.8776 54.4688L60.1766 47.2762C60.5344 46.9236 60.5344 46.3519 60.1766 45.9993L60.0406 45.8653C59.6827 45.5127 59.1026 45.5127 58.7448 45.8653Z" fill="white"/>' +
              '<path d="M63.096 53.0916C64.6998 49.2761 62.8612 44.9019 58.9893 43.3215C55.1173 41.7411 50.6784 43.553 49.0746 47.3684C47.4708 51.1838 49.3095 55.558 53.1814 57.1384C57.0533 58.7188 61.4922 56.907 63.096 53.0916Z" stroke="white" stroke-width="2" stroke-miterlimit="100"/>' +
              '<path d="M32.6765 39.6235V44.0693C32.6765 45.1256 33.2226 46.108 34.1278 46.6768L37.2193 48.6216C38.2491 49.2698 39.5667 49.2698 40.5984 48.6216L43.6898 46.6768C44.5932 46.108 45.1411 45.1256 45.1411 44.0693V39.6235C45.1411 38.5671 44.595 37.5848 43.6898 37.016L40.5984 35.0712C39.5685 34.4229 38.251 34.4229 37.2193 35.0712L34.1278 37.016C33.2244 37.5848 32.6765 38.5671 32.6765 39.6235V39.6235Z" fill="' +
              color +
              '"/>' +
              '<path style="mix-blend-mode:multiply" opacity="0.75" d="M43.6587 36.9436L40.5966 35.0186C39.5448 34.3577 38.1997 34.3577 37.1497 35.0186L34.0876 36.9436C33.1658 37.5232 32.6069 38.5254 32.6069 39.6035V44.0203C32.6069 45.0966 33.1658 46.1006 34.0876 46.6802L37.1497 48.6052C38.2015 49.2661 39.5466 49.2661 40.5966 48.6052L43.6587 46.6802C44.5804 46.1006 45.1393 45.0984 45.1393 44.0203V39.6035C45.1393 38.5272 44.5804 37.5232 43.6587 36.9436ZM44.1645 43.4082C44.1645 44.4844 43.6056 45.4884 42.6838 46.0681L40.5966 47.379C39.5448 48.04 38.1997 48.04 37.1497 47.379L35.0625 46.0681C34.1407 45.4884 33.5818 44.4862 33.5818 43.4082V40.2156C33.5818 39.1394 34.1407 38.1354 35.0625 37.5557L37.1497 36.2447C38.2015 35.5838 39.5466 35.5838 40.5966 36.2447L42.6838 37.5557C43.6056 38.1354 44.1645 39.1376 44.1645 40.2156V43.4082V43.4082Z" fill="url(#paint0_linear_355_73434)"/>' +
              '<path d="M33.7979 29.5343C34.7142 28.6495 35.6414 27.7755 36.5448 26.8781C37.1679 26.2569 37.7341 25.5815 38.3791 24.9838C38.5276 24.8466 39.0187 24.8069 39.1341 24.9242C40.7174 26.5296 42.2659 28.171 43.8143 29.807C43.8656 29.8612 43.8546 29.9713 43.873 30.0562H41.1737C40.3875 29.1804 39.6014 28.3028 38.7768 27.3855C37.8935 28.3444 37.1037 29.2003 36.3158 30.0562C35.6267 30.0562 34.9286 29.9966 34.2487 30.0743C33.677 30.1411 33.8052 29.8215 33.7961 29.5343H33.7979Z" fill="' +
              color +
              '"/>' +
              '</g>' +
              '<defs>' +
              '<linearGradient id="paint0_linear_355_73434" x1="35.5884" y1="37.7309" x2="43.2347" y2="47.5102" gradientUnits="userSpaceOnUse">' +
              '<stop offset="0.02" stop-color="white"/>' +
              '<stop offset="0.46" stop-color="#939393"/>' +
              '<stop offset="0.93" stop-color="#242424"/>' +
              '</linearGradient>' +
              '<clipPath id="clip0_355_73434">' +
              '<rect width="53" height="44" fill="white" transform="translate(13 16)"/>' +
              '</clipPath>' +
              '</defs>' +
              '</svg>'
            )
          }
          ;(styleOption.image = new Icon({
            src: 'data:image/svg+xml;utf8,' + escape(clickDroneImg(feature.getProperties().color)),
            scale: 0.85,
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction'
          })),
            (styleOption.text = new Text({
              fill: new Fill({
                color: 'white'
              }),
              backgroundStroke: new Stroke({
                color: 'black',
                width: 3.5
              }),
              backgroundFill: new Fill({
                color: 'black',
                opacity: 0.5
              }),
              offsetY: 38,
              offsetX: -2.5,
              text: '  ' + feature.get('name') + '  '
            }))
          feature.setStyle(new Style(styleOption))
        } else if (layer.get('id') == 'wayPointLayer') {
          controlModify = false
          let svg = color => {
            return (
              '<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">' +
              '<g clip-path="url(#clip0_350_70514)">' +
              '<g filter="url(#filter0_d_350_70514)">' +
              '<path d="M22 60.001C31.9411 60.001 40 56.4193 40 52.001C40 47.5827 31.9411 44.001 22 44.001C12.0589 44.001 4 47.5827 4 52.001C4 56.4193 12.0589 60.001 22 60.001Z" fill="' +
              color +
              '"/>' +
              '<path d="M22 60.001C31.9411 60.001 40 56.4193 40 52.001C40 47.5827 31.9411 44.001 22 44.001C12.0589 44.001 4 47.5827 4 52.001C4 56.4193 12.0589 60.001 22 60.001Z" stroke="#FFE5C7" stroke-width="2"/>' +
              '</g>' +
              '<path d="M35.2013 30.2941L25.5055 39.6986C25.0816 40.1111 24.5779 40.4384 24.0233 40.6617C23.4687 40.885 22.8741 41 22.2736 41C21.6731 41 21.0785 40.885 20.5239 40.6617C19.9693 40.4384 19.4656 40.1111 19.0416 39.6986L9.6202 30.2941C7.0609 27.8129 6.07279 24.6512 5.36623 21.2089C4.65967 17.7667 3.98185 14.1985 5.36623 10.9557C6.75061 7.71287 9.0954 4.94112 12.104 2.991C15.1126 1.04089 18.6499 0 22.2685 0C25.8871 0 29.4243 1.04089 32.433 2.991C35.4416 4.94112 37.7864 7.71287 39.1707 10.9557C40.5551 14.1985 40.9169 17.7667 40.2104 21.2089C39.5038 24.6512 37.7606 27.8129 35.2013 30.2941Z" fill="white" stroke="#FFE5C7" stroke-width="2"/>' +
              '<path d="M31.3101 29.5798L22.8212 38.7786C22.4666 39.1758 22.0314 39.4938 21.5443 39.7116C21.0571 39.9294 20.529 40.042 19.9948 40.042C19.4606 40.042 18.9325 39.9294 18.4454 39.7116C17.9582 39.4938 17.523 39.1758 17.1685 38.7786L8.68945 29.5798C6.41969 27.0895 4.90069 24.0145 4.30614 20.7063C3.69112 17.3468 4.00649 13.8843 5.2185 10.6892C6.39144 7.6019 8.43713 4.91755 11.1092 2.95946C13.6816 1.06475 16.7983 0.0419922 19.9998 0.0419922C23.2012 0.0419922 26.3179 1.06475 28.8903 2.95946C31.5729 4.93022 33.6226 7.63248 34.791 10.7385C35.9955 13.9183 36.3074 17.3638 35.6934 20.7063C35.0999 24.0148 33.5807 27.0901 31.3101 29.5798Z" fill="' +
              color +
              '" stroke="#FFE5C7" stroke-width="2"/>' +
              '<path d="M19.8701 22.5308C24.0123 22.5308 27.3701 19.1729 27.3701 15.0308C27.3701 10.8886 24.0123 7.53076 19.8701 7.53076C15.728 7.53076 12.3701 10.8886 12.3701 15.0308C12.3701 19.1729 15.728 22.5308 19.8701 22.5308Z" fill="white"/>' +
              '</g>' +
              '<defs>' +
              '<filter id="filter0_d_350_70514" x="-1" y="41.001" width="46" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
              '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
              '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
              '<feOffset dy="2"/>' +
              '<feGaussianBlur stdDeviation="2"/>' +
              '<feComposite in2="hardAlpha" operator="out"/>' +
              '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>' +
              '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_350_70514"/>' +
              '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_350_70514" result="shape"/>' +
              '</filter>' +
              '<clipPath id="clip0_350_70514">' +
              '<rect width="44" height="65" fill="white"/>' +
              '</clipPath>' +
              '</defs>' +
              '</svg>'
            )
          }
          styleOption.image = new Icon({
            src: 'data:image/svg+xml;utf8,' + escape(svg(feature.getProperties().prop.color)),
            scale: 0.5,
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction'
          })
          styleOption.text = new Text({
            fill: new Fill({
              color: 'white'
            }),
            backgroundStroke: new Stroke({
              color: 'black',
              width: 3.5
            }),
            backgroundFill: new Fill({
              color: 'black',
              opacity: 0.5
            }),
            offsetY: -27,
            offsetX: -2.5,
            text: '  ' + feature.getProperties().prop.cuAt + ' : ' + feature.getProperties().prop.alt + '  '
          })
          feature.setStyle(new Style(styleOption))
        } else if (layer.get('id') == 'geofenceLayer') {
          controlModify = true
          styleOption.fill = new Fill({
            color: 'rgba(0, 204, 0, 0.2)'
          })
          styleOption.stroke = new Stroke({
            color: 'rgba(255, 255, 0, 0.5)',
            lineDash: [10, 10],
            width: 2
          })

          styleOption.CircleStyle = new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)'
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)'
            })
          })
          styleOption.text = new Text({
            font: '13px sans-serif',
            fill: new Fill({
              color: 'white'
            }),
            offsetY: 0,
            offsetX: 0,
            text: feature.getProperties().prop.name
          })
          feature.setStyle(new Style(styleOption))
        } else if (layer.get('id') == 'patternLayer') {
          controlModify = true
          styleOption.fill = new Fill({
            color: 'rgba(211, 211, 211, 0.2)'
          })
          styleOption.stroke = new Stroke({
            color: 'rgba(0, 255, 0, 0.7)',
            width: 2
          })
          feature.setStyle(new Style(styleOption))
        } else if (layer.get('id') == 'roiLayer') {
          const clickHomeImg = color => {
            return `<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_355_70978)">
            <g filter="url(#filter0_d_355_70978)">
            <path d="M22 60C31.9411 60 40 56.4183 40 52C40 47.5817 31.9411 44 22 44C12.0589 44 4 47.5817 4 52C4 56.4183 12.0589 60 22 60Z" fill="${color}"/>
            </g>
            <path d="M35.0904 29.5331L25.4301 38.7019C25.0077 39.1041 24.5058 39.4232 23.9532 39.6409C23.4007 39.8586 22.8082 39.9707 22.2099 39.9707C21.6116 39.9707 21.0192 39.8586 20.4666 39.6409C19.9141 39.4232 19.4122 39.1041 18.9898 38.7019L9.81552 29.5331C7.36368 27.1685 5.72838 24.1515 5.1271 20.8833C4.41827 17.5195 3.98285 13.8674 5.37013 10.7054C6.39461 8.33478 7.96115 6.20973 9.95654 4.48382C11.9519 2.75791 14.3264 1.47425 16.9083 0.725597C19.4901 -0.0230589 22.215 -0.218015 24.886 0.154816C27.5569 0.527647 30.1073 1.45895 32.3528 2.88144C34.5982 4.30393 36.4827 6.18208 37.87 8.38017C39.2574 10.5783 40.113 13.0414 40.3749 15.5916C40.6369 18.1419 40.2987 20.7155 39.3848 23.1265C38.4709 25.5375 37.0041 27.7258 35.0904 29.5331Z" fill="white" stroke="#FFE5C7" stroke-width="2"/>
            <path d="M31.3101 29.5788L22.8212 38.7776C22.4666 39.1748 22.0314 39.4928 21.5443 39.7106C21.0571 39.9284 20.529 40.041 19.9948 40.041C19.4606 40.041 18.9325 39.9284 18.4454 39.7106C17.9582 39.4928 17.523 39.1748 17.1685 38.7776L8.68945 29.5788C6.41969 27.0886 4.90069 24.0135 4.30614 20.7054C3.69112 17.3458 4.00649 13.8833 5.2185 10.6882C6.39144 7.60092 8.43713 4.91657 11.1092 2.95848C13.6816 1.06377 16.7983 0.0410156 19.9998 0.0410156C23.2012 0.0410156 26.3179 1.06377 28.8903 2.95848C31.5729 4.92924 33.6226 7.63151 34.791 10.7375C35.9955 13.9173 36.3074 17.3629 35.6934 20.7054C35.0999 24.0139 33.5807 27.0892 31.3101 29.5788Z" fill="${color}" stroke="#FFE5C7" stroke-width="2"/>
            <g filter="url(#filter1_d_355_70978)">
            <path d="M29.4601 15.8097C29.6668 15.6636 29.8238 15.4577 29.9101 15.2197C29.9901 14.9829 29.9901 14.7265 29.9101 14.4897C29.8268 14.2474 29.6693 14.0375 29.4601 13.8897C29.2412 13.7424 28.9839 13.6624 28.7201 13.6597L23.5601 13.4797C23.5374 13.4873 23.5128 13.4873 23.4901 13.4797L21.6701 8.8797C21.575 8.64636 21.411 8.44753 21.2001 8.3097C20.9826 8.16969 20.7287 8.09666 20.4701 8.0997C20.2117 8.09839 19.9583 8.17127 19.7401 8.3097C19.532 8.4481 19.3714 8.64707 19.2801 8.8797L17.5001 13.4097L17.4501 13.4697H17.3801L12.2301 13.6597C11.9655 13.6574 11.7068 13.7378 11.4901 13.8897C11.2652 14.0348 11.0933 14.2488 11.0001 14.4997C10.9153 14.7357 10.9153 14.9937 11.0001 15.2297C11.0834 15.4747 11.2451 15.6856 11.4601 15.8297L15.4601 18.8297C15.472 18.8358 15.4826 18.8443 15.4912 18.8546C15.4998 18.8649 15.5062 18.8769 15.5101 18.8897C15.5152 18.9127 15.5152 18.9367 15.5101 18.9597L14.1201 23.5797C14.0307 23.8114 14.0307 24.068 14.1201 24.2997C14.204 24.5363 14.3617 24.7396 14.5701 24.8797C14.788 25.0213 15.0404 25.1008 15.3001 25.1097C15.5587 25.1127 15.8126 25.0397 16.0301 24.8997L20.3101 22.2097H20.3801H20.4501L24.7301 24.8997C24.9464 25.0433 25.2004 25.1199 25.4601 25.1199C25.7198 25.1199 25.9737 25.0433 26.1901 24.8997C26.3982 24.7613 26.5587 24.5623 26.6501 24.3297C26.7251 24.0956 26.7251 23.8438 26.6501 23.6097L25.3501 18.9997C25.3448 18.9888 25.342 18.9768 25.342 18.9647C25.342 18.9526 25.3448 18.9406 25.3501 18.9297C25.3439 18.9102 25.3439 18.8892 25.3501 18.8697L29.4601 15.8097Z" fill="white"/>
            </g>
            </g>
            <defs>
            <filter id="filter0_d_355_70978" x="0" y="42" width="44" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_70978"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_70978" result="shape"/>
            </filter>
            <filter id="filter1_d_355_70978" x="10.9365" y="8.09961" width="20.0334" height="18.0205" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dx="1" dy="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_70978"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_70978" result="shape"/>
            </filter>
            <clipPath id="clip0_355_70978">
            <rect width="44" height="65" fill="white"/>
            </clipPath>
            </defs>
            </svg>
            `
          }
          styleOption.image = new Icon({
            src: 'data:image/svg+xml;utf8,' + escape(clickHomeImg(feature.getProperties().prop.color)),
            scale: 0.5,
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction'
          })
          feature.setStyle(new Style(styleOption))
        }
      },
      condition: click,
      toggleCondition: function(a) {
        let isMap = false // 일반 map 클릭했는지 확인하는 로직
        let layer
        state.map.forEachFeatureAtPixel(a.pixel, async feature => {
          if (feature) {
            layer = feature.get('layer')
            isMap = true
          }
        })
        const modeName = proxy.$store.getters['layout/getModeTabValue']
        if ((modeName == 'move' || modeName == 'loiter' || modeName == 'INTEREST' || layer == 'droneLayer') && isMap) {
          return singleClick
        } else {
          return false
        }
      }
    })
    // var collection = new Collection()

    // collection.extend(layers.wayPointLayer.getSource())
    // collection.extend(layers.roiLayer.getSource())
    // collection.extend(layers.homePositionLayer.getSource())
    // var modifyCollection = [collection]
    // modifyCollection.push(selectInteraction.getFeatures())
    const modifyInteraction = new Modify({
      features: selectInteraction.getFeatures(),
      hitDetection: true,
      pixelTolerance: 20
    })

    const translateInteraction = new Translate({
      features: selectInteraction.getFeatures()
    })

    state.map.addInteraction(selectInteraction)
    state.map.addInteraction(translateInteraction)
    state.map.addInteraction(modifyInteraction)

    selectInteraction.on('select', function(evt) {
      //* geofenceLayer, patternLayer만 modify와 translate 가능하게 구현
      let selectedLayer
      if (evt.selected.length > 0) {
        selectedLayer = evt.selected[0].get('layer')
        if (selectedLayer == 'geofenceLayer' || selectedLayer == 'patternLayer') {
          modifyInteraction.setActive(true)
          translateInteraction.setActive(true)
        } else {
          modifyInteraction.setActive(false)
          translateInteraction.setActive(false)
        }
      } else {
        modifyInteraction.setActive(false)
        translateInteraction.setActive(false)
      }
      if (state.editPattern) {
        selectInteraction.getFeatures().remove(evt.selected[0])
        modifyInteraction.setActive(false)
        translateInteraction.setActive(false)
      }

      const checkboxList = $('div.drone-name-box > label > input[type=checkbox]')
      let selectedDroneFeatureList = []
      //* hud와 map에서 드론 interaction 관리하는 기능
      if (!isUndefined(evt.target.values_)) {
        selectInteraction.getFeatures().forEach((feature) => {
          if (feature.get('layer') == 'droneLayer') {
            selectedDroneFeatureList.push(feature)
          }
        })
        //? selectedDroneFeatureList가 비어있으면 리셋
        if (selectedDroneFeatureList.length == 0) {
          proxy.$store.dispatch('drone/setMissionDrone', [])
          state.droneArr = []
          state.index = ''
          proxy.$store.dispatch('layout/updateModeTab', '')
          layers.assistLayer.getSource().clear()
          for (let checkbox of checkboxList) {
            checkbox.checked = false
          }
          return
        } else {
          state.modeTab = true
        }

        if (selectedLayer == 'droneLayer') {
          if (evt.selected.length > 0) {
            let missionDrone = state.ctrlDroneList.find(drone => drone.name == evt.selected[0].get('name'))
            state.droneArr.push(missionDrone)
          } else {
            state.droneArr = state.droneArr.filter(drone => drone.name !== evt.deselected[0].get('name'))
          }
          proxy.$store.dispatch('drone/setMissionDrone', state.droneArr)
        }
      } else {
        if (!isUndefined(evt.target[0])) {
          const drone = evt.target[0]
          layers.droneLayer
            .getSource()
            .getFeatures()
            .forEach(feature => {
              if (feature.get('name') == drone.name) {
                selectedDroneFeatureList.push(feature)
              }
            })
          proxy.$store.dispatch('drone/setMissionDrone', evt.target)
        } else {
          proxy.$store.dispatch('drone/setMissionDrone', [])
          layers.assistLayer.getSource().clear()
          for (let checkbox of checkboxList) {
            checkbox.checked = false
            state.modeTab = false
          }
          return
        }
      }

      //! 드론 여러개 선택 시 checked 풀리는 현상 아래 코드에서 발생
      // for (const checkbox of checkboxList) {
      //   const isSelected = selectedDroneFeatureList.find(droneFeature => droneFeature.get('name') === checkbox.value)
      //   checkbox.checked = isSelected !== undefined ? true : false
      // }
      // for (let checkbox of checkboxList) {
      //   console.log('test4', checkbox.checked)
      // }

      let evtTarget
      const modeTab = proxy.$store.getters['layout/getModeTabValue']
      if (evt.selected.length) {
        evtTarget = evt.selected[0]
      } else {
        evtTarget = evt.deselected[0]
      }
      //* 이동, 선회에서 waypoint 선택 중복 안되게하는 로직
      selectInteraction.getFeatures().forEach(item => {
        if (item.get('layer') == 'wayPointLayer') {
          if (item.get('prop').drone == evtTarget.get('prop').drone) {
            selectInteraction.getFeatures().remove(item)
          }
        }
      })
      //* 이동, 선회 임무에서 waypoint 선택시  confirm modal waypoint 정보 전달
      if (me.getLayer(state.map, evtTarget).get('id') == 'wayPointLayer') {
        let lineFeature
        let coords

        //* 선회 waypoint 선택 후 다른 포인트 클릭시 assist line 재배치
        layers.assistLayer
          .getSource()
          .getFeatures()
          .forEach(function(feature) {
            if (feature.get('name') == evtTarget.get('prop').drone && feature.get('type') == 'circle') {
              layers.assistLayer.getSource().removeFeature(feature)
            }
          })
        layers.assistLayer
          .getSource()
          .getFeatures()
          .forEach(function(feature) {
            if (feature.get('name') == evtTarget.get('prop').drone && feature.get('type') == 'line') {
              layers.assistLayer.getSource().removeFeature(feature)
            }
          })

        if (modeTab == 'loiter') {
          //* 모드탭이 선회일 경우
          let isLoiter = false
          selectedDroneFeatureList.forEach(function(droneFeature) {
            const loiterWp = evtTarget.get('prop')
            if (loiterWp.pointType !== 'loiter') {
              isLoiter = true
              selectInteraction.getFeatures().remove(evtTarget)
              return
            }

            if (droneFeature.get('name') == evtTarget.get('prop').drone) {
              //* 이동 명령어 관련 기능 변경
              const wpProp = evt.selected[0].get('prop')
              const wpString = JSON.stringify(evt.selected[0].get('prop'))
              $(`#${wpProp.drone}`).val(wpString)
              state.selectedWps.push(wpProp)
              const loiterFeature = new Feature({
                name: evtTarget.get('prop').drone,
                type: 'circle',
                geometry: new Circle(transform([loiterWp.lng, loiterWp.lat], 'EPSG:4326', 'EPSG:3857'), loiterWp.radius).transform(
                  'EPSG:3857',
                  'EPSG:4326'
                )
              })

              const circleStyle = new Style({
                stroke: new Stroke({
                  color: '#FF0000'
                })
              })
              loiterFeature.setStyle(circleStyle)
              layers.assistLayer.getSource().addFeature(loiterFeature)

              const closestPoint = loiterFeature.getGeometry().getClosestPoint(droneFeature.getGeometry().getCoordinates())
              coords = [droneFeature.getGeometry().getCoordinates(), closestPoint]
              const lineString = new LineString(coords)
              lineFeature = new Feature({
                geometry: lineString,
                type: 'line',
                name: evtTarget.get('prop').drone
              })
            }
          })
          if (isLoiter) {
            fnModalAlert('오류', 'loiter를 선택해주세요.', '')
            return
          }
        } else if (modeTab == 'move') {
          //* 각 드론에 맞는 waypoint 지정
          selectedDroneFeatureList.forEach(function(droneFeature) {
            if (droneFeature.get('name') == evtTarget.get('prop').drone) {
              //* 이동 명령어 관련 기능 변경
              const wpProp = evt.selected[0].get('prop')
              const wpString = JSON.stringify(evt.selected[0].get('prop'))
              $(`#${wpProp.drone}`).val(wpString)
              wpProp.distance = me.getDistanceFromLatLonInKm([droneFeature.getGeometry().getCoordinates(), evtTarget.getGeometry().getCoordinates()])
              console.log(wpProp.distance)
              state.selectedWps.push(wpProp)

              //* drone to waypoint lineString
              coords = [droneFeature.getGeometry().getCoordinates(), evtTarget.getGeometry().getCoordinates()]
              const lineString = new LineString(coords)
              lineFeature = new Feature({
                geometry: lineString,
                type: 'line',
                name: evtTarget.get('prop').drone
              })
            } else {
              selectInteraction.getFeatures().remove(evtTarget)
              // fnModalAlert('오류', '해당 드론에 지정된 waypoint를 선택해주세요.', '')
            }
          })
        } else {
          selectInteraction.getFeatures().remove(evtTarget)
        }
        const styleFunction = function(feature) {
          const geometry = feature.getGeometry()
          const styles = [
            // linestring
            new Style({
              stroke: new Stroke({
                color: '#ffcc33',
                width: 2,
                lineDash: [4, 8]
              })
            })
          ]
          geometry.forEachSegment(function(start, end) {
            const dx = coords[1][0] - coords[0][0]
            const dy = coords[1][1] - coords[0][1]
            const rotation = Math.atan2(dy, dx)
            // arrows
            styles.push(
              new Style({
                geometry: new Point(end),
                image: new Icon({
                  src: arrowImg,
                  anchor: [0.75, 0.5],
                  rotateWithView: true,
                  rotation: -rotation
                })
              })
            )
          })
          return styles
        }
        lineFeature.setStyle(styleFunction)

        layers.assistLayer.getSource().addFeature(lineFeature)
      } else if (me.getLayer(state.map, evtTarget).get('id') == 'roiLayer') {
        if (modeTab == 'INTEREST') {
          let roiLineFeature
          let roiCoords
          //* 모드 탭이 관심일 경우
          let isRoi = false
          selectedDroneFeatureList.forEach(function(droneFeature) {
            const roiWp = evtTarget.get('prop')
            if (roiWp.pointType !== 'roi') {
              isRoi = true
              selectInteraction.getFeatures().remove(evtTarget)
              return
            }

            if (droneFeature.get('name') == evtTarget.get('prop').drone) {
              const wpProp = evt.selected[0].get('prop')

              //! waypoint 넘기는 로직 둘중 하나 선택 필요.
              const wpString = JSON.stringify(evt.selected[0].get('prop'))
              wpProp.distance = me.getDistanceFromLatLonInKm([droneFeature.getGeometry().getCoordinates(), evtTarget.getGeometry().getCoordinates()])
              $(`#${wpProp.drone}`).val(wpString)
              state.selectedWps.push(wpProp)
            }
            roiCoords = [droneFeature.getGeometry().getCoordinates(), evtTarget.getGeometry().getCoordinates()]
            const lineString = new LineString(roiCoords)
            roiLineFeature = new Feature({
              geometry: lineString,
              type: 'line',
              name: evtTarget.get('prop').drone
            })
          })

          if (isRoi) {
            fnModalAlert('오류', 'ROI point를 선택해주세요.', '')
            return
          }
          const styleFunction = function(feature) {
            const geometry = feature.getGeometry()
            const styles = [
              // linestring
              new Style({
                stroke: new Stroke({
                  color: '#ffcc33',
                  width: 2,
                  lineDash: [4, 8]
                })
              })
            ]
            geometry.forEachSegment(function(start, end) {
              const dx = roiCoords[1][0] - roiCoords[0][0]
              const dy = roiCoords[1][1] - roiCoords[0][1]
              const rotation = Math.atan2(dy, dx)
              // arrows
              styles.push(
                new Style({
                  geometry: new Point(end),
                  image: new Icon({
                    src: arrowImg,
                    anchor: [0.75, 0.5],
                    rotateWithView: true,
                    rotation: -rotation
                  })
                })
              )
            })
            return styles
          }
          roiLineFeature.setStyle(styleFunction)

          layers.assistLayer.getSource().addFeature(roiLineFeature)
        }
      } else {
        layers.assistLayer.getSource().clear()
        return
      }
    })
    modifyInteraction.on('modifyend', function(e) {
      if (e.features.getArray()[0].get('layer') == 'patternLayer') {
        const patternId = e.features.getArray()[0].get('patternId')
        for (let patternData of state.patternList) {
          if (patternData._id == patternId) {
            me.getCrossingPoint(e, state, layers, patternData, false)
            proxy.$PatternUtils.updatePattern(patternData)
          }
        }
      } else if (e.features.getArray()[0].get('layer') == 'geofenceLayer') {
        setGeofence(e)
      }
    })

    translateInteraction.on('translateend', function(e) {
      if (e.features.getArray()[0].get('layer') == 'patternLayer') {
        const patternId = e.features.getArray()[0].get('patternId')
        for (let patternData of state.patternList) {
          if (patternData._id == patternId) {
            me.getCrossingPoint(e, state, layers, patternData, false)
            proxy.$PatternUtils.updatePattern(patternData)
          }
        }
      } else if (e.features.getArray()[0].get('layer') == 'geofenceLayer') {
        setGeofence(e)
      }
    })

    async function setGeofence(e) {
      const preGeofenceInfo = e.features.getArray()[0].get('prop')
      if (preGeofenceInfo.type == 'circle') {
        const circlePosition = e.features
          .getArray()[0]
          .getGeometry()
          .getCenter()
        const makeUnitMeter = state.map
          .getView()
          .getProjection()
          .getMetersPerUnit()
        const radius = (
          e.features
            .getArray()[0]
            .getGeometry()
            .getRadius() * makeUnitMeter
        ).toFixed(0)
        const circleInfo = {
          type: preGeofenceInfo.type,
          state: true,
          position: { lng: circlePosition[0].toFixed(6), lat: circlePosition[1].toFixed(6) },
          radius: parseInt(radius),
          violationAct: preGeofenceInfo.violationAct,
          violationLocation: preGeofenceInfo.violationLocation,
          _id: preGeofenceInfo.id,
          name: preGeofenceInfo.name,
          droneName: preGeofenceInfo.droneName,
        }
        e.features.getArray()[0].set('prop', circleInfo)
        await proxy.$GeofenceUtils.updateGeofence(circleInfo)
      } else if (preGeofenceInfo.type == 'polygon') {
        const polygonInfo = {
          type: preGeofenceInfo.type,
          state: true,
          position: e.features
            .getArray()[0]
            .getGeometry()
            .getCoordinates(),
          radius: 0,
          violationAct: preGeofenceInfo.violationAct,
          violationLocation: preGeofenceInfo.violationLocation,
          _id: preGeofenceInfo.id,
          name: preGeofenceInfo.name,
          droneName: preGeofenceInfo.droneName,
        }
        e.features.getArray()[0].set('prop', polygonInfo)
        await proxy.$GeofenceUtils.updateGeofence(polygonInfo)
      }
    }
  }

  //* 홈포지션, roiter 제외 모든 feature 수정 이벤트 관리
  setModifyInteraction(proxy, state, layers) {
    const me = this
    var modify = new Modify({
      source: layers.wayPointLayer.getSource(),
      style: function(feature) {
        let param = null
        for (let i = 0; i < state.waypointList.length; i++) {
          if (state.waypointList[i].id === feature.getProperties().features[0].get('prop').id) param = state.waypointList[i]
        }
        var styleOption = {}
        let clickWpImg = color => {
          return (
            '<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">' +
            '<g clip-path="url(#clip0_350_70514)">' +
            '<g filter="url(#filter0_d_350_70514)">' +
            '<path d="M22 60.001C31.9411 60.001 40 56.4193 40 52.001C40 47.5827 31.9411 44.001 22 44.001C12.0589 44.001 4 47.5827 4 52.001C4 56.4193 12.0589 60.001 22 60.001Z" fill="' +
            color +
            '"/>' +
            '<path d="M22 60.001C31.9411 60.001 40 56.4193 40 52.001C40 47.5827 31.9411 44.001 22 44.001C12.0589 44.001 4 47.5827 4 52.001C4 56.4193 12.0589 60.001 22 60.001Z" stroke="#FFE5C7" stroke-width="2"/>' +
            '</g>' +
            '<path d="M35.2013 30.2941L25.5055 39.6986C25.0816 40.1111 24.5779 40.4384 24.0233 40.6617C23.4687 40.885 22.8741 41 22.2736 41C21.6731 41 21.0785 40.885 20.5239 40.6617C19.9693 40.4384 19.4656 40.1111 19.0416 39.6986L9.6202 30.2941C7.0609 27.8129 6.07279 24.6512 5.36623 21.2089C4.65967 17.7667 3.98185 14.1985 5.36623 10.9557C6.75061 7.71287 9.0954 4.94112 12.104 2.991C15.1126 1.04089 18.6499 0 22.2685 0C25.8871 0 29.4243 1.04089 32.433 2.991C35.4416 4.94112 37.7864 7.71287 39.1707 10.9557C40.5551 14.1985 40.9169 17.7667 40.2104 21.2089C39.5038 24.6512 37.7606 27.8129 35.2013 30.2941Z" fill="white" stroke="#FFE5C7" stroke-width="2"/>' +
            '<path d="M31.3101 29.5798L22.8212 38.7786C22.4666 39.1758 22.0314 39.4938 21.5443 39.7116C21.0571 39.9294 20.529 40.042 19.9948 40.042C19.4606 40.042 18.9325 39.9294 18.4454 39.7116C17.9582 39.4938 17.523 39.1758 17.1685 38.7786L8.68945 29.5798C6.41969 27.0895 4.90069 24.0145 4.30614 20.7063C3.69112 17.3468 4.00649 13.8843 5.2185 10.6892C6.39144 7.6019 8.43713 4.91755 11.1092 2.95946C13.6816 1.06475 16.7983 0.0419922 19.9998 0.0419922C23.2012 0.0419922 26.3179 1.06475 28.8903 2.95946C31.5729 4.93022 33.6226 7.63248 34.791 10.7385C35.9955 13.9183 36.3074 17.3638 35.6934 20.7063C35.0999 24.0148 33.5807 27.0901 31.3101 29.5798Z" fill="' +
            color +
            '" stroke="#FFE5C7" stroke-width="2"/>' +
            '<path d="M19.8701 22.5308C24.0123 22.5308 27.3701 19.1729 27.3701 15.0308C27.3701 10.8886 24.0123 7.53076 19.8701 7.53076C15.728 7.53076 12.3701 10.8886 12.3701 15.0308C12.3701 19.1729 15.728 22.5308 19.8701 22.5308Z" fill="white"/>' +
            '</g>' +
            '<defs>' +
            '<filter id="filter0_d_350_70514" x="-1" y="41.001" width="46" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">' +
            '<feFlood flood-opacity="0" result="BackgroundImageFix"/>' +
            '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>' +
            '<feOffset dy="2"/>' +
            '<feGaussianBlur stdDeviation="2"/>' +
            '<feComposite in2="hardAlpha" operator="out"/>' +
            '<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>' +
            '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_350_70514"/>' +
            '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_350_70514" result="shape"/>' +
            '</filter>' +
            '<clipPath id="clip0_350_70514">' +
            '<rect width="44" height="65" fill="white"/>' +
            '</clipPath>' +
            '</defs>' +
            '</svg>'
          )
        }

        var color = param.color
        if (param.cuAt == 0) {
          color = '#7b7b7b'
        }
        styleOption.image = new Icon({
          src: 'data:image/svg+xml;utf8,' + escape(clickWpImg(color)),
          scale: 0.5,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction'
        })

        feature.setStyle(new Style(styleOption))
      }
    })

    modify.on('modifyend', function(e) {
      let feature = e.features.getArray()[0]
      let selectedFeature = feature.getGeometry().getCoordinates()
      feature.get('prop').lng = selectedFeature[0]
      feature.get('prop').lat = selectedFeature[1]
      feature.get('prop').latLng.lng = selectedFeature[0]
      feature.get('prop').latLng.lat = selectedFeature[1]
      let wpId = feature.get('prop').id
      let idx = state.waypointList.findIndex(wp => wp.id === wpId)
      state.waypointList[idx].lng = selectedFeature[0]
      state.waypointList[idx].lat = selectedFeature[1]
      state.waypointList[idx].latLng.lng = selectedFeature[0]
      state.waypointList[idx].latLng.lat = selectedFeature[1]
      proxy.$MavlinkUtils.saveGoto(state.waypointList)
      me.waypointPath(state, layers)
    })
    state.map.addInteraction(modify)
  }

  //* 패턴 관련 Functions
  makeDefaultPattern(state, layers, patternData, isGet) {
    const me = this
    let patternSquarePoints
    const featureKey = Date.now()
    patternData.featureKey = featureKey

    const delPatternLineFeatures = layers.patternLineLayer.getSource().getFeatures()
    const delPatternFeatures = layers.patternLayer.getSource().getFeatures()

    //* 패턴 수정 시, 이전 패턴 지도 상에서 지우는 로직
    delPatternLineFeatures.forEach(lineFeature => {
      if (patternData._id == lineFeature.get('patternId')) layers.patternLineLayer.getSource().removeFeature(lineFeature)
    })
    delPatternFeatures.forEach(patternFeature => {
      if (patternData._id == patternFeature.get('patternId')) layers.patternLayer.getSource().removeFeature(patternFeature)
    })

    const center = me.destinationVincenty(patternData.coordinates, 90, 200)

    const point0 = me.destinationVincenty(center, 180, 1000)
    const point1 = me.destinationVincenty(point0, 270, 1000)
    const point2 = me.destinationVincenty(point1, 0, 1000)
    const point3 = me.destinationVincenty(point2, 90, 1000)
    const point4 = me.destinationVincenty(point3, 180, 1000)

    if (isGet) patternSquarePoints = [[patternData.lonLatArr]]
    else patternSquarePoints = [[[point1, point2, point3, point4, point1]]]

    makePatternFeature(me, state, layers, patternData, patternSquarePoints, isGet)

    function makePatternFeature(me, state, layers, patternData, patternSquarePoints, isGet) {
      // layers.patternLayer.getSource().clear()
      const feature = new Feature({
        geometry: new MultiPolygon(patternSquarePoints),
        patternId: patternData._id,
        layer: 'patternLayer',
        prop: {
          droneName: patternData.droneList[0].name,
          patternId: patternData._id,
          key: patternData.featureKey,
          gap: patternData.gap,
          angle: patternData.angle,
          alt: patternData.alt,
          version: patternData.version
        }
      })
      let styleOption = {}
      styleOption.fill = new Fill({
        color: 'rgba(211, 211, 211, 0.1)'
      })
      styleOption.text = new Text({
        fill: new Fill({
          color: 'white'
        }),
        backgroundStroke: new Stroke({
          color: 'black',
          width: 3.5
        }),
        backgroundFill: new Fill({
          color: 'black',
          opacity: 0.3
        }),
        offsetY: -27,
        offsetX: -2.5,
        text: patternData.patternName,
        font: '15px bold'
      })
      feature.setStyle(new Style(styleOption))
      layers.patternLayer.getSource().addFeature(feature)

      const coords = [
        me.destinationVincenty(me.destinationVincenty(point2, 270, 500), 0, 500),
        me.destinationVincenty(me.destinationVincenty(point1, 270, 500), 180, 500)
      ]
      let direction, distance
      for (let i = 0; i < (10000 / patternData.gap) * 2; i++) {
        if (i % 2 == 0) {
          if (i % 4 == 0) {
            direction = 0
          } else {
            direction = 180
          }
          distance = 10000
        } else if (i % 2 == 1) {
          direction = 90
          distance = patternData.gap
        }
        const tempPoint = me.destinationVincenty(coords[coords.length - 1], direction, distance)
        coords.push(tempPoint)
      }

      const lineFeature = new Feature({
        geometry: new LineString(coords),
        droneName: patternData.droneList[0].name,
        patternId: patternData._id,
        key: patternData.featureKey
      })
      layers.patternLineLayer.getSource().addFeature(lineFeature)
      lineFeature.getGeometry().rotate((patternData.angle / 180) * Math.PI, olExtent.getCenter(feature.getGeometry().getExtent()))

      var obj = {
        features: {
          getArray: function getArray() {
            return [feature]
          }
        },
        angle: patternData.angle
      }
      patternData.coords = coords
      me.getCrossingPoint(obj, state, layers, patternData, isGet)
    }
  }

  getCrossingPoint(mod, state, layers, patternData, isGet) {
    const me = this
    const patternInfo = mod.features.getArray()[0].get('prop')
    const patternGeometry = mod.features.getArray()[0].getGeometry()
    const patternLineFeatures = layers.patternLineLayer.getSource().getFeatures()
    let lineFeatures = []
    let modFeature

    patternData.area = polygonArea(patternGeometry)
    patternData.perimeter = polygonPerimeter(patternGeometry)

    for (var i = 0; i < patternLineFeatures.length; i++) {
      if (patternInfo.patternId === patternLineFeatures[i].get('patternId')) {
        lineFeatures.push(patternLineFeatures[i])
        modFeature = mod.features.getArray()[0]
      }
    }
    if (!lineFeatures) return

    for (let lineFeature of lineFeatures) {
      layers.patternLineLayer.getSource().removeFeature(lineFeature)
    }
    var lonArr = []
    var latArr = []
    const lonLatArr = mod.features
      .getArray()[0]
      .getGeometry()
      .getCoordinates()[0][0]
    if (isGet) {
      for (let j = 0; j < patternData.lonLatArr.length; j++) {
        lonArr.push(patternData.lonLatArr[j][0])
        latArr.push(patternData.lonLatArr[j][1])
      }
    } else {
      for (let j = 0; j < lonLatArr.length; j++) {
        lonArr.push(lonLatArr[j][0])
        latArr.push(lonLatArr[j][1])
      }
    }
    //! 여기서 store에 저장
    patternData.lonLatArr = lonLatArr

    let patternLineData = {}
    var height = me.getDistanceFromLatLonInKm([
      [Math.min(...lonArr), Math.min(...latArr)],
      [Math.min(...lonArr), Math.max(...latArr)]
    ])
    var width = me.getDistanceFromLatLonInKm([
      [Math.min(...lonArr), Math.min(...latArr)],
      [Math.max(...lonArr), Math.min(...latArr)]
    ])
    var initPosArr = [
      me.destinationVincenty(me.destinationVincenty([Math.min(...lonArr), Math.min(...latArr)], 270, 500), 180, 500),
      me.destinationVincenty(me.destinationVincenty([Math.min(...lonArr), Math.max(...latArr)], 270, 500), 0, 500)
    ]
    patternLineData.height = height + 1000
    patternLineData.width = width + 1000
    patternLineData.initPosArr = initPosArr
    if (patternData.version == 'new') me.makePatternLine(layers, patternData, patternLineData, modFeature, state)
    else if (patternData.version == 'old') me.preMakePatternLine(layers, patternData, patternLineData, modFeature, state)

    function polygonArea(polygon) {
      const area = getArea(polygon, { projection: 'EPSG:4326' })
      let output
      if (area > 100000) {
        output = Math.round((area / 1000000) * 100) / 100 + '㎢'
      } else {
        output = Math.round(area * 100) / 10000 + '㎡'
      }
      return output
    }
    function polygonPerimeter(polygon) {
      const perimeter = getLength(polygon, { projection: 'EPSG:4326' })
      let output
      if (perimeter > 1000) {
        output = Math.round((perimeter / 1000) * 100) / 100 + 'km'
      } else {
        output = Math.round(perimeter * 100) / 100 + 'm'
      }
      return output
    }
  }

  makePatternLine(layers, patternData, patternLineData, modFeature, state) {
    // patternLineLayer.getSource().clear()
    let me = this
    let coords = [patternLineData.initPosArr[1], patternLineData.initPosArr[0]]
    let direction = null
    let distance = null
    let path = []
    let pathTest = []
    const droneCount = patternData.droneList.length
    for (let i = 0; i < (patternLineData.width / patternData.gap) * 2; i++) {
      if (i % 2 == 0) {
        if (i % 4 == 0) {
          direction = 0
        } else {
          direction = 180
        }
        distance = patternLineData.height // 긴 라인 일때 height
      } else if (i % 2 == 1) {
        direction = 90
        distance = patternData.gap // 짧은 라인 일때 gap
      }
      const tempPoint = me.destinationVincenty(coords[coords.length - 1], direction, distance)
      coords.push(tempPoint)

      const tempLineFeature = new Feature({
        geometry: new LineString([coords[coords.length - 2], tempPoint]),
        layer: 'patternLineLayer',
        patternId: patternData._id
      })
      tempLineFeature.getGeometry().rotate((-patternData.angle / 180) * Math.PI, olExtent.getCenter(modFeature.getGeometry().getExtent()))
      const line1 = turf.multiPolygon([modFeature.getGeometry().getCoordinates()[0]])
      const line2 = turf.multiLineString([tempLineFeature.getGeometry().getCoordinates()])
      const intersects = turf.lineIntersect(line1, line2)
      intersects.features.sort(function(a, b) {
        if (a.geometry.coordinates[0] > b.geometry.coordinates[0]) return 1
        if (a.geometry.coordinates[0] === b.geometry.coordinates[0]) return 0
        if (a.geometry.coordinates[0] < b.geometry.coordinates[0]) return -1
      })
      if (intersects.features.length) {
        if (intersects.features.length > 2) {
          path.push(intersects.features[0].geometry.coordinates)
          path.push(intersects.features[3].geometry.coordinates)
        } else {
          for (let j = 0; j < intersects.features.length; j++) {
            path.push(intersects.features[j].geometry.coordinates)
          }
        }
      }
    }

    // for (var m = 0; m < path.length; m++) {
    //   for (var n = m; n < path.length; n++) {
    //     if (path[m][0] >= path[n][0]) {
    //       let tmp = path[n]
    //       path[n] = path[m]
    //       path[m] = tmp
    //     }
    //   }
    // }

    // 패턴 라인 방향 조정
    for (var b = 0; b < path.length; b++) {
      if (b % 4 == 2) {
        if (b == path.length - 2) {
          break
        }
        if (me.getDistanceFromLatLonInKm([path[b + 2], path[b]]) < me.getDistanceFromLatLonInKm([path[b + 1], path[b]])) {
          let tmp = path[b]
          path[b] = path[b + 1]
          path[b + 1] = tmp
        }
      }
    }
    // 패턴 라인 방향 조정
    for (var c = 0; c < path.length; c++) {
      if (c % 2 == 0 && c > 2) {
        if (c + 4 > path.length) {
          break
        }
        if (path[c - 2][1] < path[c - 1][1] && path[c][1] > path[c + 1][1] && path[c + 2][1] > path[c + 3][1]) {
          let tmp = path[c + 2]
          path[c + 2] = path[c + 3]
          path[c + 3] = tmp
        } else if (path[c - 2][1] > path[c - 1][1] && path[c][1] < path[c + 1][1] && path[c + 2][1] < path[c + 3][1]) {
          let tmp = path[c + 2]
          path[c + 2] = path[c + 3]
          path[c + 3] = tmp
        }
      }
    }

    // 전체 path 라인으로 생성
    const lineTest = turf.lineString(path)

    // path 라인을 거리(속도 * 시간)으로 나눈 coordinate Array
    const offsetLine = turf.lineChunk(lineTest, patternData.speed * patternData.shootPeriod, { units: 'meters' })

    for (let i = 0; i < offsetLine.features.length; i++) {
      for (let item of offsetLine.features[i].geometry.coordinates) {
        pathTest.push(item)
      }
    }

    // offsetLine의 중복 값 제거
    let uniqueArr = Array.from(new Set(pathTest.map(JSON.stringify)), JSON.parse)

    var length = turf.length(turf.multiLineString([uniqueArr]), { units: 'kilometers' }) // 전체 unique path 길이
    patternData.path = path
    patternData.length = length
    let pathIndex = 0
    let uniquePathIndex = 0

    for (var h = 0; h < droneCount; h++) {
      if (uniquePathIndex > 0 && pathIndex > 0) {
        path = path.slice(pathIndex)
        uniqueArr = uniqueArr.slice(uniquePathIndex)
      }

      var tempLen = length / droneCount // 한개의 drone에 할당된 길이
      var tempPath = [uniqueArr[0]]
      var tempuniquePath = [uniqueArr[0]]

      for (var m = 1; m < path.length; m++) {
        tempPath.push(path[m])
        const tempPathLength = turf.length(turf.multiLineString([tempPath]), { units: 'kilometers' })
        if (tempLen < tempPathLength) {
          tempPath.pop()
          for (var t = 1; t < uniqueArr.length; t++) {
            tempuniquePath.push(uniqueArr[t])
            const tempuniquePathLength = turf.length(turf.multiLineString([tempuniquePath]), { units: 'kilometers' })
            if (tempLen < tempuniquePathLength) {
              uniquePathIndex = t
              tempPath.push(tempuniquePath[tempuniquePath.length - 1])
              break
            }
          }
          pathIndex = m - 1
          break
        }
      }

      patternData.droneList[h].path = tempPath

      // var length = turf.length(turf.multiLineString([path]), { units: 'kilometers' }) // 전체 path 길이
      // patternData.path = path
      // patternData.length = length
      // var tempIndex = 0
      // for (var h = 0; h < droneCount; h++) {
      //   if (tempIndex > 0) {
      //     path = path.slice(tempIndex)
      //   }
      //   var tempLen = length / droneCount
      //   var tempPath = [path[0]]
      //   for (var m = 1; m < path.length; m++) {
      //     tempPath.push(path[m])
      //     const lengthtest = turf.length(turf.multiLineString([tempPath]), { units: 'kilometers' })
      //     if (tempLen < lengthtest) {
      //       tempIndex = m
      //       break
      //     }
      //   }
      //   patternData.droneList[h].path = tempPath

      var startStyle = {
        fill: new Fill({
          color: 'rgba(211, 211, 211, 0.1)'
        }),
        text: new Text({
          fill: new Fill({
            color: 'white'
          }),
          backgroundStroke: new Stroke({
            color: 'black',
            width: 3.5
          }),
          backgroundFill: new Fill({
            color: 'black',
            opacity: 0.3
          }),
          offsetY: -1,
          offsetX: -1,
          text: 'START',
          font: '13px bold'
        })
      }
      const startCircle = new Feature({
        geometry: new Point(tempPath[0]),
        layer: 'patternLineLayer',
        patternId: patternData._id
      })
      startCircle.setStyle(new Style(startStyle))

      var styleOption = {
        stroke: new Stroke({
          color: patternData.droneList[h].color,
          width: 3
        })
      }

      // for (let i = 0; i < tempPath.length - 1; i++) {
      //   let setDistance
      //   const distance = getDistance(tempPath[i], tempPath[i + 1])
      //   if (distance > 1000) {
      //     setDistance = (distance / 1000).toFixed(2) + 'km'
      //   } else {
      //     setDistance = distance.toFixed(0) + 'm'
      //   }
      //   const lineLengthStyle = {
      //     text: new Text({
      //       fill: new Fill({
      //         color: 'white'
      //       }),
      //       backgroundStroke: new Stroke({
      //         color: 'black',
      //         width: 3.5
      //       }),
      //       backgroundFill: new Fill({
      //         color: 'black',
      //         opacity: 0.5
      //       }),
      //       font: '15px',
      //       offsetY: -1,
      //       offsetX: -2.5,
      //       text: setDistance
      //     })
      //   }
      //   const lineLength = new Feature({
      //     geometry: new LineString([tempPath[i], tempPath[i + 1]]),
      //     layer: 'patternLineLayer',
      //     patternId: patternData._id,
      //     type: 'lineLength'
      //   })
      //   lineLength.setStyle(new Style(lineLengthStyle))
      //   layers.patternLineLayer.getSource().addFeature(lineLength)
      // }

      var lineFeature = new Feature({
        geometry: new LineString(tempPath),
        layer: 'patternLineLayer',
        patternId: patternData._id,
        droneName: patternData.droneList[h].name,
        key: patternData.featureKey
      })
      lineFeature.setStyle(new Style(styleOption))
      layers.patternLineLayer.getSource().addFeature(lineFeature)
      layers.patternLineLayer.getSource().addFeature(startCircle)
    }
  }

  //* 이전 패턴 로직
  preMakePatternLine(layers, patternData, patternLineData, modFeature) {
    // patternLineLayer.getSource().clear()
    var me = this
    var coords = [patternLineData.initPosArr[1], patternLineData.initPosArr[0]]
    var direction = null
    var distance = null
    var path = []
    const droneCount = patternData.droneList.length
    for (var i = 0; i < (patternLineData.width / patternData.gap) * 2; i++) {
      if (i % 2 == 0) {
        if (i % 4 == 0) {
          direction = 0
        } else {
          direction = 180
        }
        distance = patternLineData.height
      } else if (i % 2 == 1) {
        direction = 90
        distance = patternData.gap
      }
      var tempPoint = me.destinationVincenty(coords[coords.length - 1], direction, distance)
      coords.push(tempPoint)

      var tempLineFeature = new Feature({
        geometry: new LineString([coords[coords.length - 2], tempPoint]),
        layer: 'patternLineLayer',
        patternId: patternData._id
      })
      tempLineFeature.getGeometry().rotate((-patternData.angle / 180) * Math.PI, olExtent.getCenter(modFeature.getGeometry().getExtent()))
      var line1 = turf.multiPolygon([modFeature.getGeometry().getCoordinates()[0]])
      var line2 = turf.multiLineString([tempLineFeature.getGeometry().getCoordinates()])
      var intersects = turf.lineIntersect(line1, line2)
      intersects.features.sort(function(a, b) {
        if (a.geometry.coordinates[0] > b.geometry.coordinates[0]) return 1
        if (a.geometry.coordinates[0] === b.geometry.coordinates[0]) return 0
        if (a.geometry.coordinates[0] < b.geometry.coordinates[0]) return -1
      })
      if (intersects.features.length) {
        if (intersects.features.length > 2) {
          path.push(intersects.features[0].geometry.coordinates)
          path.push(intersects.features[3].geometry.coordinates)
        } else {
          for (var j = 0; j < intersects.features.length; j++) {
            path.push(intersects.features[j].geometry.coordinates)
          }
        }
      }
    }

    for (var b = 0; b < path.length; b++) {
      if (b % 4 == 2) {
        if (b == path.length - 2) {
          break
        }
        if (me.getDistanceFromLatLonInKm([path[b + 2], path[b]]) < me.getDistanceFromLatLonInKm([path[b + 1], path[b]])) {
          let tmp = path[b]
          path[b] = path[b + 1]
          path[b + 1] = tmp
        }
      }
    }

    for (var c = 0; c < path.length; c++) {
      if (c % 2 == 0 && c > 2) {
        if (c + 4 > path.length) {
          break
        }
        if (path[c - 2][1] < path[c - 1][1] && path[c][1] > path[c + 1][1] && path[c + 2][1] > path[c + 3][1]) {
          let tmp = path[c + 2]
          path[c + 2] = path[c + 3]
          path[c + 3] = tmp
        } else if (path[c - 2][1] > path[c - 1][1] && path[c][1] < path[c + 1][1] && path[c + 2][1] < path[c + 3][1]) {
          let tmp = path[c + 2]
          path[c + 2] = path[c + 3]
          path[c + 3] = tmp
        }
      }
    }

    var length = turf.length(turf.multiLineString([path]), { units: 'kilometers' }) // 전체 path 길이
    patternData.path = path
    patternData.length = length
    var tempIndex = 0
    for (var h = 0; h < droneCount; h++) {
      if (tempIndex > 0) {
        path = path.slice(tempIndex)
      }
      var tempLen = length / droneCount
      var tempPath = [path[0]]
      for (var m = 1; m < path.length; m++) {
        tempPath.push(path[m])
        const lengthtest = turf.length(turf.multiLineString([tempPath]), { units: 'kilometers' })
        if (tempLen < lengthtest) {
          tempIndex = m
          break
        }
      }
      patternData.droneList[h].path = tempPath

      var startStyle = {
        fill: new Fill({
          color: 'rgba(211, 211, 211, 0.1)'
        }),
        text: new Text({
          fill: new Fill({
            color: 'white'
          }),
          backgroundStroke: new Stroke({
            color: 'black',
            width: 3.5
          }),
          backgroundFill: new Fill({
            color: 'black',
            opacity: 0.3
          }),
          offsetY: -1,
          offsetX: -1,
          text: 'START',
          font: '13px bold'
        })
      }
      const startCircle = new Feature({
        geometry: new Circle(transform(tempPath[0], 'EPSG:4326', 'EPSG:3857'), 10).transform('EPSG:3857', 'EPSG:4326'),
        layer: 'patternLineLayer',
        patternId: patternData._id
      })
      startCircle.setStyle(new Style(startStyle))

      var styleOption = {
        stroke: new Stroke({
          color: patternData.droneList[h].color,
          width: 3
        })
      }

      for (let i = 0; i < tempPath.length - 1; i++) {
        let setDistance
        const distance = getDistance(tempPath[i], tempPath[i + 1])
        if (distance > 1000) {
          setDistance = (distance / 1000).toFixed(2) + 'km'
        } else {
          setDistance = distance.toFixed(0) + 'm'
        }

        const lineLengthStyle = {
          text: new Text({
            fill: new Fill({
              color: 'white'
            }),
            backgroundStroke: new Stroke({
              color: 'black',
              width: 3.5
            }),
            backgroundFill: new Fill({
              color: 'black',
              opacity: 0.5
            }),
            font: '15px',
            offsetY: -1,
            offsetX: -1.5,
            text: setDistance
          })
        }
        const lineLength = new Feature({
          geometry: new LineString([tempPath[i], tempPath[i + 1]]),
          layer: 'patternLineLayer',
          patternId: patternData._id,
          type: 'lineLength'
        })
        lineLength.setStyle(new Style(lineLengthStyle))
        layers.patternLineLayer.getSource().addFeature(lineLength)
      }

      var lineFeature = new Feature({
        geometry: new LineString(tempPath),
        layer: 'patternLineLayer',
        patternId: patternData._id,
        droneName: patternData.droneList[h].name,
        key: patternData.featureKey
      })
      lineFeature.setStyle(new Style(styleOption))
      layers.patternLineLayer.getSource().addFeature(lineFeature)
      layers.patternLineLayer.getSource().addFeature(startCircle)
    }
  }

  //* 지오팬스 도형 feature 생성
  createGeofenceFeatures(position, ctrlDroneList) {
    const drone = ctrlDroneList.find(item => item.name == position.droneName)
    const hex2rgb = hex => {
      const r = parseInt(hex.slice(1, 3), 16)
      const g = parseInt(hex.slice(3, 5), 16)
      const b = parseInt(hex.slice(5, 7), 16)
      return [r, g, b]
    }
    if (drone) {
      var color = hex2rgb(drone.color)
      color.push(0.3)
    }

    var styleOption = {
      fill: new Fill({
        color: color
      }),
      stroke: new Stroke({
        color: color,
        width: 2
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: 'rgba(0, 0, 0, 0.7)'
        }),
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        })
      }),
      text: new Text({
        font: '13px sans-serif',
        fill: new Fill({
          color: 'white'
        }),
        offsetY: 0,
        offsetX: 0,
        text: `${position.droneName}\n${position.name}`
      })
    }
    let feature
    if (position.type == 'polygon') {
      var polygon = new Polygon(position.position)
      feature = new Feature({
        id: position.id,
        name: position.name,
        type: 'polygon',
        layer: 'geofenceLayer',
        geometry: polygon
      })
    } else if (position.type == 'circle') {
      feature = new Feature({
        id: position.id,
        name: position.name,
        type: 'circle',
        radius: position.radius,
        layer: 'geofenceLayer',
        geometry: new Circle(
          transform([parseFloat(position.position.lng), parseFloat(position.position.lat)], 'EPSG:4326', 'EPSG:3857'),
          position.radius
        ).transform('EPSG:3857', 'EPSG:4326')
      })
    }
    feature.setStyle(new Style(styleOption))
    return feature
  }

  //* Roiter point feature 생성
  createRoiFeature(param) {
    let basicWpImg = color => {
      return `<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_355_70977)">
        <g filter="url(#filter0_d_355_70977)">
        <path d="M22 60C31.9411 60 40 56.4183 40 52C40 47.5817 31.9411 44 22 44C12.0589 44 4 47.5817 4 52C4 56.4183 12.0589 60 22 60Z" fill="${color}"/>
        </g>
        <path d="M35.0904 29.5331L25.4301 38.7019C25.0077 39.1041 24.5058 39.4232 23.9532 39.6409C23.4007 39.8586 22.8082 39.9707 22.2099 39.9707C21.6116 39.9707 21.0192 39.8586 20.4666 39.6409C19.9141 39.4232 19.4122 39.1041 18.9898 38.7019L9.81552 29.5331C7.36368 27.1685 5.72838 24.1515 5.1271 20.8833C4.41827 17.5195 3.98285 13.8674 5.37013 10.7054C6.39461 8.33478 7.96115 6.20973 9.95654 4.48382C11.9519 2.75791 14.3264 1.47425 16.9083 0.725597C19.4901 -0.0230589 22.215 -0.218015 24.886 0.154816C27.5569 0.527647 30.1073 1.45895 32.3528 2.88144C34.5982 4.30393 36.4827 6.18208 37.87 8.38017C39.2574 10.5783 40.113 13.0414 40.3749 15.5916C40.6369 18.1419 40.2987 20.7155 39.3848 23.1265C38.4709 25.5375 37.0041 27.7258 35.0904 29.5331Z" fill="white"/>
        <path d="M31.3101 29.5788L22.8212 38.7776C22.4666 39.1748 22.0314 39.4928 21.5443 39.7106C21.0571 39.9284 20.529 40.041 19.9948 40.041C19.4606 40.041 18.9325 39.9284 18.4454 39.7106C17.9582 39.4928 17.523 39.1748 17.1685 38.7776L8.68945 29.5788C6.41969 27.0886 4.90069 24.0135 4.30614 20.7054C3.69112 17.3458 4.00649 13.8833 5.2185 10.6882C6.39144 7.60092 8.43713 4.91657 11.1092 2.95848C13.6816 1.06377 16.7983 0.0410156 19.9998 0.0410156C23.2012 0.0410156 26.3179 1.06377 28.8903 2.95848C31.5729 4.92924 33.6226 7.63151 34.791 10.7375C35.9955 13.9173 36.3074 17.3629 35.6934 20.7054C35.0999 24.0139 33.5807 27.0892 31.3101 29.5788Z" fill="${color}"/>
        <g filter="url(#filter1_d_355_70977)">
        <path d="M29.4601 15.8097C29.6668 15.6636 29.8238 15.4577 29.9101 15.2197C29.9901 14.9829 29.9901 14.7265 29.9101 14.4897C29.8268 14.2474 29.6693 14.0375 29.4601 13.8897C29.2412 13.7424 28.9839 13.6624 28.7201 13.6597L23.5601 13.4797C23.5374 13.4873 23.5128 13.4873 23.4901 13.4797L21.6701 8.8797C21.575 8.64636 21.411 8.44753 21.2001 8.3097C20.9826 8.16969 20.7287 8.09666 20.4701 8.0997C20.2117 8.09839 19.9583 8.17127 19.7401 8.3097C19.532 8.4481 19.3714 8.64707 19.2801 8.8797L17.5001 13.4097L17.4501 13.4697H17.3801L12.2301 13.6597C11.9655 13.6574 11.7068 13.7378 11.4901 13.8897C11.2652 14.0348 11.0933 14.2488 11.0001 14.4997C10.9153 14.7357 10.9153 14.9937 11.0001 15.2297C11.0834 15.4747 11.2451 15.6856 11.4601 15.8297L15.4601 18.8297C15.472 18.8358 15.4826 18.8443 15.4912 18.8546C15.4998 18.8649 15.5062 18.8769 15.5101 18.8897C15.5152 18.9127 15.5152 18.9367 15.5101 18.9597L14.1201 23.5797C14.0307 23.8114 14.0307 24.068 14.1201 24.2997C14.204 24.5363 14.3617 24.7396 14.5701 24.8797C14.788 25.0213 15.0404 25.1008 15.3001 25.1097C15.5587 25.1127 15.8126 25.0397 16.0301 24.8997L20.3101 22.2097H20.3801H20.4501L24.7301 24.8997C24.9464 25.0433 25.2004 25.1199 25.4601 25.1199C25.7198 25.1199 25.9737 25.0433 26.1901 24.8997C26.3982 24.7613 26.5587 24.5623 26.6501 24.3297C26.7251 24.0956 26.7251 23.8438 26.6501 23.6097L25.3501 18.9997C25.3448 18.9888 25.342 18.9768 25.342 18.9647C25.342 18.9526 25.3448 18.9406 25.3501 18.9297C25.3439 18.9102 25.3439 18.8892 25.3501 18.8697L29.4601 15.8097Z" fill="white"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_d_355_70977" x="0" y="42" width="44" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_70977"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_70977" result="shape"/>
        </filter>
        <filter id="filter1_d_355_70977" x="10.9365" y="8.09961" width="20.0334" height="18.0205" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dx="1" dy="1"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_70977"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_70977" result="shape"/>
        </filter>
        <clipPath id="clip0_355_70977">
        <rect width="44" height="65" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        `
    }

    var color = param.color
    var styleOption = {
      image: new Icon({
        src: 'data:image/svg+xml;utf8,' + escape(basicWpImg(color)),
        scale: 0.5,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      })
    }
    var feature = new Feature({
      geometry: new Point([param.lng, param.lat]),
      layer: 'roiLayer'
    })
    feature.setStyle(new Style(styleOption))
    feature.set('prop', param)
    return feature
  }

  //* Roiter interaction 관리
  setRoiInteraction(proxy, state, layers) {
    var modify = new Modify({
      source: layers.roiLayer.getSource(),
      style: function(feature) {
        let param = null
        for (let i = 0; i < state.waypointList.length; i++) {
          if (state.waypointList[i].pointType == 'roi') {
            if (state.waypointList[i].id === feature.getProperties().features[0].get('prop').id) param = state.waypointList[i]
          }
        }
        var styleOption = {}
        const clickHomeImg = color => {
          return `<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_355_70978)">
          <g filter="url(#filter0_d_355_70978)">
          <path d="M22 60C31.9411 60 40 56.4183 40 52C40 47.5817 31.9411 44 22 44C12.0589 44 4 47.5817 4 52C4 56.4183 12.0589 60 22 60Z" fill="${color}"/>
          </g>
          <path d="M35.0904 29.5331L25.4301 38.7019C25.0077 39.1041 24.5058 39.4232 23.9532 39.6409C23.4007 39.8586 22.8082 39.9707 22.2099 39.9707C21.6116 39.9707 21.0192 39.8586 20.4666 39.6409C19.9141 39.4232 19.4122 39.1041 18.9898 38.7019L9.81552 29.5331C7.36368 27.1685 5.72838 24.1515 5.1271 20.8833C4.41827 17.5195 3.98285 13.8674 5.37013 10.7054C6.39461 8.33478 7.96115 6.20973 9.95654 4.48382C11.9519 2.75791 14.3264 1.47425 16.9083 0.725597C19.4901 -0.0230589 22.215 -0.218015 24.886 0.154816C27.5569 0.527647 30.1073 1.45895 32.3528 2.88144C34.5982 4.30393 36.4827 6.18208 37.87 8.38017C39.2574 10.5783 40.113 13.0414 40.3749 15.5916C40.6369 18.1419 40.2987 20.7155 39.3848 23.1265C38.4709 25.5375 37.0041 27.7258 35.0904 29.5331Z" fill="white" stroke="#FFE5C7" stroke-width="2"/>
          <path d="M31.3101 29.5788L22.8212 38.7776C22.4666 39.1748 22.0314 39.4928 21.5443 39.7106C21.0571 39.9284 20.529 40.041 19.9948 40.041C19.4606 40.041 18.9325 39.9284 18.4454 39.7106C17.9582 39.4928 17.523 39.1748 17.1685 38.7776L8.68945 29.5788C6.41969 27.0886 4.90069 24.0135 4.30614 20.7054C3.69112 17.3458 4.00649 13.8833 5.2185 10.6882C6.39144 7.60092 8.43713 4.91657 11.1092 2.95848C13.6816 1.06377 16.7983 0.0410156 19.9998 0.0410156C23.2012 0.0410156 26.3179 1.06377 28.8903 2.95848C31.5729 4.92924 33.6226 7.63151 34.791 10.7375C35.9955 13.9173 36.3074 17.3629 35.6934 20.7054C35.0999 24.0139 33.5807 27.0892 31.3101 29.5788Z" fill="${color}" stroke="#FFE5C7" stroke-width="2"/>
          <g filter="url(#filter1_d_355_70978)">
          <path d="M29.4601 15.8097C29.6668 15.6636 29.8238 15.4577 29.9101 15.2197C29.9901 14.9829 29.9901 14.7265 29.9101 14.4897C29.8268 14.2474 29.6693 14.0375 29.4601 13.8897C29.2412 13.7424 28.9839 13.6624 28.7201 13.6597L23.5601 13.4797C23.5374 13.4873 23.5128 13.4873 23.4901 13.4797L21.6701 8.8797C21.575 8.64636 21.411 8.44753 21.2001 8.3097C20.9826 8.16969 20.7287 8.09666 20.4701 8.0997C20.2117 8.09839 19.9583 8.17127 19.7401 8.3097C19.532 8.4481 19.3714 8.64707 19.2801 8.8797L17.5001 13.4097L17.4501 13.4697H17.3801L12.2301 13.6597C11.9655 13.6574 11.7068 13.7378 11.4901 13.8897C11.2652 14.0348 11.0933 14.2488 11.0001 14.4997C10.9153 14.7357 10.9153 14.9937 11.0001 15.2297C11.0834 15.4747 11.2451 15.6856 11.4601 15.8297L15.4601 18.8297C15.472 18.8358 15.4826 18.8443 15.4912 18.8546C15.4998 18.8649 15.5062 18.8769 15.5101 18.8897C15.5152 18.9127 15.5152 18.9367 15.5101 18.9597L14.1201 23.5797C14.0307 23.8114 14.0307 24.068 14.1201 24.2997C14.204 24.5363 14.3617 24.7396 14.5701 24.8797C14.788 25.0213 15.0404 25.1008 15.3001 25.1097C15.5587 25.1127 15.8126 25.0397 16.0301 24.8997L20.3101 22.2097H20.3801H20.4501L24.7301 24.8997C24.9464 25.0433 25.2004 25.1199 25.4601 25.1199C25.7198 25.1199 25.9737 25.0433 26.1901 24.8997C26.3982 24.7613 26.5587 24.5623 26.6501 24.3297C26.7251 24.0956 26.7251 23.8438 26.6501 23.6097L25.3501 18.9997C25.3448 18.9888 25.342 18.9768 25.342 18.9647C25.342 18.9526 25.3448 18.9406 25.3501 18.9297C25.3439 18.9102 25.3439 18.8892 25.3501 18.8697L29.4601 15.8097Z" fill="white"/>
          </g>
          </g>
          <defs>
          <filter id="filter0_d_355_70978" x="0" y="42" width="44" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_70978"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_70978" result="shape"/>
          </filter>
          <filter id="filter1_d_355_70978" x="10.9365" y="8.09961" width="20.0334" height="18.0205" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="1" dy="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_355_70978"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_355_70978" result="shape"/>
          </filter>
          <clipPath id="clip0_355_70978">
          <rect width="44" height="65" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          `
        }
        var color = param.color
        styleOption.image = new Icon({
          src: 'data:image/svg+xml;utf8,' + escape(clickHomeImg(color)),
          scale: 0.5,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction'
        })
        feature.setStyle(new Style(styleOption))
      }
    })

    modify.on('modifyend', function(e) {
      var feature = e.features.getArray()[0]
      feature.get('prop').lng = feature.getGeometry().getCoordinates()[0]
      feature.get('prop').lat = feature.getGeometry().getCoordinates()[1]
      feature.get('prop').latLng.lng = feature.getGeometry().getCoordinates()[0]
      feature.get('prop').latLng.lat = feature.getGeometry().getCoordinates()[1]
      var wpId = feature.get('prop').id
      var idx = state.waypointList.findIndex(wp => wp.id === wpId)
      state.waypointList[idx].lng = feature.getGeometry().getCoordinates()[0]
      state.waypointList[idx].lat = feature.getGeometry().getCoordinates()[1]
      state.waypointList[idx].latLng.lng = feature.getGeometry().getCoordinates()[0]
      state.waypointList[idx].latLng.lat = feature.getGeometry().getCoordinates()[1]
      proxy.$MavlinkUtils.saveGoto(state.waypointList)
    })
    proxy.$store.dispatch('local/setWpList', state.waypointList)
    proxy.$store.dispatch('local/setWpRefresh', true)
    state.map.addInteraction(modify)
  }

  //* 홈포지션 point Feature 생성
  createHomePositionFeature(param, coordinates) {
    let HomeImg = color => {
      return `<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_354_70826)">
      <g filter="url(#filter0_d_354_70826)">
      <path d="M24 62C33.9411 62 42 58.4183 42 54C42 49.5817 33.9411 46 24 46C14.0589 46 6 49.5817 6 54C6 58.4183 14.0589 62 24 62Z" fill="${color}"/>
      </g>
      <path d="M37.0904 32.2722L27.4301 41.6702C27.0077 42.0824 26.5058 42.4095 25.9532 42.6326C25.4007 42.8558 24.8082 42.9707 24.2099 42.9707C23.6116 42.9707 23.0192 42.8558 22.4666 42.6326C21.9141 42.4095 21.4122 42.0824 20.9898 41.6702L11.8155 32.2722C9.36368 29.8485 7.72838 26.756 7.1271 23.4062C6.41827 19.9583 5.98285 16.2148 7.37013 12.9738C8.39461 10.5439 9.96115 8.3657 11.9565 6.59665C13.9519 4.82759 16.3264 3.51184 18.9083 2.74447C21.4901 1.9771 24.215 1.77727 26.886 2.15942C29.5569 2.54157 32.1073 3.49616 34.3528 4.95421C36.5982 6.41226 38.4827 8.33736 39.87 10.5904C41.2574 12.8435 42.113 15.3682 42.3749 17.9822C42.6369 20.5961 42.2987 23.2341 41.3848 25.7054C40.4709 28.1767 39.0041 30.4197 37.0904 32.2722Z" fill="white"/>
      <path d="M33.3101 31.5788L24.8212 40.7776C24.4666 41.1748 24.0314 41.4928 23.5443 41.7106C23.0571 41.9284 22.529 42.041 21.9948 42.041C21.4606 42.041 20.9325 41.9284 20.4454 41.7106C19.9582 41.4928 19.523 41.1748 19.1685 40.7776L10.6894 31.5788C8.41969 29.0886 6.90069 26.0135 6.30614 22.7054C5.69112 19.3458 6.00649 15.8833 7.2185 12.6882C8.39144 9.60092 10.4371 6.91657 13.1092 4.95848C15.6816 3.06377 18.7983 2.04102 21.9998 2.04102C25.2012 2.04102 28.3179 3.06377 30.8903 4.95848C33.5729 6.92924 35.6226 9.63151 36.791 12.7375C37.9955 15.9173 38.3074 19.3629 37.6934 22.7054C37.0999 26.0139 35.5807 29.0892 33.3101 31.5788Z" fill="${color}"/>
      <g filter="url(#filter1_d_354_70826)">
      <path d="M15.3701 12H19.5394V18.4896H25.2431V12H29.3701V29H25.2431V21.9896H19.5394V29H15.3701V12Z" fill="white"/>
      </g>
      </g>
      <defs>
      <filter id="filter0_d_354_70826" x="2" y="44" width="44" height="24" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="2"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_354_70826"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_354_70826" result="shape"/>
      </filter>
      <filter id="filter1_d_354_70826" x="15.3701" y="12" width="15" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dx="1" dy="1"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 1 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_354_70826"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_354_70826" result="shape"/>
      </filter>
      <clipPath id="clip0_354_70826">
      <rect width="44" height="65" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      `
    }

    var styleOption = {
      image: new Icon({
        src: 'data:image/svg+xml;utf8,' + escape(HomeImg(param.color)),
        scale: 0.5,
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction'
      })
    }
    var feature = new Feature({
      geometry: new Point([coordinates[0], coordinates[1]]),
      layer: 'homePositionLayer',
      alt: 0
    })
    const homeParam = { lng: coordinates[0], lat: coordinates[1], droneName: param.name, droneId: param.id, color: param.color, pointType: 'home' }
    feature.setStyle(new Style(styleOption))
    feature.set('prop', homeParam)
    return feature
  }

  //* 홈포지션 interaction 관리
  setHomePositionInteraction(proxy, state, layers) {
    var modify = new Modify({
      source: layers.homePositionLayer.getSource(),
      style: function(feature) {
        let param = null
        for (let i = 0; i < state.hpList.length; i++) {
          if (state.hpList[i].droneName === feature.getProperties().features[0].get('prop').droneName) param = state.hpList[i]
        }
        var styleOption = {}
        const clickHomeImg = color => {
          return `<svg width="44" height="65" viewBox="0 0 44 65" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_354_70853)">
          <g filter="url(#filter0_d_354_70853)">
          <path d="M24 62C33.9411 62 42 58.4183 42 54C42 49.5817 33.9411 46 24 46C14.0589 46 6 49.5817 6 54C6 58.4183 14.0589 62 24 62Z" fill="${color}"/>
          <path d="M24 62C33.9411 62 42 58.4183 42 54C42 49.5817 33.9411 46 24 46C14.0589 46 6 49.5817 6 54C6 58.4183 14.0589 62 24 62Z" stroke="#FFE5C7" stroke-width="2"/>
          </g>
          <path d="M37.0904 32.2722L27.4301 41.6702C27.0077 42.0824 26.5058 42.4095 25.9532 42.6326C25.4007 42.8558 24.8082 42.9707 24.2099 42.9707C23.6116 42.9707 23.0192 42.8558 22.4666 42.6326C21.9141 42.4095 21.4122 42.0824 20.9898 41.6702L11.8155 32.2722C9.36368 29.8485 7.72838 26.756 7.1271 23.4062C6.41827 19.9583 5.98285 16.2148 7.37013 12.9738C8.39461 10.5439 9.96115 8.3657 11.9565 6.59665C13.9519 4.82759 16.3264 3.51184 18.9083 2.74447C21.4901 1.9771 24.215 1.77727 26.886 2.15942C29.5569 2.54157 32.1073 3.49616 34.3528 4.95421C36.5982 6.41226 38.4827 8.33736 39.87 10.5904C41.2574 12.8435 42.113 15.3682 42.3749 17.9822C42.6369 20.5961 42.2987 23.2341 41.3848 25.7054C40.4709 28.1767 39.0041 30.4197 37.0904 32.2722Z" fill="white" stroke="#FFE5C7" stroke-width="2"/>
          <path d="M33.3101 31.5788L24.8212 40.7776C24.4666 41.1748 24.0314 41.4928 23.5443 41.7106C23.0571 41.9284 22.529 42.041 21.9948 42.041C21.4606 42.041 20.9325 41.9284 20.4454 41.7106C19.9582 41.4928 19.523 41.1748 19.1685 40.7776L10.6894 31.5788C8.41969 29.0886 6.90069 26.0135 6.30614 22.7054C5.69112 19.3458 6.00649 15.8833 7.2185 12.6882C8.39144 9.60092 10.4371 6.91657 13.1092 4.95848C15.6816 3.06377 18.7983 2.04102 21.9998 2.04102C25.2012 2.04102 28.3179 3.06377 30.8903 4.95848C33.5729 6.92924 35.6226 9.63151 36.791 12.7375C37.9955 15.9173 38.3074 19.3629 37.6934 22.7054C37.0999 26.0139 35.5807 29.0892 33.3101 31.5788Z" fill="${color}" stroke="#FFE5C7" stroke-width="2"/>
          <g filter="url(#filter1_d_354_70853)">
          <path d="M15.3701 12H19.5394V18.4896H25.2431V12H29.3701V29H25.2431V21.9896H19.5394V29H15.3701V12Z" fill="white"/>
          </g>
          </g>
          <defs>
          <filter id="filter0_d_354_70853" x="1" y="43" width="46" height="26" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="2"/>
          <feGaussianBlur stdDeviation="2"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_354_70853"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_354_70853" result="shape"/>
          </filter>
          <filter id="filter1_d_354_70853" x="15.3701" y="12" width="15" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="1" dy="1"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 0 0.258824 0 0 0 1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_354_70853"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_354_70853" result="shape"/>
          </filter>
          <clipPath id="clip0_354_70853">
          <rect width="44" height="65" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          `
        }
        var color = param.color
        styleOption.image = new Icon({
          src: 'data:image/svg+xml;utf8,' + escape(clickHomeImg(color)),
          scale: 0.5,
          anchorXUnits: 'fraction',
          anchorYUnits: 'fraction'
        })
        feature.setStyle(new Style(styleOption))
      }
    })

    modify.on('modifyend', function(e) {
      var feature = e.features.getArray()[0]
      feature.get('prop').lng = feature.getGeometry().getCoordinates()[0]
      feature.get('prop').lat = feature.getGeometry().getCoordinates()[1]
      var wpId = feature.get('prop').droneName
      const drone = state.ctrlDroneList.find(item => item.name == wpId)
      proxy.$MavlinkUtils.setHome(drone, { lat: feature.getGeometry().getCoordinates()[1], lon: feature.getGeometry().getCoordinates()[0] })
    })
    state.map.addInteraction(modify)
  }

  //! Utility
  getLayer(map, feature) {
    var this_ = feature,
      layer_,
      layersToLookFor = []
    map.getLayers().forEach(function(layer) {
      if (layer instanceof LayerGroup) {
        layer.getLayers().forEach(check)
      } else {
        check(layer)
      }
    })
    // feature object로 인식되서 some false만 결과값
    layersToLookFor.forEach(function(obj) {
      var found = obj.features.some(feature => {
        return this_.ol_uid === feature.ol_uid
      })
      if (found) {
        layer_ = obj.layer
      }
    })
    return layer_
    function check(layer) {
      var source = layer.getSource()
      if (source instanceof VectorSource) {
        var features = source.getFeatures()
        if (features.length > 0) {
          layersToLookFor.push({
            layer: layer,
            features: features
          })
        }
      }
    }
  }

  getDistanceFromLatLonInKm(array) {
    const [lng1, lat1] = array[0]
    const [lng2, lat2] = array[1]

    const deg2rad = deg => deg * (Math.PI / 180)
    const earthRadiusKm = 6357

    const dLat = deg2rad(lat2 - lat1)
    const dLon = deg2rad(lng2 - lng1)

    const a = Math.sin(dLat / 2) ** 2 + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) ** 2

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distanceInKm = earthRadiusKm * c

    return Math.round(distanceInKm * 1000)
  }

  // getDistanceFromLatLonInKm = function(array) {
  //   var lat1 = array[0][1]
  //   var lng1 = array[0][0]
  //   var lat2 = array[1][1]
  //   var lng2 = array[1][0]

  //   function deg2rad(deg) {
  //     return deg * (Math.PI / 180)
  //   }
  //   var r = 6357 //지구의 반지름(km)
  //   var dLat = deg2rad(lat2 - lat1)
  //   var dLon = deg2rad(lng2 - lng1)
  //   var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
  //   var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  //   var d = r * c // Distance in km
  //   return Math.round(d * 1000)
  // }

  destinationVincenty = function(lonlat, brng, dist) {
    var u = {}
    u.rad = function(x) {
      return (x * Math.PI) / 180
    }
    u.deg = function(x) {
      return (x * 180) / Math.PI
    }
    u.VincentyConstants = {
      a: 6378137,
      b: 6356752.3142,
      f: 1 / 298.257223563
    }

    var ct = u.VincentyConstants
    var a = ct.a,
      b = ct.b,
      f = ct.f

    var lon1 = lonlat[0] //lonlat.lon;
    var lat1 = lonlat[1] //lonlat.lat;

    var s = dist
    var alpha1 = u.rad(brng)
    var sinAlpha1 = Math.sin(alpha1)
    var cosAlpha1 = Math.cos(alpha1)

    var tanU1 = (1 - f) * Math.tan(u.rad(lat1))
    var cosU1 = 1 / Math.sqrt(1 + tanU1 * tanU1),
      sinU1 = tanU1 * cosU1
    var sigma1 = Math.atan2(tanU1, cosAlpha1)
    var sinAlpha = cosU1 * sinAlpha1
    var cosSqAlpha = 1 - sinAlpha * sinAlpha
    var uSq = (cosSqAlpha * (a * a - b * b)) / (b * b)
    var A = 1 + (uSq / 16384) * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)))
    var B = (uSq / 1024) * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)))

    var sigma = s / (b * A),
      sigmaP = 2 * Math.PI
    while (Math.abs(sigma - sigmaP) > 1e-12) {
      var cos2SigmaM = Math.cos(2 * sigma1 + sigma)
      var sinSigma = Math.sin(sigma)
      var cosSigma = Math.cos(sigma)
      var deltaSigma =
        B *
        sinSigma *
        (cos2SigmaM +
          (B / 4) *
            (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
              (B / 6) * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)))
      sigmaP = sigma
      sigma = s / (b * A) + deltaSigma
    }

    var tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1
    var lat2 = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1, (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp))
    var lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1)
    var C = (f / 16) * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha))
    var L = lambda - (1 - C) * f * sinAlpha * (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)))

    // var revAz = Math.atan2(sinAlpha, -tmp);

    return [lon1 + u.deg(L), u.deg(lat2)]
  }

  addAlpha(color, opacity) {
    var _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
    return color + _opacity.toString(16).toUpperCase()
  }

  getVworldArea(type, box) {
    return new Promise(function(resolve, reject) {
      if (!type) {
        reject(Error('Invalid V-World Data Type'))
        return
      }
      TokenUtils.getAccessToken().then(token => {
        axios({
          method: 'GET',
          url: API_URL + './vworld',
          headers: { gcsAcToken: token },
          params: {
            type: type,
            box: box
          }
        })
          .then(response => {
            resolve(response)
          })
          .catch(function(err) {
            reject(err)
          })
      })
    })
  }

  getOpenlayersFeatures(features) {
    var openlayersFeatures = []
    for (var i = 0; i < features.length; i++) {
      var feature = new Feature({
        id: features[i].id,
        geometry: new MultiPolygon(features[i].geometry.coordinates)
      })
      openlayersFeatures.push(feature)
    }
    return openlayersFeatures
  }
}

export default new OlUtils()
