import axios from 'axios'
import jwt from 'jsonwebtoken'
import tokenUtil from '@/services/auth/Token'
import configUtil from '@/services/common/Config'
import ServiceExec from '@/common/utils/ServiceExec'

const API_URL = configUtil.getServiceUrl()
class AuthUtils extends ServiceExec {
  async login(user) {
    let result
    let userData = {
      userInfo: null,
      gcsactoken: '',
      gcsretoken: ''
    }
    try {
      const config = await (await fetch(process.env.BASE_URL + 'config.json')).json()
      user.key = config.default.jwt.SECRET
      await this.post(`${API_URL}auth`, user).then(res => {
        jwt.verify(res.headers.gcsactoken, user.key, (err, decoded) => {
          result = decoded
        })
        userData.userInfo = JSON.stringify(result)
        userData.gcsactoken = res.headers.gcsactoken
        userData.gcsretoken = res.headers.gcsretoken
      })
      return userData
    } catch (error) {
      throw new Error(error)
    }
  }

  async register(info) {
    try {
      const res = await axios.post(API_URL + 'user', {
        email: info.email,
        name: info.name,
        password: info.password,
        licenseName: info.licenseName,
        licenseNo: info.licenseNo,
        phoneNumber: info.phoneNumber
      })
      return res
    } catch (error) {
      throw new Error(error)
    }
  }

  async modifyUserInfo(userInfo) {
    try {
      const token = await tokenUtil.getAccessToken()
      await axios({
        method: 'PUT',
        url: API_URL + 'user',
        headers: { gcsAcToken: token },
        data: userInfo
      }).then(res => {
        return res
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async getAllUser() {
    try {
      const res = await axios.get(API_URL + 'user')
      return res
    } catch (error) {
      throw new Error(error)
    }
  }

  async checkPassword(password) {
    try {
      const token = await tokenUtil.getAccessToken()
      const res = await axios({
        method: 'PUT',
        url: API_URL + 'user/pwd',
        headers: { gcsAcToken: token },
        data: { password: password }
      })
      return res
    } catch (error) {
      throw new Error(error)
    }
  }

  async checkAE(ae) {
    try {
      const res = await axios.get(API_URL + `user/check/${ae}`)
      return res
    } catch (error) {
      throw new Error(error)
    }
  }

  async checkEmail(email) {
    try {
      const res = await axios.get(API_URL + `user/${email}/check`)
      return res
    } catch (error) {
      throw new Error(error)
    }
  }

  async getUserRFData() {
    try {
      const token = await tokenUtil.getAccessToken()
      const result = await axios({
        url: API_URL + 'user/rf',
        method: 'GET',
        headers: { gcsAcToken: token }
      })
      return result
    } catch (error) {
      console.log(error)
    }
  }

  async setUserRFData(rfData) {
    try {
      const token = await tokenUtil.getAccessToken()
      const result = await axios({
        url: API_URL + 'user/rf',
        method: 'POST',
        headers: { gcsAcToken: token },
        data: {
          channel: rfData.channel,
          rfDrone: rfData.rfDrone,
          extents: rfData.extents
        }
      })
      return result
    } catch (error) {
      console.log(error)
    }
  }
}

export default new AuthUtils()
