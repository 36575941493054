import axios from 'axios'
import TokenUtils from '@/services/auth/Token'
import configUtils from '@/services/common/Config'

const API_URL = configUtils.getServiceUrl() + 'geofence/'

class GeofenceUtils {
  async createGeofence(geofenceInfo) {
    try {
      const token = await TokenUtils.getAccessToken()
      await axios({
        method: 'POST',
        url: API_URL,
        headers: { gcsAcToken: token },
        data: {
          geofence: geofenceInfo
        }
      })
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async getGeofence(droneNameList) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'GET',
        url: API_URL + 'drone',
        headers: { gcsAcToken: token },
        params: {
          droneNameList: droneNameList,
        }
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async getAllGeofence() {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'GET',
        url: API_URL + 'all',
        headers: { gcsAcToken: token }
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async getGeofenceById(geofence) {
    console.log(geofence)
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'GET',
        url: API_URL + geofence,
        headers: { gcsAcToken: token }
      })
      return result.data
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async updateGeofence(geofence) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'PUT',
        url: API_URL + geofence._id,
        headers: { gcsAcToken: token },
        data: {
          geofence: geofence
        }
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async deleteGeofence(geofence) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'DELETE',
        url: API_URL + geofence,
        headers: { gcsAcToken: token }
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }
}

export default new GeofenceUtils()
