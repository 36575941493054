import axios from 'axios'

const API_URL = 'http://data.iotocean.org:9980/api/v1/lookup'

class openElevation {
    async getElevation(location) {
        let url = `${API_URL}?locations=${location.latitude},${location.longitude}`
        try {
            let result = await axios.get(url)
            const {elevation} = result.data.results[0]
            return elevation
        } catch {
            console.log('error: elevation api')
            return 0
        }

    }
}

export default new openElevation()