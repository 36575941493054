<template>
  <wrap />
</template>

<script>
import Wrap from '@/components/views/index'
export default {
  name: 'App',
  components: { Wrap }
}
</script>

<style scoped>
@import "./assets/static/css/knps.css";
</style>