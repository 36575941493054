import io from 'socket.io-client'
import configUtils from '@/services/common/Config'

const API_URL = configUtils.getServiceUrl()

var SOCKET = io.connect(API_URL)

class SocketUtils {
  gcsConnect() {
    if (SOCKET) {
      SOCKET.emit('gcs:connect')
    } else {
      SOCKET = io.connect(API_URL)
      SOCKET.emit('gcs:connect')
    }
  }

  pilotConnect() {
    if (SOCKET) {
      SOCKET.emit('pilot:connect')
    } else {
      SOCKET = io.connect(API_URL)
      SOCKET.emit('pilot:connect')
    }
  }

  close() {
    if (SOCKET) SOCKET.close()
    SOCKET = null
  }

  disconnect() {
    SOCKET.disconnect()
  }

  addListener(event, handler) {
    if (SOCKET) SOCKET.on(event, handler)
    else throw new Error("Error! Socket doesn't be connected")
  }
}

export default new SocketUtils()
