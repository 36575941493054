<template>
  <div class="modal-dim" v-show-modal-alert style="z-index: 1002">
    <!--'on 추가'-->
    <div class="set-window-wrap">
      <div class="set-window sub">
        <div class="set-window-top">
          <div class="set-window-sub">
            <div class="sub">
              <i class="i-set-add"></i>
              <h2>드론 정보</h2>
            </div>
            <div class="close" @click="fnCallBackCancel">
              <i class="i-close-26"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con" style="border-radius: 0;">
          <div class="sect">
            <p class="input-info">*는 필수 입력사항입니다.</p>
            <div class="row2 mt12">
              <div class="form column">
                <p class="form-sub">드론 이름*</p>
                <label class="label">
                  <input type="text" v-model="name" placeholder="이름 생성 (중복불가)" />
                </label>
                <p class="valid" v-if="nameValid">{{ nameValidText }}</p>
              </div>
              <div class="form column">
                <p class="form-sub">Mobius ID*</p>
                <label class="label">
                  <input type="text" v-model="id" placeholder="ID 생성 (중복불가)" />
                </label>
                <p class="valid" v-if="idValid">{{ idValidText }}</p>
              </div>
              <div class="form column">
                <p class="form-sub">FC 유형*</p>
                <div class="select-common">
                  <div class="select-common-tab" @click="fnOpenTypeTab">
                    <span>ardupilot</span>
                  </div>
                  <ul class="select-common-option" :class="{ on: typeTab === true }">
                    <li class="sample">
                      <ul>
                        <li @click="fnSelectType('ardupilot')">
                          <span>ardupilot</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!-- <label class="label">
                  <select v-model="fcType" class="select">
                    <option :value="'ardupilot'">Ardupilot</option>
                  </select>
                </label> -->
              </div>
              <div class="form column">
                <p class="form-sub">시스템 ID*</p>
                <label class="label">
                  <input type="number" v-model="sysId" placeholder="1 - 255" />
                </label>
                <p class="valid" v-if="sysidValid">{{ sysidValidText }}</p>
              </div>
              <div class="form column">
                <p class="form-sub">배터리 셀*</p>
                <label class="label">
                  <input type="number" v-model="cell" placeholder="Cell 갯수" />
                </label>
                <p class="valid" v-if="cellValid">{{ cellValidText }}</p>
              </div>
              <div class="form column">
                <p class="form-sub">색상*</p>
                <div class="select-color">
                  <div class="select-color-tab" @click="fnOpenColorTab">
                    <div class="color-box color red"></div>
                    <span>red</span>
                  </div>
                  <!--JY: 탭 클릭시 아래 옵션 활성화: 셀렉트처럼-->
                  <ul class="select-color-option " :class="{ on: tab === true }">
                    <!-- add new color -->
                    <!-- <li class="color-add" @click.prevent.stop="fnAddNewColor">
                      <a href="#">
                        <p>Add New Color</p>
                        <i class="i-add-gray"></i>
                      </a>
                    </li> -->
                    <li class="sample">
                      <ul>
                        <li @click="fnSelectColor('red')">
                          <div class="color-box color red"></div>
                          <span>red</span>
                          <!--#f44336-->
                        </li>
                        <li @click="fnSelectColor('pink')">
                          <div class="color-box color pink"></div>
                          <span>pink</span>
                          <!--#E91E63-->
                        </li>
                        <li @click="fnSelectColor('deep-purple')">
                          <div class="color-box color deep-purple"></div>
                          <span>deep-purple</span>
                          <!--##673AB7-->
                        </li>
                        <li @click="fnSelectColor('purple')">
                          <div class="color-box color purple"></div>
                          <span>purple</span>
                          <!--#9C27B0-->
                        </li>
                        <li @click="fnSelectColor('indigo')">
                          <div class="color-box color indigo"></div>
                          <span>indigo</span>
                          <!--#3F51B5-->
                        </li>
                        <li @click="fnSelectColor('blue')">
                          <div class="color-box color blue"></div>
                          <span>blue</span>
                          <!--#2196F3-->
                        </li>
                        <li @click="fnSelectColor('light-blue')">
                          <div class="color-box color light-blue"></div>
                          <span>light-blue</span>
                          <!--##03A9F4-->
                        </li>
                        <li @click="fnSelectColor('teal')">
                          <div class="color-box color teal"></div>
                          <span>teal</span>
                          <!--#009688-->
                        </li>
                        <li @click="fnSelectColor('green')">
                          <div class="color-box color green"></div>
                          <span>green</span>
                          <!--#4CAF50-->
                        </li>
                        <li @click="fnSelectColor('lime')">
                          <div class="color-box color lime"></div>
                          <span>lime</span>
                          <!--#CDDC39-->
                        </li>
                        <li @click="fnSelectColor('yellow')">
                          <div class="color-box color yellow"></div>
                          <span>yellow</span>
                          <!--##FFEB3B-->
                        </li>
                        <li @click="fnSelectColor('amber')">
                          <div class="color-box color amber"></div>
                          <span>amber</span>
                          <!--#FFC107-->
                        </li>
                        <li @click="fnSelectColor('orange')">
                          <div class="color-box color orange"></div>
                          <span>orange</span>
                          <!--##FF9800-->
                        </li>
                        <li @click="fnSelectColor('deep-orange')">
                          <div class="color-box color deep-orange"></div>
                          <span>deep-orange</span>
                          <!--#FF5722-->
                        </li>
                        <li @click="fnSelectColor('brown')">
                          <div class="color-box color brown"></div>
                          <span>brown</span>
                          <!--#795548-->
                        </li>
                        <li @click="fnSelectColor('blue-grey')">
                          <div class="color-box color blue-grey"></div>
                          <span>blue-grey</span>
                          <!--#607D8B-->
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <p class="valid" v-if="colorValid">{{ colorValidText }}</p>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button class="btn gray" v-if="isEdit" @click="fnCallBackCancel">
              취소
            </button>
            <button class="btn color" @click="createDrone">저장</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, onMounted, computed } from 'vue'
import { modalAlertNames } from '@/components/common/globalComponent'
import { useModalState } from '@/common/state/ModalState'
import { useStore } from 'vuex'
import $ from 'jquery'

export default {
  name: 'drone-add',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    param: {
      type: [Object],
      default: () => {}
    },
    doneText: {
      type: [String],
      default: 'CONFIRM'
    },
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { modalPopUp, fnModalAlert, fnBaseConfirm } = useModalState()
    const { dispatch, getters } = useStore()

    const page = reactive({
      isEdit: false
    })

    const droneData = reactive({
      action: 'create',
      host: computed(() => {
        return getters['local/getHost']
      }),
      ae: computed(() => {
        return getters['local/getAe']
      }),
      droneList: computed(() => {
        return getters['drone/getDroneList']
      }),
      name: null,
      id: null,
      fcType: null,
      color: null,
      cell: 6,
      position: {
        longitude: '0',
        latitude: '0'
      },
      sysId: null,
      control: null,
      forceCreate: null
    })

    const state = reactive({
      tab: false,
      typeTab: false
    })

    onMounted(() => {
      if (props.param) {
        page.isEdit = props.param.isEdit
        droneData.action = 'update'
        droneData.name = props.param.name
        droneData.id = props.param.id
        droneData.fcType = props.param.fcType
        droneData.color = props.param.color
        droneData.cell = props.param.cell
        droneData.sysId = props.param.sysId
        droneData.forceCreate = true
        fnInitColorPanel(props.param.color)
      } else {
        resetData()
      }
    })

    const fnInitColorPanel = color => {
      const selectDiv = $('.select-color-tab')
      selectDiv.empty()
      let panelTitle = ''
      if (color === '#F44336') {
        panelTitle = 'red'
      } else if (color === '#E91E63') {
        panelTitle = 'pink'
      } else if (color === '#673AB7') {
        panelTitle = 'deep-purple'
      } else if (color === '#9C27B0') {
        panelTitle = 'purple'
      } else if (color === '#3F51B5') {
        panelTitle = 'indigo'
      } else if (color === '#2196F3') {
        panelTitle = 'blue'
      } else if (color === '#03A9F4') {
        panelTitle = 'light-blue'
      } else if (color === '#009688') {
        panelTitle = 'teal'
      } else if (color === '#4CAF50') {
        panelTitle = 'green'
      } else if (color === '#CDDC39') {
        panelTitle = 'lime'
      } else if (color === '#FFEB3B') {
        panelTitle = 'yellow'
      } else if (color === '#FFC107') {
        panelTitle = 'amber'
      } else if (color === '#FF9800') {
        panelTitle = 'orange'
      } else if (color === '#FF5722') {
        panelTitle = 'deep-orange'
      } else if (color === '#795548') {
        panelTitle = 'brown'
      } else if (color === '#607D8B') {
        panelTitle = 'blue-grey'
      } else {
        panelTitle = color
        $('<div>', { class: 'color-box color', style: 'background-color:' + color + '!important;' }).appendTo(selectDiv)
        $('<span>', { html: panelTitle }).appendTo(selectDiv)
        return
      }
      $('<div>', { class: 'color-box color ' + panelTitle }).appendTo(selectDiv)
      $('<span>', { html: panelTitle }).appendTo(selectDiv)
    }

    const resetData = () => {
      page.isEdit = null
      droneData.name = null
      droneData.id = null
      droneData.fcType = 'ardupilot'
      droneData.color = '#E91E63'
      droneData.cell = 6
      droneData.sysId = null
    }

    const fnCallBackCancel = async () => {
      await dispatch('layout/closeModalAlertComponent', 1)
    }
    const fnCallBackDone = async event => {
      await dispatch('layout/closeModalAlertComponent', 1)
      // props.callBackDone(proxy, event)
    }
    //const callBack = async () => {}
    //const fnAddNewColor = async () => {
    //  if (state.tab) await modalPopUp(modalAlertNames.NEWCOLOR, () => callBack())
    //  state.tab = false
    //}

    let callBackFn = function(e) {
      if (!state.tab && !state.typeTab) {
        if (e.target.className == 'select-common-tab') {
          state.typeTab = true
        } else if (e.target.className == 'select-color-tab') {
          state.tab = true
        }
      } else {
        state.tab = false
        state.typeTab = false
        document.removeEventListener('click', callBackFn)
      }
    }
    const fnOpenColorTab = () => {
      document.addEventListener('click', callBackFn)
    }
    const fnOpenTypeTab = () => {
      document.addEventListener('click', callBackFn)
    }

    const fnSelectColor = color => {
      if (color === 'red') {
        droneData.color = '#F44336'
      } else if (color === 'pink') {
        droneData.color = '#E91E63'
      } else if (color === 'deep-purple') {
        droneData.color = '#673AB7'
      } else if (color === 'purple') {
        droneData.color = '#9C27B0'
      } else if (color === 'indigo') {
        droneData.color = '#3F51B5'
      } else if (color === 'blue') {
        droneData.color = '#2196F3'
      } else if (color === 'light-blue') {
        droneData.color = '#03A9F4'
      } else if (color === 'teal') {
        droneData.color = '#009688'
      } else if (color === 'green') {
        droneData.color = '#4CAF50'
      } else if (color === 'lime') {
        droneData.color = '#CDDC39'
      } else if (color === 'yellow') {
        droneData.color = '#FFEB3B'
      } else if (color === 'amber') {
        droneData.color = '#FFC107'
      } else if (color === 'orange') {
        droneData.color = '#FF9800'
      } else if (color === 'deep-orange') {
        droneData.color = '#FF5722'
      } else if (color === 'brown') {
        droneData.color = '#795548'
      } else if (color === 'blue-grey') {
        droneData.color = '#607D8B'
      }
      const selectDiv = $('.select-color-tab')
      selectDiv.empty()
      $('<div>', { class: 'color-box color ' + color }).appendTo(selectDiv)
      $('<span>', { html: color }).appendTo(selectDiv)
    }

    const fnSelectType = type => {
      droneData.fcType = type
      const selectDiv = $('.select-common-tab')
      selectDiv.empty()
      $('<span>', { html: type }).appendTo(selectDiv)
    }

    const createDrone = async () => {
      try {
        if (!fnValidDroneData(droneData)) {
          return
        }
        if (!page.isEdit) {
          await proxy.$DroneUtils.createDrone(droneData)
          await dispatch('layout/closeModalAlertComponent', 1)
          await fnModalAlert('알람', '드론이 생성되었습니다.', '')
        } else {
          const data = {
            action: 'update',
            info: {
              ae: props.param.ae,
              name: props.param.name,
              id: props.param.id
            },
            value: droneData
          }

          const result = await proxy.$DroneUtils.updateDrone(data)
          if (result.status === 200) {
            await dispatch('layout/closeModalAlertComponent', 1)
            await fnModalAlert('알람', '드론이 수정되었습니다.', '')
          } else {
            await fnModalAlert('알람', '업데이트 오류', '')
          }
        }
      } catch (error) {
        if (!fnValidDroneData(droneData)) {
          return
        }
        // if (error && error.response.status === 409) {
        //   await fnBaseConfirm('알람', '이미 동일한 무인기가 존재합니다. 계속 진행하시겠습니까?', '', async () => {
        //     droneData.forceCreate = true
        //     await proxy.$DroneUtils.createDrone(droneData)
        //     await dispatch('layout/closeModalAlertComponent', 1)
        //     await fnModalAlert('알람', '드론이 생성되었습니다.', '')
        //     const droneList = await dispatch('drone/getDroneList')
        //     await dispatch('drone/setDroneList', droneList)
        //     return
        //   })
        // }
      }

      const droneList = await dispatch('drone/getDroneList')
      await dispatch('drone/setDroneList', droneList)
    }

    const valid = reactive({
      nameValid: false,
      idValid: false,
      cellValid: false,
      sysidValid: false,
      colorValid: false,
      nameValidText: '',
      idValidText: '',
      cellValidText: '',
      sysidValidText: '',
      colorValidText: ''
    })

    const fnValidDroneData = droneData => {
      // eslint-disable-next-line no-useless-escape
      let passName = /^[a-zA-Z0-9\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\" ]*$/
      let passNumber = /^[1-255]{1,3}$/
      if (page.isEdit) {
        for (let i = 0; i < droneData.droneList.length; i++) {
          if (droneData.droneList[i].name === props.param.name) {
            droneData.droneList.splice(i, 1)
            break
          }
        }
      }

      if (!droneData.name) {
        valid.nameValid = true
        valid.nameValidText = '드론 이름을 입력해주세요.'
      } else if (droneData.name.length > 21) {
        valid.nameValid = true
        valid.nameValidText = '드론 이름은 20자 이내로 입력해주세요.'
      } else if (!passName.test(droneData.name)) {
        valid.nameValid = true
        valid.nameValidText = '드론 이름이 유효하지 않습니다.'
      } else {
        for (let drone of droneData.droneList) {
          if (drone.name == droneData.name) {
            valid.nameValid = true
            valid.nameValidText = '해당 드론 이름은 이미 존재합니다.'
            return false
          }
        }
        valid.nameValid = false
      }

      if (!droneData.id) {
        valid.idValid = true
        valid.idValidText = '드론 아이디를 입력해주세요.'
      } else if (droneData.id.length > 21) {
        valid.idValid = true
        valid.idValidText = '드론 아이디는 20자 이내로 입력해주세요.'
      } else if (!passName.test(droneData.id)) {
        valid.idValid = true
        valid.idValidText = '드론 아이디가 유효하지 않습니다.'
      } else {
        for (let drone of droneData.droneList) {
          if (drone.id == droneData.id) {
            valid.idValid = true
            valid.idValidText = '해당 모비우스 아이디는 이미 존재합니다.'
            return false
          }
        }
        valid.idValid = false
      }

      if (!droneData.cell) {
        valid.cellValid = true
        valid.cellValidText = '배터리 셀 수를 입력해주세요.'
      } else if (droneData.cell < 0 && droneData.cell > 256) {
        valid.cellValid = true
        valid.cellValidText = '배터리 셀 수가 유효하지 않습니다.'
      } else {

      }
      valid.cellValid = false

      if (!droneData.sysId) {
        valid.sysidValid = true
        valid.sysidValidText = '시스템 아이디를 입력해주세요.'
      } else if (droneData.sysId < 0 && droneData.sysId > 256) {
        valid.sysidValid = true
        valid.sysidValidText = '시스템 아이디가 유효하지 않습니다.'
      } else {
        for (let drone of droneData.droneList) {
          if (drone.sysId == droneData.sysId) {
            valid.sysidValid = true
            valid.sysidValidText = '해당 시스템 ID는 이미 존재합니다.'
            return false
          }
        }
        valid.sysidValid = false
      }

      //중복된 색상 선택 불가
      for (let i = 0; i < droneData.droneList.length; i++) {
        if (droneData.droneList[i].color == droneData.color) {
          valid.colorValid = true
          valid.colorValidText = '동일한 색상이 사용 중입니다. 다른 색상을 선택해주세요.'
          break
        }
        valid.colorValid = false
      }
      if (!valid.nameValid && !valid.idValid && !valid.cellValid && !valid.sysidValid && !valid.colorValid) return true
      else return false
    }

    return {
      ...toRefs(droneData),
      ...toRefs(page),
      ...toRefs(state),
      ...toRefs(valid),
      fnCallBackCancel,
      fnCallBackDone,
      //fnAddNewColor,
      fnOpenColorTab,
      fnOpenTypeTab,
      fnSelectColor,
      fnSelectType,
      createDrone,
      resetData
    }
  }
}
</script>

<style scoped></style>
