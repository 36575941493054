<template>
  <div class="modal-dim on">
    <!--'on 추가'-->
    <div class="set-window-wrap">
      <div class="set-window small">
        <div class="set-window-top">
          <div class="set-window-sub">
            <div class="sub">
              <i class="i-user"></i>
              <h2>User</h2>
            </div>
            <div class="close" @click="fnCallBackCancel">
              <i class="i-close-26"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con">
          <div class="set-sub">
            <button class="i-back" @click="fnBack"></button>
            <h3>회원정보 수정</h3>
          </div>
          <div class="sect pt0 scroll-y">
            <p class="input-info">*는 필수 입력 항목입니다.</p>
            <div class="form column">
              <p class="form-sub">이메일*</p>
              <label class="label">
                <input type="email" placeholder="E-mail 주소 입력" v-model="form.email" disabled />
              </label>
            </div>
            <div class="form column">
              <p class="form-sub">이름*</p>
              <label class="label">
                <input type="text" placeholder="영문 or 한글 입력" v-model="form.name" />
              </label>
              <p class="valid on" v-if="valid.name.$error">
                {{ msg.name }}
              </p>
            </div>
            <div class="form column">
              <p class="form-sub">변경 비밀번호*</p>
              <label class="label">
                <input type="password" placeholder="6자리 이상 영문+숫자" v-model="form.password" />
              </label>
              <p class="valid on" v-if="valid.password.$error">
                {{ msg.password }}
              </p>
            </div>
            <!-- <div class="form column">
              <p class="form-sub">변경 Password*</p>
              <label class="label">
                <input type="password" placeholder="6자리 이상 영문+숫자" v-model="form.confirmPw" />
              </label>
              <p class="valid on" v-if="valid.confirmPw.$error">
                {{ msg.confirmPw }}
              </p>
            </div> -->
            <!-- <div class="form column">
              <p class="form-sub">User Type* <span>1개 선택 필수, 중복 선택 가능</span></p>
              <div class="checkbox-area">
                <label class="checkbox">
                  <input type="checkbox" @click="fnUserType('설계')" :checked="form.type === '설계'" />
                  <span class="label">설계</span>
                </label>
                <label class="checkbox">
                  <input type="checkbox" @click="fnUserType('운용')" :checked="form.type === '운용'" />
                  <span class="label">운용</span>
                </label>
                <label class="checkbox">
                  <input type="checkbox" @click="fnUserType('미정')" :checked="form.type === '미정'" />
                  <span class="label">미정</span>
                </label>
              </div>
              <p class="valid on" v-if="valid.type.$error">
                {{ msg.type }}
              </p>
            </div> -->
            <div
              class="form column"
              v-if="
                Object.values(form.type)
                  .filter(i => i === '운용')
                  .includes('운용')
              "
            >
              <p class="form-sub">Licence Name*</p>
              <label class="label">
                <input type="text" placeholder="자격증 명칭 & 한정사항 입력" v-model="form.licenseName" />
              </label>
              <p class="valid on" v-if="valid.licenseName.$error">
                {{ msg.licenseName }}
              </p>
            </div>
            <div
              class="form column"
              v-if="
                Object.values(form.type)
                  .filter(i => i === '운용')
                  .includes('운용')
              "
            >
              <p class="form-sub">Licence Number*</p>
              <label class="label">
                <input type="number" placeholder="xx - xxxxxx" v-model="form.licenseNo" />
              </label>
              <p class="valid on" v-if="valid.licenseNo.$error">
                {{ msg.licenseNo }}
              </p>
            </div>
            <div
              class="form column"
              v-if="
                Object.values(form.type)
                  .filter(i => i === '운용')
                  .includes('운용')
              "
            >
              <p class="form-sub">H.P*</p>
              <label class="label">
                <input type="number" placeholder="000-0000-0000" v-model="form.phoneNumber" />
              </label>
              <p class="valid on" v-if="valid.phoneNumber.$error">
                {{ msg.phoneNumber }}
              </p>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <!--          <p class="valid">유효성 확인 문구</p>-->
          <button class="btn color" style="width: 100%;" @click.prevent.stop="fnConfirm">
            회원 정보 저장
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, onMounted } from 'vue'
import useVuelidate from '@vuelidate/core'
import { modalAlertNames } from '@/components/common/globalComponent'
import { useModalState } from '@/common/state/ModalState'
import { useStore } from 'vuex'

export default {
  name: 'user-step2',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object]
    },
    param: {
      type: [Object]
    },
    doneText: {
      type: [String],
      default: 'CONFIRM'
    },
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { modalPopUp, fnModalAlert } = useModalState()
    const { dispatch } = useStore()

    const state = reactive({
      form: {
        email: '',
        name: '',
        password: '',
        type: {},
        licenseName: '',
        licenseNo: '',
        phoneNumber: ''
      },
      msg: {
        name: '',
        password: '',
        type: '',
        licenseName: '',
        licenseNo: '',
        phoneNumber: ''
      }
    })
    const rules = {
      name: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.name = '성함을 입력하지 않았습니다. 입력 후, 다시 시도해주세요.'
            return false
          }
          if (!proxy.$Util.isNameValid(val)) {
            state.msg.name = '한글 또는 영문으로 입력해주세요.'
            return false
          }
          return true
        }
      },
      password: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            state.msg.password = '비밀번호를 입력하지 않았습니다. 입력 후, 다시 시도해주세요.'
            return false
          }
          if (!proxy.$Util.isPasswordValid(val)) {
            state.msg.password = '6자리 이상 [영문+숫자]로 비밀번호를 입력하지 않았습니다.  입력 후, 다시 시도해주세요.'
            return false
          }
          return true
        }
      },
      // confirmPw: {
      //   test: function(val) {
      //     if (proxy.$Util.isEmpty(val)) {
      //       state.msg.confirmPw = '비밀번호가 동일하지 않습니다.  입력 후, 다시 시도해주세요.'
      //       return false
      //     }
      //     if (state.form.password !== val) {
      //       state.msg.confirmPw = '입력하신 비밀번호와 동일한 비밀번호를 입력해주세요.'
      //       return false
      //     }
      //     return true
      //   }
      // },
      // type: {
      //   test: function(val) {
      //     if (Object.keys(val).length === 0) {
      //       state.msg.type = '한개 이상 골라주세요'
      //       return false
      //     }
      //     return true
      //   }
      // },
      licenseName: {
        test: function(val) {
          if (
            Object.values(state.form.type)
              .filter(i => i === '운용')
              .includes('운용') &&
            proxy.$Util.isEmpty(val)
          ) {
            state.msg.licenseName = '라이센스 명칭을 입력해주세요. '
            return false
          }
          return true
        }
      },
      licenseNo: {
        test: function(val) {
          if (
            Object.values(state.form.type)
              .filter(i => i === '운용')
              .includes('운용') &&
            proxy.$Util.isEmpty(val)
          ) {
            state.msg.licenseNo = '라이센스 번호를 입력해주세요. '
            return false
          }
          return true
        }
      },
      phoneNumber: {
        test: function(val) {
          if (
            Object.values(state.form.type)
              .filter(i => i === '운용')
              .includes('운용') &&
            proxy.$Util.isEmpty(val)
          ) {
            state.msg.phoneNumber = '휴대폰 번호를 입력해주세요.'
            return false
          }
          return true
        }
      }
    }
    const valid = useVuelidate(rules, state.form)

    onMounted(() => ((state.form.email = props.param.userEmail), (state.form.name = props.param.userName)))

    const fnConfirm = async event => {
      valid.value.$touch()
      if (valid.value.$invalid) return
      // 아이디 동일한지 확인 API
      await proxy.$AuthUtils.modifyUserInfo(state.form)
      props.callBackDone(proxy, event)
      await fnModalAlert('알람', '회원 정보 수정을 완료했습니다.', '')
    }

    const fnCallBackCancel = event => {
      props.callBackCancel(proxy, event)
    }
    const fnBack = async () => {
      const callBack = async param => {
        await modalPopUp(modalAlertNames.USERSTEP2, () => {}, { data: param.form })
        // proxy.$emit('close')
      }
      await dispatch('layout/closeModalAlertComponent')
      await modalPopUp(modalAlertNames.USERSTEP1, param => callBack(param))
    }

    // 설계, 운용을 위한 function
    // const fnUserType = param => {
    //   // if(state.form.type[param]=== param)
    //   if (state.form.type.hasOwnProperty(param)) {
    //     state.form.type = Object.values(state.form.type)
    //       .filter(i => i !== param)
    //       .reduce((a, v) => ({ ...a, [v]: v }), {})
    //   } else {
    //     state.form.type = { ...state.form.type, [param]: param }
    //   }
    // }

    return {
      ...toRefs(state),
      fnConfirm,
      fnCallBackCancel,
      fnBack,
      // fnUserType,
      valid
    }
  }
}
</script>

<style scoped></style>
