import DroneUtils from '@/services/drone/Drone'

export const state = {
  isDistanceMonitor: false,
  ctrlDroneList: null,
  droneList: [],
  droneParams: [],
  missionDroneList: [],
  globalAlt: 10,
  globalSpeed: 5,
  imageList: []
}
export const getters = {
  getIsDistanceMonitor(state) {
    return state.isDistanceMonitor
  },
  getDroneList(state) {
    return state.droneList
  },
  getCtrlDroneList(state) {
    return state.ctrlDroneList
  },
  getMissionDroneList(state) {
    return state.missionDroneList
  },
  getGlobalAlt(state) {
    return state.globalAlt
  },
  getGlobalSpeed(state) {
    return state.globalSpeed
  },
  getParams(state) {
    return state.droneParams
  },
  getImageList(state) {
    return state.imageList
  }
}
export const mutations = {
  setIsDistanceMonitor(state, isDistanceMonitor) {
    console.log(state.isDistanceMonitor)
    state.isDistanceMonitor = isDistanceMonitor
  },
  setCtrlDrones(state, drones) {
    state.ctrlDroneList = drones
  },
  setDrones(state, drones) {
    state.droneList = drones
  },
  setParams(state, params) {
    let existingObjIndex = state.droneParams.findIndex(droneParam => droneParam.droneName === params.droneName)

    if (existingObjIndex !== -1) {
      state.droneParams[existingObjIndex].parameters = params.parameters
    } else {
      state.droneParams.push(params)
    }
  },
  setMissionDroneList(state, drones) {
    // drones.push({ name: 'test_fc', color: 'green' })
    state.missionDroneList = drones
  },
  setGlobalAlt(state, alt) {
    state.globalAlt = alt
  },
  setGlobalSpeed(state, speed) {
    state.globalSpeed = speed
  },
  setImageList(state, drone) {
    if (state.imageList.indexOf(drone) < 0) {
      if (state.imageList.length > 3) {
        alert('더 이상 이미지 화면을 추가할 수 없습니다. \n열려 있는 화면을 종료 후, 추가해주세요.')
      } else {
        state.imageList.push(drone)
      }
    } else {
      state.imageList = state.imageList.filter(e => e !== drone)
    }
  }
}

// 가져오기, set 할때 등록이면 푸쉬 delete면 삭제
export const actions = {
  setIsDistanceMonitor({ commit }, isDistanceMonitor) {
    commit('setIsDistanceMonitor', isDistanceMonitor)
  },
  setCtrlDrones({ commit }, ctrlDrones) {
    commit('setCtrlDrones', ctrlDrones)
    return ctrlDrones
  },
  async getDroneList({ commit }) {
    const isConnected = localStorage.getItem('mobius_connected')

    if (isConnected === 'connected') {
      const droneList = await DroneUtils.getDrones()
      return droneList
    } else {
      commit('setDrones', null)
    }
  },
  setMissionDrone({ commit }, drone) {
    // console.log(drone)
    commit('setMissionDroneList', drone)
  },
  setCtrlDroneList({ commit }, drones) {
    commit('setCtrlDrones', drones)
  },
  setDroneList({ commit }, drones) {
    commit('setDrones', drones)
  },
  setDroneParams({ commit }, params) {
    commit('setParams', params)
  },
  globalAlt({ commit }, alt) {
    commit('setGlobalAlt', alt)
  },
  globalSpeed({ commit }, speed) {
    commit('setGlobalSpeed', speed)
  },
  setImageList({ commit }, drone) {
    commit('setImageList', drone)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
