/** 모달 ALERT **/
import BASE from '@/components/common/modal/base/index'
import CONFIRM from '@/components/common/modal/confirm/index'
import CONFIRM_BASE from '@/components/common/modal/baseConfirm/index'
import DRONELIST from '@/components/common/modal/drone/list'
import DRONEADD from '@/components/common/modal/drone/add'
import DRONEMODIFY from '@/components/common/modal/drone/modify'
import CREATE from '@/components/common/modal/created/index'
import MARKER from '@/components/common/modal/created/marker'
import GEOFENCE from '@/components/common/modal/created/geofence'
import PARAMETER from '@/components/common/modal/drone/parameter'
import USERSTEP1 from '@/components/common/modal/user/step1'
import USERSTEP2 from '@/components/common/modal/user/step2'
import OPTIONS from '@/components/common/modal/options'
import NEWCOLOR from '@/components/common/modal/drone/color'
import MODE from '@/components/views/mode'
import SURVEY from '@/components/common/modal/created/survey'
import DRONELOGS from '@/components/common/modal/drone/logs'
import EXTRA from '@/components/common/modal/extra'
import LXLOGIN from '@/components/common/modal/lx/login.vue'

/** 전체화면 **/

export const modalAlertNames = {
  BASE,
  CONFIRM,
  CONFIRM_BASE,
  DRONELIST,
  OPTIONS,
  PARAMETER,
  USERSTEP1,
  USERSTEP2,
  DRONEADD,
  DRONEMODIFY,
  CREATE,
  MARKER,
  NEWCOLOR,
  SURVEY,
  DRONELOGS,
  // 모드
  MODE,
  // 테스트
  EXTRA,
  GEOFENCE,
  LXLOGIN
}

export const modalNames = {}
