import TokenUtils from '@/services/auth/Token'
import ConfigUtil from '@/services/common/Config'
import axios from 'axios'

const MODE = [
  { name: 'STABILIZE', mainMode: 0, settable: true },
  { name: 'ACRO', mainMode: 1, settable: true },
  { name: 'ALT_HOLD', mainMode: 2, settable: true },
  { name: 'AUTO', mainMode: 3, settable: true },
  { name: 'GUIDED', mainMode: 4, settable: true },
  { name: 'LOITER', mainMode: 5, settable: true },
  { name: 'RTL', mainMode: 6, settable: true },
  { name: 'CIRCLE', mainMode: 7, settable: true },
  { name: 'POSITION', mainMode: 8, settable: true },
  { name: 'LAND', mainMode: 9, settable: true },
  { name: 'OF_LOITER', mainMode: 10, settable: true },
  { name: 'DRIFT', mainMode: 11, settable: true },
  { name: 'RESERVED_12', mainMode: 12, settable: true },
  { name: 'SPORT', mainMode: 13, settable: true },
  { name: 'FLIP', mainMode: 14, settable: true },
  { name: 'AUTOTUNE', mainMode: 15, settable: true },
  { name: 'POS_HOLD', mainMode: 16, settable: true },
  { name: 'BRAKE', mainMode: 17, settable: true },
  { name: 'THROW', mainMode: 18, settable: true },
  { name: 'AVOID_ADSB', mainMode: 19, settable: true },
  { name: 'GUIDED_NOGPS', mainMode: 20, settable: true },
  { name: 'SAFE_RTL', mainMode: 21, settable: true }
]

const API_URL = ConfigUtil.getServiceUrl()

let map = new Map()
function droneList(drone, params) {
  const droneList = [
    {
      info: {
        name: drone.name,
        ae: drone.ae,
        id: drone.id,
        type: drone.autopilot
      },
      sysId: drone.sysId
    }
  ]
  return droneList
}

function setAutoMissionItem(wpData) {
  const missionItem = [
    {
      command: 16,
      radius: 0,
      turns: 0,
      heading: 0,
      lat: 0,
      lng: 0,
      z: 0
    }
  ]

  wpData.forEach((param, index) => {
    let command = 16
    let hold = param.hold ? param.hold : 0

    if (param.roi) command = 201

    missionItem.push({
      command: command,
      hold: hold,
      radius: 0,
      turns: 0,
      heading: 0,
      lat: param.lat,
      lng: param.lng,
      z: param.z
    })
    missionItem.push({
      command: 178,
      param1: 0,
      param2: param.spd ? param.spd : 3,
      param3: 0,
      param4: 0,
      param5: 0,
      param6: 0,
      param7: 0
    })
  })

  return missionItem
}

function setPatternMissionItem(wpData, delay) {
  const missionItem = [
    {
      command: 16,
      radius: 0,
      turns: 0,
      heading: 0,
      lat: 0,
      lng: 0,
      z: 0
    }
  ]

  wpData.forEach((param, index) => {
    let command = 16
    let hold = 0

    if (index === 0) hold = delay

    missionItem.push({
      command: command,
      hold: hold,
      radius: 0,
      turns: 0,
      heading: 0,
      lat: param.lat,
      lng: param.lng,
      z: param.z
    })
  })

  return missionItem
}

class MavlinkUtils {
  makeQueue(commands, name) {
    const queueFunc = commands.function
    const queueParam = commands.parameter
    map.set(name, {
      queueFunc: queueFunc,
      queueParam: queueParam
    })
  }

  // nextQueueExecution(droneName) {
  //   const tempMap = map.get(droneName)
  //   if (!tempMap) return
  //   const queueFunc = tempMap.queueFunc
  //   const queueParam = tempMap.queueParam

  //   if (queueFunc == null || queueParam == null) {
  //     map.delete(droneName)
  //     return
  //   }
  //   if (queueFunc.length == 0 || queueParam.length == 0) {
  //     map.delete(droneName)
  //     return
  //   } else {
  //     const param = queueParam.shift()
  //     if (param.length == 0) {
  //       queueFunc.shift()()
  //     } else if (param.length == 1) {
  //       queueFunc.shift()(param[0])
  //     } else if (param.length == 2) {
  //       queueFunc.shift()(param[0], param[1])
  //     } else if (param.length == 3) {
  //       queueFunc.shift()(param[0], param[1], param[2])
  //     }
  //   }
  // }

  nextQueueExecution(droneName) {
    const drone = map.get(droneName)
    if (!drone) return

    const { queueFunc, queueParam } = drone
    if (!queueFunc.length || !queueParam.length) {
      map.delete(droneName)
      return
    }

    const [func, params] = [queueFunc.shift(), queueParam.shift()]
    if (func && params) {
      func(...params)
    }

    if (queueFunc.length && queueParam.length) {
      map.set(droneName, { queueFunc, queueParam })
    } else {
      map.delete(droneName)
    }
  }

  async setMode(drone, modeVal) {
    try {
      const modeName = modeVal.toUpperCase()
      const ArdupilotMode = MODE.find(function(mode) {
        return mode.name === modeName
      })
      ArdupilotMode.type = drone.data.autopilot
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'PUT',
        url: API_URL + './drone/flightMode',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.data.autopilot
          },
          name: drone.name,
          sysId: drone.sysId,
          value: ArdupilotMode
        }
      })

      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setArm(drone) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/ARM',
        headers: { gcsAcToken: token },
        data: {
          droneList: droneList(drone)
        }
      })

      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setDisArm(drone) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/DISARM',
        headers: { gcsAcToken: token },
        data: {
          droneList: droneList(drone)
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setTakeOff(drone, alt, deleyTime) {
    try {
      const params = {
        param1: 0,
        param2: 0,
        param3: 0,
        param4: 0,
        param5: 0,
        param6: 0,
        param7: alt
      }
      const token = await TokenUtils.getAccessToken()
      setTimeout(async () => {
        const response = await axios({
          method: 'POST',
          url: API_URL + './drone/command/TAKEOFF',
          headers: { gcsAcToken: token },
          data: {
            droneList: [
              {
                info: {
                  name: drone.name,
                  ae: drone.ae,
                  id: drone.id,
                  type: drone.data.autopilot
                },
                sysId: drone.sysId,
                params: params
              }
            ]
          }
        })
        return response.data
      }, deleyTime * 1000)
    } catch (err) {
      throw new Error(err)
    }
  }

  async setMove(drone, info) {
    try {
      let params = {
        param1: 1,
        param2: 3,
        param3: 3,
        param4: 0,
        param5: 0,
        param6: 0,
        param7: info.alt
      }
      let pos = {
        lat: info.lat,
        lng: info.lon
      }
      params.param5 = parseFloat(parseFloat(pos.lat).toFixed(7))
      params.param6 = parseFloat(parseFloat(pos.lng).toFixed(7))
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/GOTO',
        headers: { gcsAcToken: token },
        data: {
          droneList: [
            {
              info: {
                name: drone.name,
                ae: drone.ae,
                id: drone.id,
                type: drone.data.autopilot
              },
              sysId: drone.sysId,
              params: params
            }
          ]
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setLoiterTurn(drone, loiterParams) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/mission/upload',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.data.autopilot
          },
          sysId: drone.sysId,
          type: 0,
          missionItem: {
            data: loiterParams
          }
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setSpeed(drone, speed) {
    try {
      const params = {
        param1: 0,
        param2: speed ? speed : 3,
        param3: 0,
        param4: 0,
        param5: 0,
        param6: 0,
        param7: 0
      }
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/SPEED',
        headers: { gcsAcToken: token },
        data: {
          droneList: [
            {
              info: {
                name: drone.name,
                ae: drone.ae,
                id: drone.id,
                type: drone.data.autopilot
              },
              sysId: drone.sysId,
              params: params
            }
          ]
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setLand(drone) {
    try {
      const params = {
        param1: 0,
        param2: 0,
        param3: 0,
        param4: NaN,
        param5: 0,
        param6: 0,
        param7: 0
      }
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/LAND',
        headers: { gcsAcToken: token },
        data: {
          droneList: [
            {
              info: {
                name: drone.name,
                ae: drone.ae,
                id: drone.id,
                type: drone.data.autopilot
              },
              sysId: drone.sysId,
              params: params
            }
          ]
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setMissionClear(drone) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'PUT',
        url: API_URL + './drone/mission/clear',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.data.autopilot
          },
          sysId: drone.sysId,
          type: 0
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setAutoMission(drone, wpData) {
    try {
      const missionItem = setAutoMissionItem(wpData)

      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/mission/upload',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.data.autopilot,
            pattern: true
          },
          sysId: drone.sysId,
          type: 0,
          missionItem: {
            data: missionItem
          }
        }
      })

      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setPatternMission(drone, wpData, delay) {
    try {
      const missionItem = setPatternMissionItem(wpData, delay)

      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/mission/upload',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.data.autopilot,
            pattern: true
          },
          sysId: drone.sysId,
          type: 0,
          missionItem: {
            data: missionItem
          }
        }
      })

      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setGeoFence(drone, wpData, fence) {
    // 'lat': pos.lat,
    // 'lng': pos.lng,
    // 'missionType': mavlink.MAV_MISSION_TYPE_FENCE,
    // 'polygonCount': fence.data.vertex.length, // fence.position.pos.length
    // 'type': fence.type,
    // 'inclusion': fence.inclusion,
    // 'violationAct': fence.violationAct,
    // 'violationPosition': fence.violationPosition
    try {
      const token = await TokenUtils.getAccessToken()
      const tpData = []
      wpData.forEach(function(param) {
        const tempData = {
          command: 16,
          radius: 0,
          turns: 0,
          heading: 0,
          lat: param.lat,
          lng: param.lng,
          z: 20
        }
        tpData.push(tempData)
      })
      const figureInfo = [
        {
          data: {
            vertex: tpData
          },
          type: fence.type,
          radius: fence.radius,
          inclusion: fence.inclusion,
          violationAct: fence.violationAct,
          violationPosition: fence.violationPosition
        }
      ]
      console.log(figureInfo)
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/mission/upload',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.data.autopilot
          },
          sysId: drone.sysId,
          type: 1,
          missionItem: {
            data: figureInfo
          }
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async startMission(drone) {
    try {
      const token = await TokenUtils.getAccessToken()
      await axios({
        method: 'POST',
        url: API_URL + './drone/command/START',
        headers: { gcsAcToken: token },
        data: {
          droneList: droneList(drone)
        }
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async startPattern(drone, patternLength) {
    try {
      const token = await TokenUtils.getAccessToken()
      await axios({
        method: 'POST',
        url: API_URL + './drone/command/PATTERN',
        headers: { gcsAcToken: token },
        data: {
          droneList: droneList(drone),
          patternLength: patternLength
        }
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async changeDroneParam(drone, changedData) {
    try {
      const token = await TokenUtils.getAccessToken()
      await axios({
        method: 'PUT',
        url: API_URL + './drone/params/update',
        headers: { gcsAcToken: token },
        data: {
          info: {
            name: drone.name,
            ae: drone.ae,
            id: drone.id,
            type: drone.fcType
          },
          name: drone.name,
          sysId: drone.sysId,
          param: changedData
        }
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async loadGoto(target) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'GET',
        url: API_URL + './goto/move',
        headers: { gcsAcToken: token },
        params: {
          drone: target
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async saveGoto(itemList) {
    try {
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './goto/move',
        headers: { gcsAcToken: token },
        data: {
          data: itemList
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async getHome(drone) {
    try {
      const params = {
        param1: 0,
        param2: 0,
        param3: 0,
        param4: 0,
        param5: 0,
        param6: 0,
        param7: 0
      }
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/gethome',
        headers: { gcsAcToken: token },
        data: {
          droneList: [
            {
              info: {
                name: drone.name,
                ae: drone.ae,
                id: drone.id,
                type: drone.data.autopilot
              },
              sysId: drone.sysId,
              params: params
            }
          ]
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }

  async setHome(drone, data) {
    try {
      const params = {
        param1: 0,
        param2: 0,
        param3: 0,
        param4: 0,
        param5: data.lat,
        param6: data.lon,
        param7: 150
      }
      const token = await TokenUtils.getAccessToken()
      const response = await axios({
        method: 'POST',
        url: API_URL + './drone/command/sethome',
        headers: { gcsAcToken: token },
        data: {
          droneList: [
            {
              info: {
                name: drone.name,
                ae: drone.ae,
                id: drone.id,
                type: drone.data.autopilot
              },
              sysId: drone.sysId,
              params: params
            }
          ]
        }
      })
      return response.data
    } catch (error) {
      throw new Error(error)
    }
  }
}

export default new MavlinkUtils()
