<template>
  <div class="order-component bg1" style="width: 66rem; z-index: 100">
    <div class="header">
      <div class="header-inner">
        <p class="ttl">패턴 수정</p>
      </div>
      <i class="i-close-20" @click="fnCallBack"></i>
    </div>

    <div class="order-component-inner" style="padding-bottom:1rem; max-height:640px; overflow-y:auto;">
      <div class="form row">
        <p class="form-sub">드론 목록</p>
        <label class="label">
          <div class="sel-wrapper">
            <div class="sel-tag" style="width: 51rem!important; height: 36px">
              <button class="tag-item" v-for="pattern of patternDrones" :key="pattern" style="height: 3rem; margin-left: 0rem!important">
                <span>{{ pattern.name }}</span> &nbsp;<span @click="deletePatternDrones(pattern)"> X</span>
              </button>
            </div>
          </div>
        </label>
      </div>
      <div class="form row">
        <p class="form-sub">드론</p>
        <label class="label">
          <select @change="addPatternDrones($event)">
            <option value="dummy">드론을 선택해주세요.</option>
            <option v-for="item of ctrlDroneList" :key="item" :value="JSON.stringify(item)">{{ item.name }}</option>
          </select>
        </label>
      </div>
      <div class="form row">
        <p class="form-sub">패턴 이름</p>
        <label class="label">
          <input type="text" v-model="patternName" @blur="setShootingParam()" />
        </label>
      </div>
      <div>
        <div style="width: 48%; float: left; margin-right: 10px">
          <!-- <div class="form row">
            <p class="form-sub">배터리</p>
            <label class="label">
              <p class="colorWhite">30%</p>
            </label>
          </div> -->
          <div class="form row">
            <p class="form-sub">면적</p>
            <label class="label">
              <p class="colorWhite">{{ patternArea }}</p>
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">길이</p>
            <label class="label">
              <p class="colorWhite">{{ patternPerimeter }}</p>
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">간격</p>
            <label class="label">
              <p class="colorWhite">{{ gap }}</p>
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">각도</p>
            <label class="label">
              <input type="number" v-model="patternAngle" @blur="setShootingParam()" />
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">순항고도</p>
            <label class="label">
              <input type="text" v-model="patternAlt" @blur="setShootingParam()" />
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">순항속도</p>
            <label class="label">
              <input type="number" v-model="patternSpeed" @blur="setShootingParam()" />
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">버전</p>
            <label class="label">
              <select v-model="version" @blur="setShootingParam()">
                <option :value="'new'">New</option>
                <option :value="'old'">Old</option>
              </select>
            </label>
          </div>
        </div>
        <div style="width: 48%; float: right">
          <div class="form row">
            <p class="form-sub">촬영주기</p>
            <label class="label">
              <p class="colorWhite">{{ shootPeriod }}</p>
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">주행거리</p>
            <label class="label">
              <p class="colorWhite" v-if="drivingDistance > 1">{{ drivingDistance.toFixed(1) }}km</p>
              <p class="colorWhite" v-if="drivingDistance < 1">{{ (drivingDistance * 1000).toFixed(1) }}m</p>
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">비행시간</p>
            <label class="label">
              <p class="colorWhite">{{ estimateTime }}</p>
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">중복도</p>
            <label class="label">
              <input type="text" v-model="multiplicity" @blur="setShootingParam()" />
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">초점거리</p>
            <label class="label">
              <input type="text" v-model="focalLength" @blur="setShootingParam()" />
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">가로 센서</p>
            <label class="label">
              <input type="text" v-model="Wsensor" @blur="setShootingParam()" />
            </label>
          </div>
          <div class="form row">
            <p class="form-sub">세로 센서</p>
            <label class="label">
              <input type="text" v-model="Hsensor" @blur="setShootingParam()" />
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-wrap" style="padding-top:1.5rem">
      <button class="btn gray" @click="fnCallBack">닫기</button>
      <button class="btn color" style="margin-right:0" @click="updatePattern(true)">수정</button>
      <i class="i-set-delete" style="margin-left: 30px;" @click="deletePattern()"></i>
    </div>
  </div>
</template>

<script>
import { useModalState } from '@/common/state/ModalState'
import { getCurrentInstance, reactive, toRefs, watch, computed, onMounted } from 'vue'
import { MapBrowserEvent } from 'ol'
import { Select, Modify } from 'ol/interaction'
import { SelectEvent } from 'ol/interaction/Select'
import { useStore } from 'vuex'

export default {
  name: 'create-survey',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    mainState: {},
    layers: {},
    patternData: {},
    callBackCancel: {
      type: [Function],
      default: () => {}
    },
    resetPattern: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert } = useModalState()
    const { dispatch } = useStore()
    const state = reactive({
      markerType: 0,
      ctrlDroneList: props.mainState.ctrlDroneList,

      /*
       * 값 0인 변수들 Ol에서 추가하기
       */
      patternId: props.patternData._id,
      patternDrones: props.patternData.droneList,
      patternAlt: props.patternData.alt,
      patternSpeed: props.patternData.speed,
      patternAngle: props.patternData.angle,
      patternName: props.patternData.patternName,
      gap: props.patternData.gap,
      coordinates: [props.patternData.coordinates[1], props.patternData.coordinates[0]],
      focalLength: props.patternData.focalLength,
      Hsensor: props.patternData.Hsensor,
      Wsensor: props.patternData.Wsensor,
      multiplicity: props.patternData.multiplicity,
      lonLatArr: props.patternData.lonLatArr,
      // observation data
      drivingDistance: props.patternData.length,
      pathList: props.patternData.path,
      patternArea: props.patternData.area,
      patternPerimeter: props.patternData.perimeter,
      version: props.patternData.version,
      estimateTime: 0,
      shootPeriod: 0
    })
    onMounted(() => {
      initCalculate()
      const selectEvent = new SelectEvent()
      const selectFeature = props.layers.patternLayer
        .getSource()
        .getFeatures()
        .find(feature => {
          return feature.get('patternId') == props.patternData._id
        })
      props.mainState.map.getInteractions().forEach(function(interaction) {
        if (interaction instanceof Select) {
          interaction.getFeatures().remove(selectFeature)
          selectEvent.type = 'select'
          selectEvent.selected = []
          selectEvent.deselected = [selectFeature]
          selectEvent.mapBrowserEvent = new MapBrowserEvent()
          interaction.dispatchEvent(selectEvent)
        }
      })
    })

    const addPatternDrones = e => {
      const value = JSON.parse(e.target.value)
      if (value !== 'dummy') {
        if (state.patternDrones.length == 0) {
          state.patternDrones.push(value)
        } else if (!state.patternDrones.find(item => item.name == value.name)) {
          state.patternDrones.push(value)
        }
        setShootingParam()
      } else {
        return
      }
    }

    const deletePatternDrones = drone => {
      state.patternDrones = state.patternDrones.filter(item => item.name !== drone.name)
      setShootingParam()
    }

    const setShootingParam = () => {
      let period = (state.Hsensor * state.patternAlt * ((100 - state.multiplicity) / 100)) / (state.focalLength * state.patternSpeed)
      let patternSpace = period * state.patternSpeed
      state.gap = parseInt(patternSpace * (state.Wsensor / state.Hsensor))
      state.shootPeriod = parseInt(period)
      updatePattern(false)
    }

    const initCalculate = () => {
      const seconds = (((state.drivingDistance * 1000) / state.patternSpeed + state.pathList.length * 5) / state.patternDrones.length).toFixed(0)
      state.estimateTime = getTime(seconds)
      let period = (state.Hsensor * state.patternAlt * ((100 - state.multiplicity) / 100)) / (state.focalLength * state.patternSpeed)
      state.shootPeriod = parseInt(period)
    }

    const updatePattern = async isCreate => {
      if (state.patternDrones.length == 0) {
        await fnModalAlert('에러', '드론을 선택해주세요.', '')
        return
      }
      const patternData = {
        _id: state.patternId,
        patternName: state.patternName,
        droneList: state.patternDrones,
        angle: state.patternAngle,
        gap: state.gap,
        coordinates: state.coordinates,
        focalLength: state.focalLength,
        Hsensor: state.Hsensor,
        Wsensor: state.Wsensor,
        multiplicity: state.multiplicity,
        alt: state.patternAlt,
        speed: state.patternSpeed,
        lonLatArr: state.lonLatArr,
        shootPeriod: state.shootPeriod,
        version: state.version
      }
      await proxy.$OlUtils.makeDefaultPattern(props.mainState, props.layers, patternData, true)
      state.drivingDistance = patternData.length
      const seconds = (((patternData.length * 1000) / state.patternSpeed + patternData.path.length * 5) / state.patternDrones.length).toFixed(0)
      state.estimateTime = getTime(seconds)
      if (isCreate) {
        await proxy.$PatternUtils.updatePattern(patternData)
        //! emit 사용요망
        // eslint-disable-next-line vue/no-mutating-props
        props.mainState.editPattern = false
        props.resetPattern()
        proxy.$emit('close', props.index)
      }
    }

    const deletePattern = async () => {
      try {
        await proxy.$PatternUtils.deletePattern(state.patternId)
        //! emit 사용요망
        // eslint-disable-next-line vue/no-mutating-props
        props.mainState.editPattern = false
        props.resetPattern()
        proxy.$emit('close', props.index)
        await fnModalAlert('삭제', '패턴이 삭제되었습니다.', '')
      } catch (error) {
        console.log('error', error)
      }
    }

    const fnCallBack = event => {
      //! emit 사용요망
      // eslint-disable-next-line vue/no-mutating-props
      props.mainState.editPattern = false
      props.resetPattern()
      proxy.$emit('close', props.index)
    }

    const getTime = seconds => {
      var hour = parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600)
      var min = parseInt((seconds % 3600) / 60) < 10 ? '0' + parseInt((seconds % 3600) / 60) : parseInt((seconds % 3600) / 60)
      var sec = seconds % 60 < 10 ? '0' + (seconds % 60) : seconds % 60
      if (hour >= 1) return `${hour}시 ${min}분 ${sec}초`
      else if (min >= 1) return `${min}분 ${sec}초`
      else if (sec > 0) return `${sec}초`
    }

    return {
      ...toRefs(state),
      fnCallBack,
      addPatternDrones,
      updatePattern,
      deletePatternDrones,
      setShootingParam,
      deletePattern,
      initCalculate
    }
  }
}
</script>

<style scoped>
.nametag-wrapper {
  width: 100%;
}

.nametag-wrapper .label .cst-select {
  border-radius: 0.6rem 0.6rem 0 0;
}

.nametag-wrap {
  background: #eee;
  border: solid 1px #e0e0e0;
  border-radius: 0 0 0.6rem 0.6rem;
  padding: 0.5rem;
}

.nametag-wrap li label > span {
  display: flex;
  padding: 0.3rem;
}

.nametag-wrap .color {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  background: #f00;
  border-radius: 0.6rem;
  margin-right: 0.5rem;
}

.tag-item {
  background: #ed7c2a;
  color: #fff;
  padding: 0.2rem 1rem;
  border-radius: 2rem;
  margin-right: 0.6rem;
  margin-top: -0.2rem;
  display: inline-block;
}
.colorWhite {
  color: white;
  margin-left: 5%;
}
</style>
