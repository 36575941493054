<template>
  <div class="body-inner">
    <div class="modal-dim on">
      <!--'on 추가'-->
      <div class="modal-wrap">
        <div class="modal-inner bottom">
          <div class="modal">
            <div class="modal-head">
              <h2>{{ layout.title }}</h2>
              <!--              <i class="i-close-20"></i>-->
            </div>
            <div class="modal-con">
              <div class="modal-txt">
                <!--  ex) [기체명] <br> 15M  -->
                <div class="txt-ttl" v-html="`[` + layout.name + `]`"></div>
                <div class="txt-con">
                  <p v-html="layout.contents"></p>
                </div>
              </div>
            </div>
            <div class="modal-btn">
              <div class="btn-wrap">
                <button class="btn gray" v-on:click="fnCancel">
                  {{ cancelText }}
                </button>
                <button class="btn color" v-on:click="missionConfirm">
                  {{ doneText }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useModalState } from '@/common/state/ModalState'
import { getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'modal-alert-confirm',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    missionData: {
      type: [Object]
    },
    doneText: {
      type: [String],
      default: '확인'
    },
    cancelText: {
      type: [String],
      default: '취소'
    },
    closeComponent: {},
    mainState: {},
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const store = useStore()
    const { fnModalAlert } = useModalState()
    const { proxy } = getCurrentInstance()
    const fnCallBackCancel = event => {
      props.callBackCancel(proxy, event)
    }
    const fnCancel = () => {
      store.dispatch('layout/closeModalAlertComponent', { index: 1 })
    }
    const missionConfirm = event => {
      // array가 2개 있으니 크게 missionData로 감싸고 안에
      for (let missionData of props.missionData) {
        if (missionData.param == 'mode') {
          proxy.$MavlinkUtils.setMode(missionData.selectedDrone, missionData.mode)
        } else if (missionData.param == 'arm') {
          proxy.$MavlinkUtils.setArm(missionData.selectedDrone)
        } else if (missionData.param == 'disarm') {
          proxy.$MavlinkUtils.setDisArm(missionData.selectedDrone)
        } else if (missionData.param == 'takeoff') {
          proxy.$MavlinkUtils.makeQueue(
            {
              function: [proxy.$MavlinkUtils.setMode, proxy.$MavlinkUtils.setArm, proxy.$MavlinkUtils.setTakeOff],
              parameter: [
                [missionData.selectedDrone, 'GUIDED'],
                [missionData.selectedDrone],
                [missionData.selectedDrone, missionData.globalAlt, missionData.takeoffDeleyTime]
              ]
            },
            missionData.selectedDrone.name
          )
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'alt') {
          const altInfo = {
            lat: missionData.selectedDrone.data.position.latitude,
            lon: missionData.selectedDrone.data.position.longitude,
            alt: parseInt(missionData.globalAlt)
          }
          proxy.$MavlinkUtils.makeQueue(
            {
              function: [proxy.$MavlinkUtils.setMode, proxy.$MavlinkUtils.setMove],
              parameter: [
                [missionData.selectedDrone, 'GUIDED'],
                [missionData.selectedDrone, altInfo]
              ]
            },
            missionData.selectedDrone.name
          )
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'move') {
          var moveInfo = {
            lat: missionData.wpInfo.lat,
            lon: missionData.wpInfo.lng,
            alt: missionData.wpInfo.alt
          }
          const speedParam = setWPNAV_SPEED(missionData.wpInfo.spd)
          if (missionData.moveRadio == 0) {
            proxy.$MavlinkUtils.makeQueue(
              {
                function: [
                  proxy.$MavlinkUtils.setMode,
                  proxy.$MavlinkUtils.setMove,
                  proxy.$MavlinkUtils.setSpeed,
                  proxy.$MavlinkUtils.changeDroneParam
                ],
                parameter: [
                  [missionData.selectedDrone, 'GUIDED'],
                  [missionData.selectedDrone, moveInfo],
                  [missionData.selectedDrone, missionData.wpInfo.spd],
                  [missionData.selectedDrone, speedParam]
                ]
              },
              missionData.selectedDrone.name
            )
          } else {
            let wpData = [
              {
                lat: missionData.selectedDrone.data.position.latitude,
                lng: missionData.selectedDrone.data.position.longitude,
                z: missionData.wpInfo.alt
              },
              {
                lat: missionData.wpInfo.lat,
                lng: missionData.wpInfo.lng,
                z: missionData.wpInfo.alt
              }
            ]
            proxy.$MavlinkUtils.makeQueue(
              {
                function: [
                  proxy.$MavlinkUtils.setMissionClear,
                  proxy.$MavlinkUtils.setAutoMission,
                  proxy.$MavlinkUtils.startMission,
                  proxy.$MavlinkUtils.setSpeed,
                  proxy.$MavlinkUtils.changeDroneParam
                ],
                parameter: [
                  [missionData.selectedDrone],
                  [missionData.selectedDrone, wpData],
                  [missionData.selectedDrone],
                  [missionData.selectedDrone, missionData.wpInfo.spd],
                  [missionData.selectedDrone, speedParam]
                ]
              },
              missionData.selectedDrone.name
            )
          }
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'loiter') {
          proxy.$MavlinkUtils.makeQueue(
            {
              function: [
                proxy.$MavlinkUtils.changeDroneParam,
                proxy.$MavlinkUtils.changeDroneParam,
                proxy.$MavlinkUtils.setMissionClear,
                proxy.$MavlinkUtils.setLoiterTurn,
                proxy.$MavlinkUtils.startMission
              ],
              parameter: [
                [missionData.selectedDrone, { param_id: 'CIRCLE_RADIUS', param_value: missionData.wpInfo.radius, param_type: 5 }],
                [missionData.selectedDrone, { param_id: 'CIRCLE_RATE', param_value: missionData.wpInfo.speed, param_type: 9 }], // circle_rate은 loiter 속도 parameter
                [missionData.selectedDrone],
                [
                  missionData.selectedDrone,
                  [
                    {
                      command: 16,
                      radius: 0,
                      turns: 0,
                      heading: 0,
                      lat: 0,
                      lng: 0,
                      z: 0
                    },
                    {
                      heading: 0,
                      command: 18,
                      z: missionData.globalAlt,
                      lat: missionData.wpInfo.lat,
                      lng: missionData.wpInfo.lng,
                      turns: missionData.wpInfo.rpm,
                      radius: missionData.wpInfo.radius
                    }
                  ]
                ],
                [missionData.selectedDrone]
              ]
            },
            missionData.selectedDrone.name
          )
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'speed') {
          proxy.$MavlinkUtils.makeQueue(
            {
              function: [proxy.$MavlinkUtils.setMode, proxy.$MavlinkUtils.setSpeed],
              parameter: [
                [missionData.selectedDrone, 'GUIDED'],
                [missionData.selectedDrone, missionData.globalSpeed]
              ]
            },
            missionData.selectedDrone.name
          )
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'stop') {
          proxy.$MavlinkUtils.setMode(missionData.selectedDrone, 'BRAKE')
        } else if (missionData.param == 'land') {
          proxy.$MavlinkUtils.setLand(missionData.selectedDrone)
        } else if (missionData.param == 'return') {
          proxy.$MavlinkUtils.setMode(missionData.selectedDrone, 'RTL')
        } else if (missionData.param == 'autoMission') {
          // let missionData = missionData.find(data => data.droneName == missionData.selectedDrone.name)
          var wpData = []
          missionData.wpList.forEach((wp, i) => {
            let instance
            if (i == 1 && missionData.roiPoint) {
              instance = {
                roi: true,
                lat: missionData.roiPoint.lat,
                lng: missionData.roiPoint.lng,
                z: missionData.globalAlt
              }
              wpData.push(instance)
            }
            if (wp.cuAt >= missionData.startIndex && wp.cuAt <= missionData.endIndex) {
              instance = {
                lat: wp.lat,
                lng: wp.lng,
                spd: wp.spd,
                z: wp.alt,
                hold: missionData.autoDelay
              }
              wpData.push(instance)
            }
          })
          const speedParam = setWPNAV_SPEED(missionData.wpList[missionData.wpList.length - 1].spd)
          proxy.$MavlinkUtils.makeQueue(
            {
              function: [
                proxy.$MavlinkUtils.setMissionClear,
                proxy.$MavlinkUtils.changeDroneParam,
                proxy.$MavlinkUtils.setAutoMission,
                proxy.$MavlinkUtils.startMission
              ],
              parameter: [
                [missionData.selectedDrone],
                [missionData.selectedDrone, speedParam],
                [missionData.selectedDrone, wpData],
                [missionData.selectedDrone]
              ]
            },
            missionData.selectedDrone.name
          )
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'startMission') {
          proxy.$MavlinkUtils.startMission(missionData.selectedDrone)
        } else if (missionData.param == 'loadFence') {
          let vertex = []
          let geofenceInfo
          if (missionData.geofenceType == 'polygon') {
            missionData.vertex.forEach(function(coordinates) {
              var coord = {
                lat: coordinates[1],
                lng: coordinates[0],
                z: 20
              }
              vertex.push(coord)
            })
          } else if (missionData.geofenceType == 'circle') {
            var coord = {
              lat: missionData.vertex.lat,
              lng: missionData.vertex.lng,
              z: 20
            }
            vertex.push(coord)
          }
          geofenceInfo = {
            type: missionData.geofenceType,
            radius: missionData.radius,
            inclusion: missionData.inclusion,
            violationAct: missionData.violationAct,
            violationPosition: missionData.violationPosition
          }
          proxy.$MavlinkUtils.makeQueue(
            {
              function: [proxy.$MavlinkUtils.setGeoFence],
              parameter: [[missionData.selectedDrone, vertex, geofenceInfo]]
            },
            missionData.selectedDrone.name
          )
          proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
        } else if (missionData.param == 'getHome') {
          proxy.$MavlinkUtils.getHome(missionData.selectedDrone)
        } else if (missionData.param == 'setHome') {
          proxy.$MavlinkUtils.setHome(missionData.selectedDrone, { lon: 128.4723631, lat: 37.1710662 })
        } else if (missionData.param == 'PATTERN') {
          //* 범위각 선택 (WP_YAW_BEHAVIOR)
          let changedParam
          if (missionData.azimuthControl == 'lock') {
            changedParam = { param_id: 'WP_YAW_BEHAVIOR', param_type: 2, param_value: 0 }
          } else if (missionData.azimuthControl == 'unlock') {
            changedParam = { param_id: 'WP_YAW_BEHAVIOR', param_type: 2, param_value: 1 }
          }
          var pointData = []
          //* 패턴을 선택했을 경우
          if (missionData.selectedPattern !== 'none') {
            missionData.selectedPattern.droneList.forEach(drone => {
              if (drone.name == missionData.droneName) {
                drone.path.forEach((path, index) => {
                  var instance = {
                    lat: path[1],
                    lng: path[0],
                    z: missionData.selectedPattern.alt
                  }
                  pointData.push(instance)
                })
              }
            })
            props.mainState.ctrlDroneList.forEach(drone => {
              if (drone.name == missionData.droneName) {
                drone.pattern = { name: missionData.selectedPattern.patternName, path: [] }
              }
            })
            proxy.$DroneUtils.setCurrentPattern({ name: missionData.selectedPattern.patternName, path: [] }, missionData.selectedDrone)
            const speedParam = setWPNAV_SPEED(missionData.selectedPattern.speed)
            proxy.$MavlinkUtils.changeDroneParam(missionData.selectedDrone, speedParam)

            proxy.$MavlinkUtils.makeQueue(
              {
                function: [
                  // proxy.$MavlinkUtils.changeDroneParam,
                  proxy.$MavlinkUtils.setMissionClear,
                  proxy.$MavlinkUtils.setPatternMission,
                  proxy.$MavlinkUtils.startPattern,
                  proxy.$MavlinkUtils.setSpeed,
                  setPattern
                ],
                parameter: [
                  // [missionData.selectedDrone, changedParam],
                  [missionData.selectedDrone],
                  [missionData.selectedDrone, pointData, missionData.selectedPattern.shootPeriod],
                  [missionData.selectedDrone, pointData.length],
                  [missionData.selectedDrone, missionData.selectedPattern.speed],
                  [missionData, missionData.selectedDrone]
                ]
              },
              missionData.selectedDrone.name
            )
            proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
          } else {
            //* 패턴 선택 안하고 임무 연결에서 그만하기, 이어하기를 선택했을 경우
            if (missionData.missionControl == 'stop') {
              let unfinishedPath
              if (missionData.selectedDrone.pattern.path.length) {
                //* 이미 중단한 path가 있을 때
                unfinishedPath = missionData.selectedDrone.pattern.path
                unfinishedPath.splice(0, missionData.selectedDrone.currentWpSeq - 2)
              } else {
                //* 중단한 path가 없을 때 등록한 패턴에서 path 가져오기
                const unfinishedPattern = missionData.patternList.find(pattern => pattern.patternName == missionData.selectedDrone.pattern.name)
                const unfinishedData = unfinishedPattern.droneList.find(drone => drone.name == missionData.droneName)
                unfinishedPath = unfinishedData.path
                unfinishedPath.splice(0, missionData.selectedDrone.currentWpSeq - 2)
              }
              props.mainState.ctrlDroneList.forEach(drone => {
                if (drone.name == missionData.droneName) {
                  drone.pattern = { name: missionData.selectedDrone.pattern.name, path: unfinishedPath }
                }
              })

              const currentPattern = { name: missionData.selectedDrone.pattern.name, path: unfinishedPath }
              proxy.$DroneUtils.setCurrentPattern(currentPattern, missionData.selectedDrone)
              proxy.$MavlinkUtils.makeQueue(
                {
                  function: [proxy.$MavlinkUtils.setMode],
                  parameter: [[missionData.selectedDrone, 'RTL']]
                },
                missionData.selectedDrone.name
              )
              proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
            } else if (missionData.missionControl == 'continue') {
              const pattern = missionData.selectedDrone.pattern
              const patternData = missionData.patternList.find(pt => pt.patternName == pattern.name)
              pattern.path.forEach(path => {
                var instance = {
                  lat: path[1],
                  lng: path[0],
                  z: patternData.alt
                }
                pointData.push(instance)
              })
              const speedParam = setWPNAV_SPEED(patternData.speed)
              proxy.$MavlinkUtils.makeQueue(
                {
                  function: [
                    proxy.$MavlinkUtils.setMissionClear,
                    proxy.$MavlinkUtils.setAutoMission,
                    proxy.$MavlinkUtils.startPattern,
                    proxy.$MavlinkUtils.setSpeed,
                    proxy.$MavlinkUtils.changeDroneParam
                  ],
                  parameter: [
                    [missionData.selectedDrone],
                    [missionData.selectedDrone, pointData, true],
                    [missionData.selectedDrone, pointData.length],
                    [missionData.selectedDrone, patternData.speed],
                    [missionData.selectedDrone, speedParam]
                  ]
                },
                missionData.selectedDrone.name
              )
              proxy.$MavlinkUtils.nextQueueExecution(missionData.selectedDrone.name)
            }
          }
        } else if (missionData.param == 'INTEREST') {
          props.mainState.registerROI.set(missionData.droneName, missionData.wpInfo)
        } else if (missionData.param == 'capture') {
          let captureMessage
          if (missionData.capture == 'start') captureMessage = `01g 5 ${missionData.selectedDrone.name}`
          else if (missionData.capture == 'stop') captureMessage = `01s`
          proxy.$DroneUtils.sendCaptureMessage(missionData.selectedDrone, captureMessage)
        }
      }
      fnCallBackCancel(event)
      props.closeComponent()
    }

    const setPattern = async (missionData, drone) => {
      try {
        const currentPattern = { name: missionData.selectedPattern.patternName, no: 0 }
        await proxy.$DroneUtils.setCurrentPattern(currentPattern, drone)
      } catch (error) {
        throw new Error(error)
      }
    }
    const setWPNAV_SPEED = spd => {
      return { param_id: 'WPNAV_SPEED', param_value: spd * 100, param_type: 9 }
    }
    // await fnModalAlert('알람', '지오펜스 적재가 완료되었습니다.', '')
    return { fnCallBackCancel, fnCancel, missionConfirm, setPattern }
  }
}
</script>

<style scoped></style>
