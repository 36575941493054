class ConfigUtils {
  getServiceUrl() {
    // return 'http://localhost:7588/'
    return 'http://npgcs.iotocean.org/'
    // return 'https://npgcs.iotocean.org:7588/'
    // return '/'
  }
}

export default new ConfigUtils()

// 배포전에 개발서버에서 테스트하고 내보내야함
// 인증서 생성 시 하기전에 내부검수 및 요청
