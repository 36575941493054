<template>
  <div class="modal-dim" v-show-modal-alert style="z-index: 1002">
    <!--'on 추가'-->
    <div class="set-window-wrap">
      <div class="set-window sub">
        <div class="set-window-top">
          <div class="set-window-sub">
            <div class="sub">
              <i class="i-set-add"></i>
              <h2>Drone Profile</h2>
            </div>
            <div class="close" @click="fnCallBackCancel">
              <i class="i-close-26"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con">
          <div class="sect">
            <p class="input-info">*는 필수 입력사항입니다.</p>
            <div class="row2 mt12">
              <div class="form column">
                <p class="form-sub">Drone Name*</p>
                <label class="label">
                  <input type="text" placeholder="최대 글자수 00자 이내" readonly />
                </label>
                <p class="valid" v-if="true">유효성 확인 문구</p>
              </div>
              <div class="form column">
                <p class="form-sub">Drone ID*</p>
                <label class="label">
                  <input type="text" placeholder="최대 글자수 00자 이내" readonly />
                </label>
                <p class="valid" v-if="true">유효성 확인 문구</p>
              </div>
              <div class="form column">
                <p class="form-sub">Type*</p>
                <label class="label">
                  <select name="" id="" class="select">
                    <option value="">option</option>
                  </select>
                </label>
                <p class="valid" v-if="true">유효성 확인 문구</p>
              </div>
              <div class="form column">
                <p class="form-sub">Battery Cell*</p>
                <label class="label">
                  <input type="text" placeholder="내용을 입력하세요" />
                </label>
                <p class="valid" v-if="true">유효성 확인 문구</p>
              </div>
              <div class="form column">
                <p class="form-sub">System ID*</p>
                <label class="label">
                  <input type="text" placeholder="내용을 입력하세요" />
                </label>
                <p class="valid" v-if="true">유효성 확인 문구</p>
              </div>

              <div class="form column">
                <p class="form-sub">Color</p>
                <div class="select-color">
                  <div class="select-color-tab" @click="fnOpenColorTab">
                    <div class="color-box color red"></div>
                    <span>red</span>
                  </div>
                  <!--JY: 탭 클릭시 아래 옵션 활성화: 셀렉트처럼-->
                  <ul class="select-color-option " :class="{ on: tab === true }">
                    <li class="color-add" @click.prevent.stop="fnAddNewColor">
                      <a href="#">
                        <p>Add New Color</p>
                        <i class="i-add-gray"></i>
                      </a>
                    </li>
                    <li class="sample">
                      <ul>
                        <li>
                          <div class="color-box color red"></div>
                          <span>red</span>
                        </li>
                        <li>
                          <div class="color-box color pink"></div>
                          <span>pink</span>
                        </li>
                        <li>
                          <div class="color-box color deep-purple"></div>
                          <span>deep-purple</span>
                        </li>
                        <li>
                          <div class="color-box color purple"></div>
                          <span>purple</span>
                        </li>
                        <li>
                          <div class="color-box color indigo"></div>
                          <span>indigo</span>
                        </li>
                        <li>
                          <div class="color-box color blue"></div>
                          <span>blue</span>
                        </li>
                        <li>
                          <div class="color-box color light-blue"></div>
                          <span>light-blue</span>
                        </li>
                        <li>
                          <div class="color-box color blue"></div>
                          <span>cyan</span>
                        </li>
                        <li>
                          <div class="color-box color teal"></div>
                          <span>teal</span>
                        </li>
                        <li>
                          <div class="color-box color green"></div>
                          <span>green</span>
                        </li>
                        <li>
                          <div class="color-box color lime"></div>
                          <span>lime</span>
                        </li>
                        <li>
                          <div class="color-box color yellow"></div>
                          <span>yellow</span>
                        </li>
                        <li>
                          <div class="color-box color amber"></div>
                          <span>amber</span>
                        </li>
                        <li>
                          <div class="color-box color orange"></div>
                          <span>orange</span>
                        </li>
                        <li>
                          <div class="color-box color deep-orange"></div>
                          <span>deep-orange</span>
                        </li>
                        <li>
                          <div class="color-box color brown"></div>
                          <span>brown</span>
                        </li>
                        <li>
                          <div class="color-box color blue-grey"></div>
                          <span>blue-grey</span>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button class="btn color" @click="fnCallBackDone">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { modalAlertNames } from '@/components/common/globalComponent'
import { useModalState } from '@/common/state/ModalState'
import { useStore } from 'vuex'

export default {
  name: 'drone-modify',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    doneText: {
      type: [String],
      default: 'CONFIRM'
    },
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { modalPopUp } = useModalState()
    const { dispatch } = useStore()
    const state = reactive({
      tab: false
    })

    const fnCallBackCancel = async () => {
      await dispatch('layout/closeModalAlertComponent', 1)
    }
    const fnCallBackDone = async event => {
      await dispatch('layout/closeModalAlertComponent', 1)
      // props.callBackDone(proxy, event)
    }
    const callBack = async () => {}
    const fnAddNewColor = async () => {
      await modalPopUp(modalAlertNames.NEWCOLOR, () => callBack())
    }
    const fnOpenColorTab = () => {
      state.tab = !state.tab
    }

    return {
      ...toRefs(state),
      fnCallBackCancel,
      fnCallBackDone,
      fnAddNewColor,
      fnOpenColorTab
    }
  }
}
</script>

<style scoped></style>
