<template>
  <div style="overflow: hidden; height: 348px; width: 100%">
    <img src="@/assets/static/images/hud/background3.png" class="hud_background" />
    <div class="speed" style="pointer-events: none">
      <img src="@/assets/static/images/hud/gauge_left.png" class="speed_gauge" />
    </div>
    <div class="valbox spdbox" style="pointer-events: none">
      <div class="boxtitle">SPD</div>
      <div class="boxcont shadow_icon">
        {{
          Math.sqrt(
            drone.data.groundSpeed.x * drone.data.groundSpeed.x +
              drone.data.groundSpeed.y * drone.data.groundSpeed.y +
              drone.data.groundSpeed.z * drone.data.groundSpeed.z
          ).toFixed(2)
        }}
      </div>
      <div class="boxline"></div>
    </div>
    <div class="alt" style="pointer-events: none">
      <img src="@/assets/static/images/hud/gauge_right.png" class="alt_gauge" style="top: -4364.5%" />
    </div>
    <div class="valbox altbox" style="pointer-events: none">
      <div class="boxline"></div>
      <div class="boxcont shadow_icon">{{ Math.floor(drone.data.relative_alt / 1000) }}</div>
      <div class="boxtitle">ALT</div>
    </div>
    <div class="roll" style="pointer-events: none">
      <img src="@/assets/static/images/hud/roll_gauge.png" class="roll_gauge" />
    </div>
    <div class="pitch_background" style="pointer-events: none">
      <img src="@/assets/static/images/hud/center_gauge_w.png" class="pitch_gauge" style="top: -104.3%" />
    </div>
    <div class="speed_unit" style="pointer-events: none">
      <img src="@/assets/static/images/hud/km_guide.png" />
    </div>
    <div class="alt_unit" style="pointer-events: none">
      <img src="@/assets/static/images/hud/alt_guide.png" />
    </div>
    <div class="hudHeading" style="pointer-events: none">
      <img src="@/assets/static/images/hud/compass_full.png" class="heading_gauge" />
    </div>
    <div class="valbox_head headbox" style="pointer-events: none">
      <div class="hedcont shadow_icon">{{ Math.floor(drone.data.heading) }}˚</div>
    </div>
    <div class="guide" style="pointer-events: none">
      <img src="@/assets/static/images/hud/guide.png" class="guide_gauge" />
    </div>
    <div class="armed_default">
      <span class="shadow_icon" style="font-size:40px; color: red;">
        {{ drone.data.armStatus === 0 ? 'DISARMED' : 'ARMED' }}
      </span>
    </div>
    <div class="droneInfo" style="pointer-events: none">
      <div class="homedistance" style="width: 100%">
        <!-- <span class="shadow_icon" style="font-size:30px;">
          <img src="@/assets/static/images/i_home_white.svg" style="float: left; margin-top:7px;" />
          {{ drone.data.homeDistance }}m
        </span> -->
        <span class="shadow_icon" style="float: right; margin-bottom:10px; font-size:30px;">{{ flightMode }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, computed, toRefs, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import $ from 'jquery'

export default {
  name: 'hud-panel',
  props: {
    drone: Object,
    isVideo: {},
    // videoId: String,
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { getters } = useStore()

    const state = reactive({
      isVideo: props.isVideo,
    })

    const data = reactive({
      flightMode: computed(() => {
        return proxy.$Util.setFlightMode(props.drone.data.flightMode)
      }),
    })

    watch(
      () => props.drone.data.armStatus,
      async val => {
        if (val == 128) {
          $('#' + props.drone.id + ' div .armed_default').animate({ opacity: 0 }, 5000)
          $('#' + props.drone.id + ' div .armed_upsize').animate({ opacity: 0 }, 5000)
        } else {
          $('#' + props.drone.id + ' div .armed_default').css('opacity', 1)
          $('#' + props.drone.id + ' div .armed_upsize').css('opacity', 1)
        }
      }
    )

    onMounted(() => {
      console.log(props.drone.data.relative_alt)
      let armStatus = props.drone.data.armStatus
      if (armStatus == 128) {
        $('#' + props.drone.id + ' div .armed_default').animate({ opacity: 0 }, 5000)
        $('#' + props.drone.id + ' div .armed_upsize').animate({ opacity: 0 }, 5000)
      } else {
        $('#' + props.drone.id + ' div .armed_default').css('opacity', 1)
        $('#' + props.drone.id + ' div .armed_upsize').css('opacity', 1)
      }
    })



    return {
      ...toRefs(state),
      ...toRefs(data),
    }
  }
}
</script>

<style scoped>
.shadow_icon {
  text-shadow: 0.5px 0.5px 5px #000000;
}
video::-webkit-media-controls {
  display: none !important;
}
.pitch_background {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  width: 160px;
  height: 215px;
  top: 21%;
  left: 32.5%;
}

.pitch_image {
  position: relative;
  top: -139%;
  left: -189%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.v-leave {
  opacity: 0;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.5s;
}
.v-enter-to {
  opacity: 1;
}
.droneInfo {
  position: absolute;
  width: 100%;
  left: 0%;
  bottom: 0%;
  overflow: hidden;
  color: white;
  padding: 0 10px;
}
.droneInfo > div {
  margin-bottom: 5px;
  width: 100%;
}
.droneInfo .bold {
  font-weight: bold;
}
.hud_background {
  width: 1000px;
  height: 807.885px;
  top: -65.5%;
  position: relative;
  left: -50%;
}
.hudHeading {
  position: absolute;
  width: 98%;
  height: 12%;
  left: -43%;
  top: 0%;
}
.heading_gauge {
  left: -175.39%;
  position: relative;
  height: 70%;
  transition-duration: 0.8s;
  transition-property: top;
  max-width: none;
}
.speed {
  position: absolute;
  width: 13%;
  left: 9%;
  top: 24.9%;
  height: 51%;
  overflow: hidden;
  border: solid 1px #fff;
  border-left: 0;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 5px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.speed_unit {
  position: absolute;
  width: 100%;
  left: 13%;
  top: 15%;
  height: 54%;
  overflow: hidden;
}
.armed_default {
  position: absolute;
  height: 35%;
  width: 50%;
  left: 27%;
  top: 35%;
  overflow: hidden;
  transform-origin: 53% 33% 0px;
  z-index: 3;
  text-align: center;
}
.armed_upsize {
  position: absolute;
  height: 35%;
  width: 50%;
  left: 27%;
  top: 39%;
  overflow: hidden;
  transform-origin: 53% 33% 0px;
  z-index: 3;
  text-align: center;
}
.speed_gauge {
  position: absolute;
  height: 380%;
  width: 80%;
  left: 20%;
  top: -228.8%;
  transition-duration: 0.3s;
  transition-property: top;
}
.alt {
  position: absolute;
  width: 12.5%;
  top: 24.5%;
  height: 51%;
  right: 9%;
  overflow: hidden;
  border: solid 1px #fff;
  border-right: 0;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: -5px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.alt_unit {
  position: absolute;
  width: 8%;
  top: 14%;
  height: 48%;
  right: 9%;
  overflow: hidden;
}
.alt_gauge {
  width: 90%;
  position: absolute;
  height: 4500%;
  transition-duration: 0.3s;
  transition-property: top;
}

.valbox_head {
  position: absolute;
  font-size: 14px;
  width: 90px;
  height: 30px;
  top: 47.1%;
}
.valbox_head.headbox {
  position: absolute;
  top: 0%;
  font-size: 14px;
  width: 90px;
  height: 30px;
  left: 43.9%;
  opacity: 1;
}
.valbox_head .boxcont {
  background: rgba(11, 11, 11, 0.4);
  color: #00ffff;
  width: 41%;
  height: 25px;
  line-height: 25px;
  float: left;
  text-align: center;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.valbox_head .hedcont {
  background: rgba(11, 11, 11, 0.4);
  color: #ffffff;
  width: 41%;
  margin-left: 10px;
  text-align: center;
  height: 30px;
  line-height: 28px;
  float: left;
  text-align: center;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
}

.valbox {
  position: absolute;
  font-size: 14px;
  width: 90px;
  height: 30px;
  top: 47.1%;
}
.valbox.spdbox {
  left: 2%;
}
.valbox.altbox {
  right: 1%;
}
.valbox.headbox {
  position: absolute;
  top: 0%;
  font-size: 14px;
  width: 90px;
  height: 30px;
  left: 43.9%;
  opacity: 1;
}
.valbox .boxtitle {
  background: rgba(44, 212, 212, 0.5);
  color: #fff;
  width: 32%;
  height: 25px;
  line-height: 25px;
  float: left;
  text-align: center;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.valbox .boxcont {
  background: rgba(11, 11, 11, 0.4);
  color: #00ffff;
  width: 41%;
  height: 25px;
  line-height: 25px;
  float: left;
  text-align: center;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.valbox .hedcont {
  background: rgba(11, 11, 11, 0.4);
  color: #ffffff;
  width: 41%;
  margin-left: 17px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  float: left;
  text-align: center;
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.valbox.altbox .boxcont {
  margin-left: 20px;
}
.valbox .boxline {
  width: 27px;
  height: 1px;
  background: #00ffff;
  position: absolute;
  top: 38%;

  transform: translateY(-50%);
  -webkit-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  -moz-box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
  box-shadow: 2px 5px 8px 0px rgba(33, 33, 33, 0.2);
}
.valbox.altbox .boxline {
  left: -7px;
}
.valbox.spdbox .boxline {
  right: -4px;
}
.valbox.headbox .boxline {
  height: 12px;
  width: 1px;
  left: 40%;
  top: 105%;
  z-index: 0;
}
.roll {
  position: absolute;
  width: 46%;
  left: 27.2%;
  top: 7.2%;
  transform-origin: 51% 61%;
  transition-property: transform;
  transition-duration: 0.3s;
}
.roll_gauge {
  width: 100%;
  height: 100%;
  position: absolute;
}
.pitch {
  position: absolute;
  height: 45%;
  width: 15%;
  left: 43.5%;
  top: 34%;
  overflow: hidden;
  transform-origin: 53% 33% 0px;
}
.pitch_gauge {
  /* width: 45%;
	position: absolute;
	left: 25%;
	top: -10%;
	height : 325%;
	transition-property: top;
	transition-duration: .3s; */
  position: relative;
  width: 45%;
  /* top: -50%; */
  left: 28%;
  /* transition-duration: .3s;
	transition-property: top; */
}
.guide {
  position: absolute;
  height: 35%;
  width: 50%;
  left: 27%;
  top: 15.5%;
  overflow: hidden;
  transform-origin: 53% 33% 0px;
  z-index: 3;
}
.guide_gauge {
  width: 100%;
  position: absolute;
  left: -4%;
  top: -76%;
  height: 349%;
  transition-property: top;
  transition-duration: 0.3s;
}
.roll:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.roll_anchor_round_area:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
.table_column {
  width: 33%;
  height: 49.5%;
  position: relative;
  float: left;
  border: 0px solid #29394c;
}
.clear_row {
  clear: both;
}
.gauge_circle {
  width: 100%;
  height: 100%;
  position: absolute;
}
.gauge_text {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
  top: 0%;
}
.alt_hand {
  position: absolute;
  left: 0%;
  width: 100%;
  height: 100%;
  top: 0%;
  transform-origin: 50% 50%;
}
.heading_hand {
  position: absolute;
  width: 25%;
  height: 25%;
  left: 38%;
  top: 36%;
  transform-origin: center;
}
.speed_hand {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  transform-origin: 50% 50%;
  transform: rotate(207deg);
}

.speed_m_hand {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0%;
  top: 0%;
  transform-origin: 50% 50%;
  transform: rotate(225deg);
}
.vsi_hand {
  position: absolute;
  width: 100%;
  left: 0%;
  height: 100%;
  top: 0%;
  transform-origin: 50% 50%;
  transform: rotate(270deg);
}
.roll_hand {
  position: absolute;
  width: 50%;
  height: 50%;
  left: 25%;
  top: 25%;
  transform-origin: center;
}
.pitch_hand {
  position: absolute;
  width: 40%;
  height: 40%;
  left: 31%;
  top: 30%;
  transform-origin: center;
}
.heading {
  position: absolute;
  width: 100%;
  height: 12%;
  /* border:solid 1px #fff; */
  border-bottom: solid 1px #fff;
  bottom: 3%;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
}
/**
	========= 안씀 =================
*/
.battery_svg {
  position: absolute;
  width: 32%;
  bottom: -4%;
  left: 11%;
}
.battery_box {
  color: #fff;
  left: 15%;
  bottom: 10%;
  width: 50%;
  height: 50%;
}
.battery_text {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tbl1 {
  color: #fff;
  left: 24%;
  position: absolute;
  bottom: 9.7%;
  font-size: x-small;
  width: 7%;
  text-align: right;
  height: 3%;
}
.tbl2 {
  color: #fff;
  left: 24%;
  position: absolute;
  bottom: 5.7%;
  font-size: x-small;
  width: 7%;
  text-align: right;
  height: 3%;
}
.tbl3 {
  color: #fff;
  left: 24%;
  position: absolute;
  bottom: 1.7%;
  font-size: x-small;
  width: 7%;
  text-align: right;
  height: 3%;
}
.tbl4 {
  color: #fff;
  right: 19%;
  position: absolute;
  bottom: 9.7%;
  font-size: x-small;
  width: 7%;
  height: 3%;
}
.tbl5 {
  color: #fff;
  right: 19%;
  position: absolute;
  bottom: 5.7%;
  font-size: x-small;
  width: 7%;
  height: 3%;
}
.tbl6 {
  color: #fff;
  right: 19%;
  position: absolute;
  bottom: 1.7%;
  font-size: x-small;
  width: 7%;
  height: 3%;
}
.groundspeed {
  position: absolute;
  width: 13%;
  height: 3%;
  /*top	: 55.3%;
	left : 33%;*/
  left: 23%;
  top: 72.3%;
  color: #fff;
  font-weight: bold;
  font-size: x-small;
}
.airspeed {
  position: absolute;
  width: 13%;
  height: 3%;
  /* top	: 59.5%;
	left : 33%;*/
  left: 23%;
  top: 76.5%;
  color: #fff;
  font-weight: bold;
  font-size: x-small;
}
.gauge_title {
  height: 12%;
  padding: 5px 0px;
}
.gauge_box {
  position: relative;
  height: 88%;
}
.hud_bat_gauge {
  position: absolute;
  left: 2%;
  top: 80%;
  width: 25%;
  height: 25%;
}
.hud_bat_1_gauge {
  position: absolute;
  left: 16%;
  top: 80%;
  width: 25%;
  height: 25%;
}
.hud_bat_2_gauge {
  position: absolute;
  left: 24%;
  top: 80%;
  width: 25%;
  height: 25%;
}
.hud_gps_gauge {
  position: absolute;
  left: 2%;
  top: 84%;
  width: 25%;
  height: 25%;
}
.hud_time_gauge {
  position: absolute;
  left: 37.5%;
  top: 84%;
  width: 25%;
  height: 25%;
}
.hud_cfm_gauge {
  position: absolute;
  right: 2%;
  top: 80%;
  width: 25%;
  height: 25%;
}
.hud_cd_gauge {
  position: absolute;
  right: 2%;
  top: 84%;
  width: 25%;
  height: 25%;
}
.hud_speed_km_gauge {
  position: absolute;
  left: 2%;
  top: 0%;
  width: 25%;
  height: 25%;
}
.hud_vsi_gauge {
  position: absolute;
  right: 2%;
  top: 0%;
  width: 25%;
  height: 25%;
}
.hud_speed_gauge {
  position: absolute;
  left: 12%;
  top: 38%;
  width: 25%;
  height: 25%;
}
.hud_alt_gauge {
  position: absolute;
  right: 16%;
  top: 38%;
  width: 25%;
  height: 25%;
}
.hud_as_gauge {
  position: absolute;
  left: 12%;
  top: 58%;
  width: 25%;
  height: 25%;
}
.hud_gs_gauge {
  position: absolute;
  left: 12%;
  top: 62%;
  width: 25%;
  height: 25%;
}
</style>
