const extra = {
  STABILIZE: {
    name: 'STABILIZE'
  },
  ACRO: {
    name: 'ACRO'
  },
  ALT_HOLD: {
    name: 'ALT_HOLD'
  },
  AUTO: {
    name: 'AUTO'
  },
  GUIDED: {
    name: 'GUIDED'
  },
  LOITER: {
    name: 'LOITER'
  },
  RTL: {
    name: 'RTL'
  },
  CIRCLE: {
    name: 'CIRCLE'
  },
  POSITION: {
    name: 'POSITION'
  },
  LAND: {
    name: 'LAND'
  },
  OF_LOITER: {
    name: 'OF_LOITER'
  },
  DRIFT: {
    name: 'DRIFT'
  },
  RESERVED_12: {
    name: 'RESERVED_12'
  },
  SPORT: {
    name: 'SPORT'
  },
  FLIP: {
    name: 'FLIP'
  },
  AUTOTUNE: {
    name: 'AUTOTUNE'
  },
  POS_HOLD: {
    name: 'POS_HOLD'
  },
  BRAKE: {
    name: 'BRAKE'
  },
  THROW: {
    name: 'THROW'
  },
  AVOID_ADSB: {
    name: 'AVOID_ADSB'
  },
  GUIDED_NOGPS: {
    name: 'GUIDED_NOGPS'
  },

  SAFE_RTL: {
    name: 'SAFE_RTL'
  }
}

export default extra
