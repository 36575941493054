import AuthUtils from '@/services/auth/Auth'
import SocketUtils from '@/services/common/Socket'

const isUser = localStorage.getItem('userData')
const isAcToken = localStorage.getItem('gcsAcToken')
export const state = {
  gcsAcToken: isAcToken ? isAcToken : null,
  userData: isUser ? isUser : null,
  isAutoLogin: isUser ? true : false,
  rfData: { channel: '', rfDrone: { name: '', host: '' }, extents: '' }
}
export const getters = {
  getAccessToken(state) {
    return state.gcsAcToken
  },
  getUserData(state) {
    return JSON.parse(state.userData)
  },
  getIsAutoLogin(state) {
    return state.isAutoLogin
  },
  getRfData(state) {
    return state.rfData
  }
}
export const mutations = {
  setAccessToken(state, { gcsAcToken = '' }) {
    state.gcsAcToken = gcsAcToken
    localStorage.gcsactoken = gcsAcToken
  },
  setUserData(state, { userData = {} }) {
    state.userData = userData
    localStorage.userData = JSON.stringify(userData)
  },
  setIsAutoLogin(state, { isAutoLogin = false }) {
    state.isAutoLogin = isAutoLogin
    localStorage.isAutoLogin = isAutoLogin
  },
  storeLogin(state, userData) {
    state.userData = userData.userInfo
    state.gcsAcToken = userData.gcsactoken
    state.isAutoLogin = true
    localStorage.userData = userData.userInfo
    localStorage.gcsAcToken = userData.gcsactoken
    localStorage.gcsReToken = userData.gcsretoken
  },
  async storeLogout(state) {
    localStorage.clear()
  },
  setRfChannel(state, channel) {
    state.rfData['channel'] = channel
  },
  setRfDrone(state, rfDrone) {
    state.rfData['rfDrone'] = rfDrone
  },
  setRfExtents(state, extents) {
    state.rfData['extents'] = extents
  }
}
export const actions = {
  async signIn({ commit }, user) {
    const userData = await AuthUtils.login({ email: user.userEmail, password: user.userPw })
    commit('storeLogin', userData)
    return userData
  },
  async signOut({ commit }) {
    await SocketUtils.disconnect()
    location.replace('/')
    commit('storeLogout')
  },
  updateRfData({ commit }, { type, data }) {
    if (type === 'channel') commit('setRfChannel', data)
    else if (type === 'drone') commit('setRfDrone', data)
    else if (type === 'extents') commit('setRfExtents', data)
  },
  initRfData({ commit }, data) {
    commit('setRfChannel', data.channel)
    commit('setRfDrone', data.rfDrone)
    commit('setRfExtents', data.extents)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
