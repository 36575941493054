<template>
  <svg class="droneSvg" width="53" height="44" viewBox="0 0 53 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_113_73142)">
      <path
        class="stroke"
        d="M8.39268 15.781C12.215 15.781 15.3137 12.6823 15.3137 8.85996C15.3137 5.0376 12.215 1.93896 8.39268 1.93896C4.57032 1.93896 1.47168 5.0376 1.47168 8.85996C1.47168 12.6823 4.57032 15.781 8.39268 15.781Z"
        :stroke="color"
        stroke-miterlimit="100"
      />
      <path
        class="stroke"
        d="M41.6925 15.781C45.5148 15.781 48.6135 12.6823 48.6135 8.85996C48.6135 5.0376 45.5148 1.93896 41.6925 1.93896C37.8701 1.93896 34.7715 5.0376 34.7715 8.85996C34.7715 12.6823 37.8701 15.781 41.6925 15.781Z"
        :stroke="color"
        stroke-miterlimit="100"
      />
      <path
        class="stroke"
        d="M8.4538 41.5751C12.5704 41.5751 15.9076 38.2379 15.9076 34.1213C15.9076 30.0047 12.5704 26.6675 8.4538 26.6675C4.33718 26.6675 1 30.0047 1 34.1213C1 38.2379 4.33718 41.5751 8.4538 41.5751Z"
        :stroke="color"
        stroke-miterlimit="100"
      />
      <path class="fill" d="M10.4087 8.49773L9.41382 10.6157L17.8858 14.5951L18.8806 12.4771L10.4087 8.49773Z" :fill="color" />
      <path class="fill" d="M39.8572 8.49425L31.3853 12.4736L32.3801 14.5916L40.8521 10.6122L39.8572 8.49425Z" :fill="color" />
      <path class="fill" d="M34.0067 25.769L32.4695 28.1882L39.4643 33.7952L41.6981 31.88L34.0067 25.769Z" :fill="color" />
      <path class="fill" d="M16.2585 25.769L8.56714 31.88L10.8009 33.7952L17.7957 28.1882L16.2585 25.769Z" :fill="color" />
      <path
        class="fill stroke"
        d="M19.7704 19.0444L22.474 17.3002C24.256 16.15 26.5474 16.15 28.3312 17.3002L31.0348 19.0444C31.5892 19.4026 32.0626 19.8545 32.4442 20.3693L31.7602 12.8452C31.7242 12.4582 31.6 12.082 31.4002 11.7616L28.1746 6.58305C27.8704 6.09345 27.3736 5.80005 26.8552 5.80005H23.6656C23.1472 5.80005 22.6558 6.09345 22.3588 6.58305L19.216 11.7616C19.0216 12.082 18.9028 12.4564 18.874 12.8452L18.3052 20.4412C18.6958 19.894 19.189 19.4189 19.7704 19.0427V19.0444Z"
        :fill="color"
        :stroke="color"
        stroke-width="2"
        stroke-miterlimit="100"
      />
      <path
        class="fill stroke"
        d="M32.4443 20.3692C32.0627 19.8544 31.5893 19.4026 31.0349 19.0444L28.3313 17.3002C26.5493 16.15 24.2579 16.15 22.4741 17.3002L19.7705 19.0444C19.1891 19.4188 18.6959 19.8958 18.3053 20.443C17.6609 21.3466 17.2991 22.4391 17.2991 23.5821V28.1596C17.2991 29.9938 18.2297 31.702 19.7705 32.6973L22.4741 34.4416C24.2561 35.5918 26.5475 35.5918 28.3313 34.4416L31.0349 32.6973C32.5757 31.702 33.5063 29.9938 33.5063 28.1596V23.5821C33.5063 22.4085 33.1229 21.2872 32.4443 20.3692Z"
        :fill="color"
        :stroke="color"
        stroke-width="2"
        stroke-miterlimit="100"
      />
      <path
        class="fill"
        d="M10.659 9.21424C10.8541 7.96286 9.99779 6.79028 8.74641 6.59521C7.49502 6.40014 6.32244 7.25646 6.12737 8.50784C5.9323 9.75923 6.78862 10.9318 8.04001 11.1269C9.29139 11.3219 10.464 10.4656 10.659 9.21424Z"
        :fill="color"
      />
      <path
        class="fill"
        d="M11.0927 4.18361C10.6625 3.93521 10.1117 4.08281 9.86329 4.51301L5.36329 12.307C5.11489 12.7372 5.26249 13.288 5.69269 13.5364C6.12289 13.7848 6.6737 13.6372 6.9221 13.207L11.4221 5.41301C11.6705 4.98281 11.5229 4.43201 11.0927 4.18361Z"
        :fill="color"
      />
      <path
        class="fill"
        d="M42.3676 11.0546C43.578 10.6817 44.257 9.39829 43.8842 8.18791C43.5113 6.97753 42.2279 6.29855 41.0175 6.67138C39.8071 7.0442 39.1282 8.32764 39.501 9.53802C39.8738 10.7484 41.1573 11.4274 42.3676 11.0546Z"
        :fill="color"
      />
      <path
        class="fill"
        d="M44.7219 12.307L40.2219 4.51301C39.9735 4.08281 39.4227 3.93521 38.9925 4.18361C38.5623 4.43201 38.4147 4.98281 38.6631 5.41301L43.1631 13.207C43.4115 13.6372 43.9623 13.7848 44.3925 13.5364C44.8227 13.288 44.9703 12.7372 44.7219 12.307Z"
        :fill="color"
      />
      <path
        class="fill"
        d="M8.39265 36.5961C9.79335 36.5961 10.9288 35.4606 10.9288 34.0599C10.9288 32.6592 9.79335 31.5237 8.39265 31.5237C6.99194 31.5237 5.85645 32.6592 5.85645 34.0599C5.85645 35.4606 6.99194 36.5961 8.39265 36.5961Z"
        :fill="color"
      />
      <path
        class="fill"
        d="M4.23806 29.7724L4.10441 29.9061C3.75294 30.2576 3.75294 30.8274 4.10441 31.1789L11.2741 38.3485C11.6255 38.7 12.1954 38.7 12.5468 38.3485L12.6805 38.2149C13.032 37.8634 13.032 37.2936 12.6805 36.9421L5.51085 29.7724C5.15938 29.421 4.58953 29.421 4.23806 29.7724Z"
        :fill="color"
      />
      <path
        class="color fill"
        d="M42.0526 36.5963C43.4533 36.5963 44.5888 35.4608 44.5888 34.0601C44.5888 32.6594 43.4533 31.5239 42.0526 31.5239C40.6519 31.5239 39.5164 32.6594 39.5164 34.0601C39.5164 35.4608 40.6519 36.5963 42.0526 36.5963Z"
        :fill="color"
      />
      <path
        class="color fill"
        d="M44.9337 29.7701L37.7641 36.9398C37.4126 37.2912 37.4126 37.8611 37.7641 38.2126L37.8977 38.3462C38.2492 38.6977 38.819 38.6977 39.1705 38.3462L46.3402 31.1766C46.6916 30.8251 46.6916 30.2553 46.3402 29.9038L46.2065 29.7701C45.855 29.4187 45.2852 29.4187 44.9337 29.7701Z"
        :fill="color"
      />
      <path
        class="stroke"
        d="M49.2078 36.9733C50.7832 33.17 48.9771 28.8098 45.1738 27.2344C41.3706 25.659 37.0103 27.4651 35.435 31.2684C33.8596 35.0716 35.6657 39.4319 39.4689 41.0072C43.2722 42.5826 47.6324 40.7765 49.2078 36.9733Z"
        :stroke="color"
        stroke-miterlimit="100"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ComponentDrone',
  props: {
    color: {
      type: String
    }
  }
}
</script>

<style></style>
