export const state = {
  lon: 0,
  lat: 0,
  magnetic: null,
  temperature: null,
  humidity: null,
  windDirection: null,
  windSpeed: null,
  cloudiness: null
}
export const getters = {
  getWeather(state) {
    return state
  }
}
export const mutations = {
  setWeatherData(state, weather) {
    state.lon = weather.coord[0]
    state.lat = weather.coord[1]
    state.magnetic = weather.magVal
    state.temperature = `${weather.weatherData.main.temp} ℃`
    state.humidity = `${weather.weatherData.main.humidity} %`
    state.windDirection = `${weather.weatherData.wind.deg} deg`
    state.windSpeed = `${weather.weatherData.wind.speed} m/s`
    state.cloudiness = weather.weatherData.clouds.all
  }
}
export const actions = {
  async setWeather({ commit }, weatherInfo) {
    commit('setWeatherData', weatherInfo)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
