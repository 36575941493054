import TokenUtils from '@/services/auth/Token'
import ConfigUtil from '@/services/common/Config'
import axios from 'axios'

const API_URL = ConfigUtil.getServiceUrl() + 'lx/'

class LxUtils {
  async login(loginData) {
    try {
      const result = await axios({
        method: 'POST',
        url: API_URL + 'login',
        data: {
          id: loginData.id,
          password: loginData.password
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async logout(droneInfo) {
    try {
      const token = await TokenUtils.getAccessToken()
      const lxAcToken = localStorage.getItem(`${droneInfo.name}_lxAcToken`)

      await axios({
        method: 'POST',
        url: API_URL + 'logout',
        headers: { gcsAcToken: token, lxAcToken: lxAcToken },
        data: {
          droneName: droneInfo.name
        }
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async getPhotoPlan(droneInfo) {
    try {
      const token = await TokenUtils.getAccessToken()
      const lxAcToken = localStorage.getItem(`${droneInfo.name}_lxAcToken`)

      const result = await axios({
        method: 'GET',
        url: API_URL + 'photo_plan_list',
        headers: { gcsAcToken: token, lxAcToken: lxAcToken },
        params: {
          droneAe: droneInfo.ae,
          mqttHost: droneInfo.host,
          droneName: droneInfo.name,
          droneSysId: droneInfo.sysId
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async flightInfo(droneInfo, potogrf_sn) {
    try {
      const token = await TokenUtils.getAccessToken()
      const lxAcToken = localStorage.getItem(`${droneInfo.name}_lxAcToken`)

      const result = await axios({
        method: 'POST',
        url: API_URL + 'flight_info',
        headers: { gcsAcToken: token, lxAcToken: lxAcToken },
        data: {
          droneName: droneInfo.name,
          potogrfSn: potogrf_sn
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async start(videoType, droneName, potogrfSn) {
    try {
      const token = await TokenUtils.getAccessToken()
      const lxAcToken = localStorage.getItem(`${droneName}_lxAcToken`)

      const result = await axios({
        method: 'POST',
        url: API_URL + 'start',
        headers: { gcsAcToken: token, lxAcToken: lxAcToken },
        data: {
          videoType: videoType,
          droneName: droneName,
          potogrfSn: potogrfSn
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }

  async stop(droneName) {
    try {
      const token = await TokenUtils.getAccessToken()
      const lxAcToken = localStorage.getItem(`${droneName}_lxAcToken`)

      const result = await axios({
        method: 'POST',
        url: API_URL + 'stop',
        headers: { gcsAcToken: token, lxAcToken: lxAcToken },
        data: {
          droneName: droneName
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }
}
export default new LxUtils()
