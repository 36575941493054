<template>
  <div class="modal-dim on" v-show-modal-alert>
    <div class="modal-wrap">
      <div class="modal-inner center">
        <div class="modal log-page">
          <div class="modal-head">
            <h2>Information of Drone Name</h2>
            <i class="i-close-20" @click="fnCallBack"></i>
          </div>
          <div class="modal-con">
            <div class="drone-info">
              <p>WayPoint Info</p>
              <xmp class="scroll-y"
                >{{ waypointText }}
              </xmp>
            </div>
          </div>
          <div class="modal-btn">
            <div class="btn-wrap">
              <div class="input-file">
                <div class="form row">
                  <label>
                    <!--파일 셀렉트 구조 이렇게 짜는 게 아닌 거 같은데 이렇게 두어도 될까요 TT?-->
                    <input type="file" id="importWp" accept=".waypoints" @change="importWayPoint($event)" ref="file" />
                    <span class="label">Attach WP Files</span>
                    <!--말줄임-->
                  </label>
                </div>
              </div>
              <button class="btn color" @click="saveWpList">Import</button>
              <button class="btn color" @click="saveFile">Export</button>
              <button class="btn gray" @click="fnCallBack">Cancel</button>
            </div>
            <div class="btn-wrap" style="justify-content: flex-end"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useModalState } from '@/common/state/ModalState'
import $ from 'jquery'

export default {
  name: 'drone-logs',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    wpText: {},
    droneName: {},
    waypointList: {},
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert, fnBaseConfirm } = useModalState()
    const { dispatch } = useStore()

    const state = reactive({
      waypointText: props.wpText,
      waypointFile: ''
    })
    const fnCallBack = event => {
      props.callBackCancel(proxy, event)
    }

    const saveFile = () => {
      var blob = new Blob([props.wpText], { type: 'text/plain' })
      var objURL = window.URL.createObjectURL(blob)

      if (window.__Xr_objURL_forCreatingFile__) {
        window.URL.revokeObjectURL(window.__Xr_objURL_forCreatingFile__)
      }
      window.__Xr_objURL_forCreatingFile__ = objURL
      var a = document.createElement('a')
      a.download = 'test.waypoints'
      a.href = objURL

      a.click()
    }

    const importWayPoint = e => {
      // TODO:
      //* 1. 현재 import를 진행하면, 전에 등록되어 있던 wapoints들은 삭제되므로 import전에 삭제 확인 alert 추가 필요
      //* 2. 현재 홈포지션이 0번째 index에 해당되어 있는데, 다음 버전에서 변경 예정
      //* 3. 예외처리 필요한 항목: 홈포지션 외 waypoint가 없을 때, 확장자는 맞지만 내용이 waypoint와 맞지 않을 때
      state.waypointFile = e.target.files[0]
      const waypointName = $('#importWp').val()
      if (waypointName != '') {
        const extension = waypointName
          .split('.')
          .pop()
          .toLowerCase()
        if ($.inArray(extension, ['waypoints']) == -1) {
          alert('등록할 수 없는 파일명입니다.')
          state.waypointFile = null
          $('#importWp').val('')
          return
        }
      }

      const loader = new FileReader()
      loader.onload = function(e) {
        if (e.target.readyState !== 2) return
        if (e.target.error) {
          console.log(e.target.error)
          return
        }
        state.waypointText = e.target.result
      }
      loader.readAsText(state.waypointFile)
    }

    const saveWpList = async () => {
      await fnBaseConfirm('주의', '기존 waypoints는 삭제됩니다.', '', async () => {
        const wpArray = []
        const remainWp = props.waypointList.filter(item => item.drone !== props.droneName)
        remainWp.forEach(item => {
          wpArray.push(item)
        })
        const waypointList = state.waypointText.split('\n')
        let tempGotoId = new Date().getTime()
        for (let i = 2; i < waypointList.length; i++) {
          const waypoint = waypointList[i].split(' ')
          let obj = {
            id: tempGotoId++,
            pointType: 'move',
            lat: parseFloat(waypoint[8]),
            lng: parseFloat(waypoint[9]),
            alt: parseFloat(waypoint[10]),
            spd: 5,
            drone: props.droneName,
            select: false,
            cuAt: waypoint[0],
            latLng: {
              lat: parseFloat(waypoint[8]),
              lng: parseFloat(waypoint[9])
            },
            turns: 0,
            radius: 0,
            rate: 0
          }
          wpArray.push(obj)
        }
        await proxy.$MavlinkUtils.saveGoto(wpArray)
        await dispatch('local/setWpRefresh', true)
        await props.callBackCancel(proxy)
        await fnModalAlert('알람', 'waypoints를 불러왔습니다.', '')
      })
    }
    return {
      fnCallBack,
      saveFile,
      saveWpList,
      importWayPoint,
      ...toRefs(state)
    }
  }
}
</script>

<style scoped></style>
