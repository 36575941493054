const routeState = {
  isHeader: false,
  titleName: '',
  isBack: false,
  rightNav: true,
  rightNavTop: false,
  rightNavBot: false
}

export const state = {
  isLoading: false,
  isOn: true,
  isFocus: false,
  isFnShow: false,
  mapType: 2,
  map: null,
  modeTabValue: '',
  modalAlertComponents: [],
  modalComponents: [],
  activeLayers: [],
  routeLayout: {
    ...routeState
  },
  mainComponent: null
}
export const getters = {
  getIsLoading: state => {
    return state.isLoading
  },
  getModeTabValue: state => {
    return state.modeTabValue
  },
  getIsOn: state => {
    return state.isOn
  },
  getIsFocus: state => {
    return state.isFocus
  },
  getIsFnShow: state => {
    return state.isFnShow
  },
  getModalAlertComponents: state => {
    return state.modalAlertComponents
  },
  getModalComponents: state => {
    return state.modalComponents
  },
  getRouteLayout: state => {
    return state.routeLayout
  },
  getMapType: state => {
    return state.mapType
  },
  getMap: state => {
    return state.map
  },
  getActiveLayers: state => {
    return state.activeLayers
  },
  getMainComponent: state => {
    return state.mainComponent
  }
}
export const mutations = {
  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading
  },
  setIsOn(state, { isOn = true }) {
    state.isOn = isOn
  },
  setModeTabValue(state, mode) {
    state.modeTabValue = mode
  },
  setIsFocus(state, { isFocus = false }) {
    state.isFocus = isFocus
  },
  setModalAlertComponents(state, { modalAlertComponents }) {
    state.modalAlertComponents = modalAlertComponents
  },
  setModalComponents(state, { modalComponents }) {
    state.modalComponents = modalComponents
  },
  setRouteLayout(
    state,
    { isHeader = undefined, titleName = undefined, isBack = undefined, rightNav = undefined, rightNavTop = undefined, rightNavBot = undefined }
  ) {
    if (isHeader !== undefined) state.routeLayout.isHeader = isHeader
    if (titleName !== undefined) state.routeLayout.titleName = titleName
    if (isBack !== undefined) state.routeLayout.isBack = isBack
    if (rightNav !== undefined) state.routeLayout.rightNav = rightNav
    if (rightNavTop !== undefined) state.routeLayout.rightNavTop = rightNavTop
    if (rightNavBot !== undefined) state.routeLayout.rightNavBot = rightNavBot
  },
  setTitleName(state, { titleName }) {
    state.routeLayout.titleName = titleName
  },
  setMapType(state, { mapType = 2 }) {
    state.mapType = mapType
  },
  setMap(state, map) {
    state.map = map
  },
  setActiveLayers(state, layer) {
    state.activeLayers = layer
  },
  setMainComponent(state, mainComponent) {
    state.mainComponent = mainComponent
  }
}
export const actions = {
  updateIsLoading({ commit }, { isLoading = false }) {
    commit('setIsLoading', { isLoading })
  },
  pushModalAlertComponent({ state, commit }, payload) {
    state.modalAlertComponents.push(payload)
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    })
    // payload.fnShow = !payload.fnShow
    // console.log(payload.fnShow)
  },
  updateModeTab({ commit }, mode) {
    commit('setModeTabValue', mode)
  },
  removeAllModalAlertComponent({ commit }) {
    commit('setModalAlertComponents', {
      modalAlertComponents: []
    })
  },
  closeModalAlertComponent({ state, commit }, index = 0) {
    const length = state.modalAlertComponents.length - 1
    state.modalAlertComponents.splice(length, 1)
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    })
  },
  pushModalComponent({ state, commit }, payload) {
    state.modalComponents.push(payload)
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    })
  },
  removeAllModalComponent({ commit }) {
    commit('setModalComponents', {
      modalComponents: []
    })
  },
  closeModalComponent({ state, commit }, { index = 0 }) {
    state.modalComponents.splice(index, 1)
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    })
  },
  updateRouteLayout({ commit }, payload) {
    commit('setRouteLayout', payload)
  },
  updateMapType({ commit }, { mapType = 2 }) {
    commit('setMapType', { mapType: mapType })
  },
  updateMap({ commit }, map) {
    commit('setMap', map)
  },
  updateActiveLayers({ commit }, layer) {
    commit('setActiveLayers', layer)
  },
  updateMainComponent({ commit }, mainComponent) {
    commit('setMainComponent', mainComponent)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
