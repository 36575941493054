const initialState = () => ({
  sseAccount: []
})

export const state = initialState

export const getters = {
  getSseAccounts: state => state.sseAccount
}
export const mutations = {
  setLxAccount(state, value) {
    const isExist = state.sseAccount.find(account => account.droneName == value.droneName)
    if (isExist) {
      state.sseAccount.map(account => {
        if (account.droneName == value.droneName) {
          account.lxuser = value.lxuser
          account.sessionId = value.sessionId
        }
      })
    } else {
      state.sseAccount.push({ lxuser: value.lxuser, droneName: value.droneName, sessionId: '' })
    }
  },
  setSseId(state, value) {
    const isExist = state.sseAccount.find(account => account.droneName == value.droneName)
    if (isExist) {
      state.sseAccount.map(account => {
        if (account.droneName == value.droneName) {
          account.sessionId = value.sessionId
        }
      })
    }
  },
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => {
      state[key] = initial[key]
    })
  }
}
export const actions = {
  saveLxAccount({ commit }, account) {
    commit('setLxAccount', account)
  },
  saveSseId({ commit }, account) {
    commit('setSseId', account)
  },
  reset({ commit }) {
    commit('resetState')
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
