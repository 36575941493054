import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
import Util from '@/common/utils/Util'
import Token from '@/services/auth/Token'
import { useModalState } from '@/common/state/ModalState'
const { fnModalAlert } = useModalState()

const updateRouteLayout = async meta => {
  await store.dispatch('layout/updateRouteLayout', {
    isHeader: meta.isHeader,
    titleName: meta.titleName,
    isBack: meta.isBack,
    isNav: meta.isNav,
    rightNav: meta.rightNav
  })
}

const fnBeforeEnter = async (to, from, next) => {
  if (from.name !== 'SignInIndex') {
    const gcsAcToken = localStorage.getItem('gcsAcToken')
    const expired = await Token.isTokenExpired(gcsAcToken)
    if (expired) {
      localStorage.clear()
      next({ path: '/signin', replace: true })
    } else {
      next()
    }
  } else {
    next()
  }
}

const routes = [
  {
    path: '/',
    beforeEnter: function(to, from, next) {
      if (Util.isApp()) {
        Util.nativeGetRegistrationKey('nativeSetDeviceInfo')
      } else {
        next({ path: '/main' /*, query: { isRoot: 1 } */ })
      }
    }
  },
  {
    path: '/signin',
    name: 'SignInIndex',
    component: () => import('@/views/sign/in'),
    meta: {
      isHeader: false,
      isNav: false,
      isBack: false,
      rightNav: false,
      titleName: 'SignIn'
    }
  },
  {
    path: '/signup',
    name: 'SignUpIndex',
    component: () => import('@/views/sign/up'),
    meta: {
      isHeader: false,
      isNav: false,
      isBack: false,
      rightNav: false,
      titleName: 'SignUp'
    }
  },
  {
    path: '/main',
    name: 'MainIndex',
    component: () => import('@/views/main/index.vue'),
    meta: {
      isHeader: true,
      isNav: true,
      isBack: false,
      rightNav: true,
      titleName: 'Home'
    },
    beforeEnter: fnBeforeEnter
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/test.vue'),
  },
  {
    description: 'Redirect (wildCard)',
    path: '/:pathMatch(.*)*',
    name: 'No Page Found',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 }
    // return new Promise(resolve => {
    //   setTimeout(() => {
    //     if (savedPosition) {
    //       resolve(savedPosition)
    //     } else {
    //       resolve({ left: 0, top: 0 })
    //     }
    //   }, 500)
    // })
  }
})

window.routeQuery = ''
window.path = ''

router.beforeEach(async (to, from, next) => {
  let meta = to.meta
  await updateRouteLayout(meta)
  window.routeQuery = to.query
  window.path = to.path
  next()
})

export default router