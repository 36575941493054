<template>
  <div class="modal-dim" v-show-modal-alert style="z-index: 1000">
    <div class="order-component bg1">
      <div class="header">
        <div class="header-inner">
          <p class="ttl">Marker Type:</p>
          <div>
            <label class="radio">
              <input type="radio" name="radio" :value="'move'" v-model="pointType" />
              <span class="label">Goto</span>
            </label>
            <label class="radio">
              <input type="radio" name="radio" :value="'loiter'" v-model="pointType" />
              <span class="label">Trun</span>
            </label>
            <label class="radio">
              <input type="radio" name="radio" :value="'roi'" v-model="pointType" />
              <span class="label">ROI</span>
            </label>
          </div>
        </div>
        <i class="i-close-20" @click="closeModal"></i>
      </div>
      <div class="order-component-inner" v-if="pointType !== 'roi'">
        <div class="form row">
          <p class="form-sub">Drone</p>
          <div class="label">
            <select class="unknown" v-model="drone">
              <option value="" selected>unknown</option>
              <option v-for="item in mainState.ctrlDroneList" :key="item.name" :value="item.name" selected> {{ item.name }}</option>
            </select>
          </div>
        </div>
        <div class="form row">
          <p class="form-sub">Index</p>
          <label class="label">
            <select v-if="drone === '' && modalType == '생성'" v-model="cuAt">
              <option value="0">0</option>
            </select>
            <select v-if="preDrone === '' && modalType == '수정'" v-model="cuAt">
              <option>{{ wpIndexLength }}</option>
            </select>
            <select id="diffDrone" v-if="drone !== '' && modalType == '생성'" v-model="cuAt">
              <option>{{ wpIndexLength }}</option>
            </select>
            <select id="diffDrone" v-if="drone !== '' && prePointType == 'roi' && modalType == '수정'" v-model="cuAt">
              <option>{{ wpIndexLength }}</option>
            </select>
            <select id="diffDrone" v-if="drone !== '' && prePointType !== 'roi' && preDrone !== '' && modalType == '수정'" v-model="cuAt">
              <option v-for="(data, index) in wpIndexLength" :key="index">{{ index + 1 }}</option>
            </select>
          </label>
        </div>
        <div class="form row">
          <p class="form-sub">Latitude</p>
          <label class="label">
            <input type="text" :value="lat" disabled="" />
          </label>
        </div>
        <div class="form row">
          <p class="form-sub">Longitude</p>
          <label class="label">
            <input type="text" :value="lng" disabled="" />
          </label>
        </div>
        <div class="form-wrap">
          <div class="form row">
            <p class="form-sub">비행고도<span style="font-size: 12px; margin:0">(m)</span></p>
            <label class="label">
              <input type="number" v-model="alt" />
            </label>
            <div class="btn-wrap">
              <button class="btn_up" @click="() => (alt = parseInt(alt) + 1)" style="margin-bottom:0"></button>
              <button class="btn_down" @click="() => (alt = parseInt(alt) - 1)"></button>
            </div>
          </div>

          <div class="slidecontainer">
            <VueSlider
              v-model="alt"
              min="0"
              max="1000"
              tooltip="always"
              tooltip-placement="bottom"
              :tooltip-style="tooltipStyle"
              :dot-size="dotSize"
              :dot-style="dotStyle"
              :process-style="processStyle"
            />
          </div>
        </div>
        <div class="form-wrap" v-show="pointType === 'move'">
          <div class="form row">
            <p class="form-sub">비행속도<span style="font-size: 12px; margin:0">(m/s)</span></p>
            <label class="label">
              <input type="text" v-model="spd" />
            </label>
            <div class="btn-wrap">
              <button class="btn_up" @click="() => (spd = parseInt(spd) + 1)" style="margin-bottom:0"></button>
              <button class="btn_down" @click="() => (spd = parseInt(spd) - 1)"></button>
            </div>
          </div>

          <div class="slidecontainer">
            <VueSlider
              v-model="spd"
              min="0"
              max="100"
              tooltip="always"
              tooltip-placement="bottom"
              :tooltip-style="tooltipStyle"
              :dot-size="dotSize"
              :dot-style="dotStyle"
              :process-style="processStyle"
            />
          </div>
        </div>
        <div class="form-wrap" v-show="pointType === 'loiter'">
          <div class="form row">
            <p class="form-sub">선회반지름<span style="font-size: 12px; margin:0">(m)</span></p>
            <label class="label">
              <input type="number" v-model="radius" />
            </label>
            <div class="btn-wrap">
              <button class="btn_up" @click="() => (radius = parseInt(radius) + 1)" style="margin-bottom:0"></button>
              <button class="btn_down" @click="() => (radius = parseInt(radius) - 1)"></button>
            </div>
          </div>

          <div class="slidecontainer">
            <VueSlider
              v-model="radius"
              min="0"
              max="500"
              tooltip="always"
              tooltip-placement="bottom"
              :tooltip-style="tooltipStyle"
              :dot-size="dotSize"
              :dot-style="dotStyle"
              :process-style="processStyle"
            />
          </div>
        </div>
        <div class="form-wrap" v-show="pointType === 'loiter'">
          <div class="form row">
            <p class="form-sub">선회속도<span style="font-size: 10px; margin:0">(m/rad)</span></p>
            <label class="label">
              <input type="text" v-model="turns" />
            </label>
            <div class="btn-wrap">
              <button class="btn_up" @click="() => (turns = parseInt(turns) + 1)" style="margin-bottom:0"></button>
              <button class="btn_down" @click="() => (turns = parseInt(turns) - 1)"></button>
            </div>
          </div>

          <div class="slidecontainer">
            <VueSlider
              v-model="turns"
              min="0"
              max="100"
              tooltip="always"
              tooltip-placement="bottom"
              :tooltip-style="tooltipStyle"
              :dot-size="dotSize"
              :dot-style="dotStyle"
              :process-style="processStyle"
            />
          </div>
        </div>
        <div class="form-wrap" v-show="pointType === 'loiter'">
          <div class="form row">
            <p class="form-sub">선회 횟수</p>
            <label class="label">
              <input type="number" v-model="turnNum" />
            </label>
            <div class="btn-wrap">
              <button class="btn_up" @click="() => (turnNum = parseInt(turnNum) + 1)" style="margin-bottom:0"></button>
              <button class="btn_down" @click="() => (turnNum = parseInt(turnNum) - 1)"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="order-component-inner" v-else>
        <div class="form row">
          <p class="form-sub">Drone</p>
          <div class="label">
            <select class="unknown" v-model="drone">
              <option value="" selected>unknown</option>
              <option v-for="item in mainState.ctrlDroneList" :key="item.name" :value="item.name" selected> {{ item.name }}</option>
            </select>
          </div>
        </div>
        <div class="form row">
          <p class="form-sub">Latitude</p>
          <label class="label">
            <input type="text" :value="lat" disabled="" />
          </label>
        </div>
        <div class="form row">
          <p class="form-sub">Longitude</p>
          <label class="label">
            <input type="text" :value="lng" disabled="" />
          </label>
        </div>
      </div>
      <div class="btn-wrap">
        <button class="btn gray" @click="closeModal">Cancel</button>
        <button class="btn color" @click="createWp">{{ modalType }}</button>
        <i class="i-set-delete" v-if="modalType !== '생성'" @click="deleteWp"></i>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { getCurrentInstance, reactive, toRefs, watch, onMounted } from 'vue'
import { useModalState } from '@/common/state/ModalState'
import { useStore } from 'vuex'
import $ from 'jquery'

export default {
  name: 'created-marker',
  components: {
    VueSlider
  },
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layer: {},
    wpInfo: {},
    mainState: {},
    modalType: {},
    coordinates: {},
    callBackMarkerCancel: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { dispatch, getters } = useStore()
    const { fnModalAlert } = useModalState()
    const state = reactive({
      markerType: 1,
      waypointList: props.mainState.waypointList,
      wpIndexLength:
        props.modalType == '생성'
          ? props.mainState.waypointList.filter(item => item.drone === props.wpInfo.drone && item.pointType !== 'roi').length + 1
          : props.mainState.waypointList.filter(item => item.drone === props.wpInfo.drone && item.pointType !== 'roi').length,
      selectedDrone: '',
      globalAlt: getters['drone/getGlobalAlt'],
      globalSpeed: getters['drone/getGlobalSpeed'],
      tooltipStyle: {
        color: '#ED7C2A',
        border: '1px solid #ED7C2A'
      },
      dotSize: 18,
      dotStyle: {
        backgroundColor: '#ED7C2A',
        borderColor: '#ED7C2A'
      },
      processStyle: {
        backgroundColor: '#ffe5c7'
      }
    })
    const waypointInfo = reactive({
      pointType: props.wpInfo.pointType ? props.wpInfo.pointType : 'move',
      id: props.wpInfo.id ? props.wpInfo.id : new Date().getTime(),
      lat: props.wpInfo.lat ? props.wpInfo.lat : parseFloat(props.coordinates.lat),
      lng: props.wpInfo.lng ? props.wpInfo.lng : parseFloat(props.coordinates.lng),
      alt: props.wpInfo.alt ? props.wpInfo.alt : state.globalAlt,
      spd: props.wpInfo.spd ? props.wpInfo.spd : state.globalSpeed,
      drone: props.wpInfo.drone ? props.wpInfo.drone : '',
      select: props.wpInfo.select ? props.wpInfo.select : false,
      cuAt: props.wpInfo.cuAt ? props.wpInfo.cuAt : 0,
      latLng: {
        lat: props.wpInfo.lat,
        lng: props.wpInfo.lng
      },
      turns: props.wpInfo.turns ? props.wpInfo.turns : 5,
      radius: props.wpInfo.radius ? props.wpInfo.radius : 20,
      modalType: props.modalType ? props.modalType : '생성',
      feature: props.wpInfo.feature ? props.wpInfo.feature : null,
      precuAt: props.wpInfo.cuAt ? props.wpInfo.cuAt : 0,
      preDrone: props.wpInfo.drone ? props.wpInfo.drone : '',
      prePointType: props.wpInfo.pointType ? props.wpInfo.pointType : '',
      turnNum: props.wpInfo.turnNum ? props.wpInfo.turnNum : 1
    })

    //* 드론 선택 관련
    watch(
      () => waypointInfo.drone,
      async droneName => {
        if (droneName) {
          const result = state.waypointList.filter(wp => wp.drone === droneName && wp.pointType !== 'roi')
          state.wpIndexLength = result.length + 1
          waypointInfo.cuAt = result.length + 1
        }

        //* 드론 선택 시 변경점 확인
        if (waypointInfo.preDrone) {
          return
        } else if (droneName !== waypointInfo.preDrone) {
          waypointInfo.cuAt = state.wpIndexLength
          $('#diffDrone').prop('disabled', true)
        } else if (droneName == waypointInfo.preDrone) {
          waypointInfo.cuAt = waypointInfo.precuAt
          $('#diffDrone').prop('disabled', false)
        }
      }
    )

    onMounted(() => {
      if (waypointInfo.prePointType == 'roi') {
        const result = state.waypointList.filter(wp => wp.drone === waypointInfo.drone)
        state.wpIndexLength = result.length
        waypointInfo.cuAt = result.length
      }
    })

    //* 팝업 닫기
    const closeModal = () => {
      proxy.$emit('close', props.index)
    }

    //* waypoint 생성
    const createWp = async () => {
      let modifiedWpInfo = {
        pointType: waypointInfo.pointType,
        id: waypointInfo.id,
        lat: waypointInfo.lat,
        lng: waypointInfo.lng,
        alt: waypointInfo.alt,
        spd: waypointInfo.spd,
        drone: waypointInfo.drone,
        select: false,
        cuAt: waypointInfo.cuAt,
        latLng: {
          lat: waypointInfo.lat,
          lng: waypointInfo.lng
        },
        turns: waypointInfo.turns,
        radius: waypointInfo.radius,
        turnNum: waypointInfo.turnNum
      }
      try {
        //* Global 속도, 고도 설정
        await dispatch('drone/globalAlt', modifiedWpInfo.alt)
        await dispatch('drone/globalSpeed', modifiedWpInfo.spd)

        if (waypointInfo.pointType == 'roi') {
          modifiedWpInfo = {
            pointType: waypointInfo.pointType,
            id: waypointInfo.id,
            lat: waypointInfo.lat,
            lng: waypointInfo.lng,
            drone: waypointInfo.drone,
            select: false,
            latLng: {
              lat: waypointInfo.lat,
              lng: waypointInfo.lng
            }
          }
        }

        if (waypointInfo.modalType == '생성') {
          state.waypointList.push(modifiedWpInfo)
          await proxy.$MavlinkUtils.saveGoto(state.waypointList)
          dispatch('local/setWpRefresh', true)
          proxy.$emit('close', props.index)
        } else if (waypointInfo.modalType == '수정') {
          //* 드론 선택 안할 시, cuAt 0
          if (!modifiedWpInfo.drone) modifiedWpInfo.cuAt = 0
          let wpIdx

          //* 기존 데이터와 같을때
          if (modifiedWpInfo.cuAt == waypointInfo.precuAt && modifiedWpInfo.drone == waypointInfo.preDrone) {
            wpIdx = state.waypointList.findIndex(wp => wp.cuAt == waypointInfo.cuAt && wp.drone == modifiedWpInfo.drone)
            state.waypointList[wpIdx] = modifiedWpInfo
            //* cuAt이 0일때
          } else if (!waypointInfo.preDrone) {
            wpIdx = state.waypointList.findIndex(wp => wp.id == waypointInfo.id)
            state.waypointList[wpIdx] = modifiedWpInfo
            //* 드론 SAME, cuAt DIFF
          } else if (modifiedWpInfo.cuAt !== waypointInfo.precuAt && modifiedWpInfo.drone == waypointInfo.preDrone) {
            if (waypointInfo.prePointType !== 'roi') {
              const idx1 = state.waypointList.findIndex(wp => wp.cuAt == modifiedWpInfo.cuAt && wp.drone == modifiedWpInfo.drone)
              const idx2 = state.waypointList.findIndex(wp => wp.cuAt == waypointInfo.precuAt && wp.drone == modifiedWpInfo.drone)
              state.waypointList[idx1].cuAt = waypointInfo.precuAt
              state.waypointList[idx2] = modifiedWpInfo
            } else {
              wpIdx = state.waypointList.findIndex(wp => wp.id == modifiedWpInfo.id)
              state.waypointList[wpIdx] = modifiedWpInfo
            }
            //* 드론 DIFF, cuAt SAME
          } else if (modifiedWpInfo.drone !== waypointInfo.preDrone) {
            let modifiedWpList = []
            // 남아있는 waypoint
            const changedWp = state.waypointList.filter(wp => wp.drone == waypointInfo.preDrone && wp.cuAt < waypointInfo.precuAt)
            // 변경되는 waypoint
            const remainWp = state.waypointList.filter(wp => wp.drone == waypointInfo.preDrone && wp.cuAt > waypointInfo.precuAt)
            // 추가되는 waypoint
            const wpList = state.waypointList.filter(wp => wp.drone !== waypointInfo.preDrone)
            //? wp cuat 재설정
            await remainWp.forEach(element => {
              element.cuAt = element.cuAt - 1
              modifiedWpList.push(element)
            })
            wpList.push(modifiedWpInfo)

            for (let item of changedWp) {
              modifiedWpList.push(item)
            }
            for (let item of wpList) {
              modifiedWpList.push(item)
            }
            state.waypointList = modifiedWpList
          }
          await proxy.$MavlinkUtils.saveGoto(state.waypointList)
          await dispatch('local/setWpRefresh', true)
          proxy.$emit('close', props.index)
          await fnModalAlert('알람', 'WayPoint가 수정되었습니다.', '')
        }
      } catch (error) {
        await fnModalAlert('에러', 'WAYPOINT 수정중 에러가 발생했습니다.', '')
        throw new Error(error)
      }
    }

    //* waypoint 삭제
    const deleteWp = async () => {
      try {
        const idx = state.waypointList.findIndex(wp => wp.id === props.wpInfo.id)
        if (props.wpInfo.pointType == 'roi') {
          if (idx > -1) state.waypointList.splice(idx, 1)
          await proxy.$MavlinkUtils.saveGoto(state.waypointList)
        } else {
          if (state.waypointList[idx].cuAt !== 0) {
            let modifiedWpList = []
            const diffDroneWp = state.waypointList.filter(wp => wp.drone !== waypointInfo.drone)
            const sameDroneWp = state.waypointList.filter(wp => wp.drone == waypointInfo.drone)
            const idx = sameDroneWp.findIndex(wp => wp.id === props.wpInfo.id)
            sameDroneWp.forEach(wp => {
              if (wp.cuAt > props.wpInfo.cuAt) {
                wp.cuAt = wp.cuAt - 1
              }
            })
            sameDroneWp[idx].cuAt = 0
            sameDroneWp[idx].color = 'gray'
            sameDroneWp[idx].drone = ''
            for (let wp of diffDroneWp) {
              modifiedWpList.push(wp)
            }
            for (let wp of sameDroneWp) {
              modifiedWpList.push(wp)
            }
            await proxy.$MavlinkUtils.saveGoto(modifiedWpList)
          } else {
            if (idx > -1) state.waypointList.splice(idx, 1)
            await proxy.$MavlinkUtils.saveGoto(state.waypointList)
          }
        }

        await dispatch('local/setWpRefresh', true)
        proxy.$emit('close', props.index)
        await fnModalAlert('삭제', 'WAYPOINT가 삭제되었습니다.', '')
      } catch (error) {
        await fnModalAlert('에러', 'WAYPOINT 삭제중 에러가 발생했습니다.', '')
        throw new Error(error)
      }
    }

    //* 슬라이더 설정
    const fnSlider = (min, max, val) => {
      return `linear-gradient(90deg, #E0E0E0 0%, #E0E0E0 ${((val - min) / (max - min)) * 100}%, #FFE5C7 ${((val - min) / (max - min)) *
        100}%, #FFE5C7 100%) !important`
    }
    return {
      ...toRefs(state),
      ...toRefs(waypointInfo),
      createWp,
      deleteWp,
      fnSlider,
      closeModal
    }
  }
}
</script>

<style scoped></style>
