<template>
  <div class="modal-dim on">
    <!--'on 추가'-->
    <div class="set-window-wrap">
      <div class="set-window">
        <div class="set-window-top">
          <div class="set-window-sub">
            <div class="sub">
              <i class="i-setting"></i>
              <h2>Setting</h2>
            </div>
            <div class="close" @click="fnCallBackCancel">
              <i class="i-close-26"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con">
          <div class="set-sub"><h3>Drone Option</h3></div>
          <section class="sect drone-set">
            <!--JY: 이 페이지는 업체측에서도 내용을 제대로 준 게 아니에요. 인풋이랑 토글 옵션만 달라고 했습니다. 크게 신경쓰지 않으셔도 될 것 같습니다.-->
            <div class="sect mt24">
              <div class="form column mb24">
                <p class="form-sub">Input Option</p>
                <label class="label">
                  <select name="" id="">
                    <option value="">기본옵션</option>
                  </select>
                </label>
              </div>
              <div class="form column">
                <p class="form-sub">Toggle Option</p>
                <div class="toggle">
                  <span class="ttl">OFF</span>
                  <label class="switch">
                    <input type="checkbox" checked="" />
                    <span class="slider"></span>
                  </label>
                  <span class="ttl">ON</span>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="btn-wrap">
          <button class="btn color" @click="fnCallBackDone">Confirm</button>
        </div>
      </div>
    </div>
  </div></template
>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import useVuelidate from '@vuelidate/core'
import { useStore } from 'vuex'

export default {
  name: 'options-index',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    doneText: {
      type: [String],
      default: 'CONFIRM'
    },
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { dispatch } = useStore()

    const fnCallBackCancel = async () => {
      await dispatch('layout/closeModalAlertComponent', 1)
    }

    const fnCallBackDone = event => {
      // Options 변경 관련 API
      props.callBackDone(proxy, event)
    }

    return {
      fnCallBackCancel,
      fnCallBackDone
    }
  }
}
</script>

<style scoped></style>
