import axios from 'axios'
import configUtil from '@/services/common/Config'

const API_URL = configUtil.getServiceUrl()

class TokenUtils {
  // async await 로 변경
  async getAccessToken() {
    const gcsAcToken = localStorage.getItem('gcsAcToken')
    if (!gcsAcToken) {
      return null
    } else {
      const res = await this.isTokenExpired(gcsAcToken)
      if (res) {
        //로그인 세션 비유효
        localStorage.clear()
        location.replace('/')
        return null
        // await this.requestReissueAccessToken().then(function(res2) {
        //   const acToken = res2.config.headers.gcsretoken
        //   if (!acToken) {
        //     return null
        //   }
        //   localStorage.setItem('gcsAcToken', acToken)
        //   return gcsAcToken
        // })
      } else {
        //로그인 세션 유효
        return gcsAcToken
      }
    }
  }

  async requestReissueAccessToken() {
    const res = await axios({
      method: 'POST',
      url: API_URL + 'auth/reissue',
      headers: { gcsretoken: this.getRefreshToken() }
    })

    if (!res.data) {
      throw new Error('로그인 세션이 만료되었습니다. 다시 로그인해주세요')
    } else {
      localStorage.setItem('gcsReToken', res.config.headers.gcsretoken)
      // localStorage.setItem('gcsAcToken', res.data.gcsAcToken)
      return res
    }
  }

  getRefreshToken() {
    const gcsReToken = localStorage.getItem('gcsReToken')
    if (!gcsReToken) {
      throw new Error('로그인 세션이 만료되었습니다. 다시 로그인해주세요')
    } else {
      const isToken = this.isTokenExpired(gcsReToken)
      if (!isToken) {
        throw new Error('로그인 세션이 만료되었습니다. 다시 로그인해주세요')
      }
      return gcsReToken
    }
  }

  async isTokenExpired(token) {
    try {
      const jwt = require('jsonwebtoken')
      const config = await (await fetch(process.env.BASE_URL + 'config.json')).json()
      jwt.verify(token, config.default.jwt.SECRET)
      return false
    } catch (error) {
      return true
    }
  }
}

export default new TokenUtils()
