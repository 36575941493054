import TokenUtils from '@/services/auth/Token'
import ConfigUtil from '@/services/common/Config'
import axios from 'axios'

const API_URL = ConfigUtil.getServiceUrl() + 'system/'

class SystemUtils {
  async getGcsSettings() {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'GET',
        url: API_URL + 'setting',
        headers: { gcsAcToken: token }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }
  async setChannel(channel) {
    try {
      const token = await TokenUtils.getAccessToken()
      await axios({
        method: 'POST',
        url: API_URL + 'setting/channel/set',
        headers: { gcsAcToken: token },
        data: {
          channel: channel
        }
      })
    } catch (error) {
      throw new Error(error)
    }
  }
  async setExtent(extent) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'POST',
        url: API_URL + 'setting/extent',
        headers: { gcsAcToken: token },
        data: {
          extent: extent
        }
      })

      return result.data.extent
    } catch (error) {
      throw new Error(error)
    }
  }
  async setTelemetry(telemetryObj) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'POST',
        url: API_URL + 'setting/channel/telemetry',
        headers: { gcsAcToken: token },
        data: {
          config: telemetryObj
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }
  async setTransmitter(transmitterObj) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'POST',
        url: API_URL + 'setting/channel/transmitter',
        headers: { gcsAcToken: token },
        data: {
          config: transmitterObj
        }
      })
      return result
    } catch (error) {
      throw new Error(error)
    }
  }
}
export default new SystemUtils()
