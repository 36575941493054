<template>
  <div class="modal-dim on">
    <!--'on 추가'-->
    <div class="set-window-wrap">
      <div class="set-window small" style="background: #fafcfb;">
        <div class="set-window-top">
          <div class="set-window-sub">
            <div class="sub">
              <i class="i-user"></i>
              <h2>회원정보</h2>
            </div>
            <div class="close" @click="fnCallBackCancel">
              <i class="i-close-26"></i>
            </div>
          </div>
        </div>
        <div class="set-window-con">
          <!-- <div class="set-sub"><h3>회원정보 수정</h3></div> -->
          <div class="sect" style="background: #fafcfb;">
            <!-- <div class="form row">
              <p class="form-sub">사용자 유형:</p>
              <button class="btn color">설계</button>
            </div> -->
            <div class="form column">
              <p class="form-sub" style="color: #2c3033">이메일</p>
              <label class="label">
                <!-- <input type="email" placeholder="E-mail 주소 입력" v-model="form.userEmail" /> -->
                <input type="email" :value="form.userEmail" disabled />
              </label>
              <p class="valid" v-if="valid.userEmail.$error">
                {{ msg.userEmail }}
              </p>
            </div>
            <div class="form column">
              <p class="form-sub" style="color: #2c3033">비밀번호*</p>
              <label class="label">
                <input type="password" placeholder="password" v-model="form.password" />
              </label>
              <p class="valid" v-if="valid.password.$error">{{ msg.password }}</p>
            </div>
          </div>
        </div>
        <div class="btn-wrap" style="background: #fafcfb​; border-radius: 0 0 1.2rem 1.2rem;">
          <button class="btn color" @click.prevent.stop="fnConfirm">
            수정
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs, computed } from 'vue'
import { useStore } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { useModalState } from '@/common/state/ModalState'

export default {
  name: 'user-step1',
  props: {
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    doneText: {
      type: [String],
      default: 'CONFIRM'
    },
    cancelText: {
      type: [String],
      default: 'CANCEL'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert } = useModalState()
    const { getters } = useStore()

    const page = reactive({
      form: {
        userEmail: computed(() => {
          return getters['user/getUserData'].u_e
        }),
        userName: computed(() => {
          return getters['user/getUserData'].u_n
        }),
        password: ''
      },
      msg: {
        userEmail: '',
        password: ''
      }
    })

    const rules = {
      userEmail: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            page.msg.userEmail = '이메일을 입력하지 않았습니다. 입력 후, 다시 시도해주세요.'
            return false
          }
          return true
        }
      },
      password: {
        test: function(val) {
          if (proxy.$Util.isEmpty(val)) {
            page.msg.password = '비밀번호를 입력하지 않았습니다. 입력 후, 다시 시도해주세요.'
            return false
          }
          if (!proxy.$Util.isPasswordValid(val)) {
            page.msg.password = '6자리 이상 [영문+숫자]로 비밀번호를 입력하지 않았습니다.  입력 후, 다시 시도해주세요.'
            return false
          }
          return true
        }
      }
    }
    const valid = useVuelidate(rules, page.form)

    const fnConfirm = async event => {
      valid.value.$touch()
      if (valid.value.$invalid) return
      // 아이디 동일한지 확인 API
      try {
        await proxy.$AuthUtils.checkPassword(page.form.password)
        props.callBackDone(proxy, page.form)
      } catch (error) {
        await fnModalAlert('알람', '회원정보가 맞지 않습니다.', '')
      }
    }

    const fnCallBackCancel = event => {
      props.callBackCancel(proxy, event)
    }

    return {
      ...toRefs(page),
      fnConfirm,
      fnCallBackCancel,
      valid
    }
  }
}
</script>

<style scoped></style>
