import axios from 'axios'
import TokenUtils from '@/services/auth/Token'
import configUtils from '@/services/common/Config'

const API_URL = configUtils.getServiceUrl() + 'pattern/'

class PatternUtils {
  async savePattern(patternInfo) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'POST',
        url: API_URL,
        headers: { gcsAcToken: token },
        data: patternInfo
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async getPattern() {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'GET',
        url: API_URL,
        headers: { gcsAcToken: token }
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async updatePattern(patternInfo) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'PUT',
        url: API_URL,
        headers: { gcsAcToken: token },
        data: patternInfo
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async deletePattern(patternId) {
    try {
      const token = await TokenUtils.getAccessToken()
      const result = await axios({
        method: 'DELETE',
        url: `${API_URL}${patternId}`,
        headers: { gcsAcToken: token }
      })
      return result
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }
}

export default new PatternUtils()
