import droneUtils from '@/services/drone/Drone'
import socketUtils from '@/services/common/Socket'

export const state = {
  mobius_connected: localStorage.getItem('mobius_connected'),
  droneList: '',
  wpRefresh: false,
  wpList: [],
  host: '' || localStorage.getItem('host'),
  ae: '' || localStorage.getItem('ae')
}
export const getters = {
  getMobiusConnected(state) {
    return state.mobius_connected
  },
  getHost(state) {
    return state.host
  },
  getAe(state) {
    return state.ae
  },
  getWpRefresh(state) {
    return state.wpRefresh
  },
  getWpList(state) {
    return state.wpList
  }
}
export const mutations = {
  setMobiusConnected(state, value) {
    state.mobius_connected = value
    localStorage.mobius_connected = value
  },
  setMobiusData(state, mobiusData) {
    state.host = mobiusData.host
    state.ae = mobiusData.ae
    localStorage.host = mobiusData.host
    localStorage.ae = mobiusData.ae
  },
  setWpRefresh(state, value) {
    state.wpRefresh = value
  },
  setWpList(state, wpList) {
    state.wpList = wpList
  },
  resetInitData(state) {
    state.mobius_connected = 'disconnected'
    state.host = ''
    state.ae = ''
  }
}
export const actions = {
  async connect({ commit }, mobiusData) {
    const result = await droneUtils.initDrone(mobiusData)
    // if (result.data.statusCode) {
    //   return result.data.statusCode
    // } else {
    //   commit('setMobiusConnected', 'connected')
    //   commit('setMobiusData', mobiusData)
    // }
    if (result.data.error) {
      if(result.data.error['m2m:dbg']) return 2
      else return 1
    } else {
      commit('setMobiusConnected', 'connected')
      commit('setMobiusData', mobiusData)
    }
  },
  async disconnect({ commit }, mobiusData) {
    commit('setMobiusConnected', 'disconnected')
    commit('setMobiusData', mobiusData)
    await socketUtils.close()
  },
  clearData({ commit }) {
    commit('resetInitData')
  },
  setWpRefresh({ commit }, value) {
    commit('setWpRefresh', value)
  },
  setWpList({ commit }, wpList) {
    commit('setWpList', wpList)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
