<template>
  <div class="modal-dim" v-show-modal-alert style="z-index: 1000">
    <div class="order-component bg1">
      <div class="header">
        <div class="header-inner">
          <p class="ttl">Geofence</p>
        </div>
        <i class="i-close-20" @click="closeModal"></i>
      </div>
      <div class="order-component-inner">
        <div class="form row">
          <p class="form-sub">Drone</p>
          <div class="label">
            <select class="unknown" v-model="drone">
              <option v-for="item in mainState.ctrlDroneList" :key="item.name" :value="item.name" selected> {{ item.name }}</option>
            </select>
          </div>
        </div>
        <div class="form row">
          <p class="form-sub">Type</p>
          <div class="label">
            <label class="radio">
              <input type="radio" name="radio1" :value="'circle'" v-model="geofenceType" />
              <span class="label">circle</span>
            </label>
            <label class="radio">
              <input type="radio" name="radio1" :value="'polygon'" v-model="geofenceType" />
              <span class="label">polygon</span>
            </label>
          </div>
        </div>
        <div class="form row">
          <p class="form-sub">방향</p>
          <div class="label" v-if="modalType == '생성'">
            <label class="radio">
              <input type="radio" name="radio2" :value="'true'" v-model="inclusion" />
              <span class="label">inclusion</span>
            </label>
            <label class="radio">
              <input type="radio" name="radio2" :value="'false'" v-model="inclusion" />
              <span class="label">exclusion</span>
            </label>
          </div>
          <div class="label" v-else>
            <label class="radio" >
              <input type="radio" name="radio2" :value="'true'" v-model="editInclusion" />
              <span class="label">inclusion</span>
            </label>
            <label class="radio">
              <input type="radio" name="radio2" :value="'false'" v-model="editInclusion" />
              <span class="label">exclusion</span>
            </label>
          </div>
        </div>
      </div>
      <div class="btn-wrap" v-if="modalType == '생성'">
        <button class="btn gray" @click="closeModal">Cancel</button>
        <button class="btn color" @click="createGeofence">{{ modalType }}</button>
      </div>
      <div class="btn-wrap" v-else >
        <button class="btn gray" @click="closeModal">Cancel</button>
        <button class="btn color" @click="modifyGeofence"> 수정 </button>
        <i class="i-set-delete" @click="deleteGeofence"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { useModalState } from '@/common/state/ModalState'

export default {
  name: 'geofence',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    GeofenceUpdate: {},
    layer: {},
    param: {},
    mainState: {},
    modalType: {},
    coordinates: {},
  },
  setup(props) {
    const { proxy } = getCurrentInstance()
    const { fnModalAlert } = useModalState()
    const state = reactive({
      layer: props.layer,
      drone: props.param ? props.param.droneName : props.mainState.ctrlDroneList[0].name,
      inclusion: 'true',
      editInclusion: props.param && props.param.name == '진출 불가' ? 'false' : 'true',
      geofenceType: props.param ? props.param.type : 'circle',
    })

    const closeModal = () => {
      proxy.$emit('close', props.index)
    }

    const createGeofence = async () => {
      try {
        let figureInfo = {}
        let position = {
          position: props.coordinates
        }
        if (state.geofenceType == 'circle') {
          figureInfo = {
            type: 'circle',
            inclusion: state.inclusion,
            state: true,
            position: props.coordinates,
            radius: 1000,
            droneName: state.drone,
            name: state.inclusion == 'false' ? '진출 불가' : '진입 불가',
          }
          await proxy.$GeofenceUtils.createGeofence(figureInfo)
        } else if (state.geofenceType == 'polygon') {
          const polygonPosition = [
            [
              [parseFloat(position.position.lng), parseFloat(position.position.lat)],
              [parseFloat(position.position.lng) - 0.05, parseFloat(position.position.lat)],
              [parseFloat(position.position.lng) - 0.05, parseFloat(position.position.lat) + 0.03],
              [parseFloat(position.position.lng), parseFloat(position.position.lat) + 0.03],
              [parseFloat(position.position.lng), parseFloat(position.position.lat)]
            ]
          ]
          figureInfo = {
            type: 'polygon',
            inclusion: state.inclusion,
            state: true,
            position: polygonPosition,
            violationAct: 4,
            violationLocation: 1,
            droneName: state.drone,
            name: state.inclusion == 'false' ? '진출 불가' : '진입 불가',
          }
          await proxy.$GeofenceUtils.createGeofence(figureInfo)
        }

        props.GeofenceUpdate() 
        closeModal()
        await fnModalAlert('알람', '지오팬스가 생성되었습니다.', '')
      } catch (error) {
        await fnModalAlert('오류', '지오팬스 생성중 오류가 발생했습니다.', '')
      }
    }

    const modifyGeofence = async () => {
      try {
        let position = props.param.position
        let geofenceType = props.param.type
        if(state.geofenceType == 'polygon' && geofenceType == 'circle') {
          position = [
            [
              [parseFloat(position.lng), parseFloat(position.lat)],
              [parseFloat(position.lng) - 0.05, parseFloat(position.lat)],
              [parseFloat(position.lng) - 0.05, parseFloat(position.lat) + 0.03],
              [parseFloat(position.lng), parseFloat(position.lat) + 0.03],
              [parseFloat(position.lng), parseFloat(position.lat)]
            ]
          ]
        } else if(state.geofenceType == 'circle' && geofenceType == 'polygon') {
          // 폴리곤 생성시 첫번째 선택지점이 오른쪽 아래 꼭지점 -> 수정시에도 오른쪽 아래 꼭지점이 원의 중심
          position = {lng: position[0][0][0], lat: position[0][0][1]}
        }
        let geofenceInfo = {
          droneName: state.drone,
          type: state.geofenceType,
          state: props.param.state,
          position: position,
          name: state.editInclusion == 'false' ? '진출 불가' : '진입 불가',
          inclusion: state.editInclusion,
          radius: props.param.radius ? props.param.radius : 1000,
          violationAct: props.param.violationAct ? props.param.violationAct : 4,
          violationLocation: props.param.violationLocation ? props.param.violationLocation : 1,
          _id: props.param.id,
        }
        await proxy.$GeofenceUtils.updateGeofence(geofenceInfo)
        closeModal()
        await updateGeofence()
        await fnModalAlert('알람', '지오팬스가 수정되었습니다.', '')
      } catch (error) {
        await fnModalAlert('오류', '지오팬스 수정중 오류가 발생했습니다.', '')
      }
    }

    const deleteGeofence = async () => {
      try {
        await proxy.$GeofenceUtils.deleteGeofence(props.param._id)
        await updateGeofence() 
        closeModal()
        await fnModalAlert('알람', '지오팬스가 삭제되었습니다.', '')
      } catch (error) {
        await fnModalAlert('오류', '지오팬스 삭제중 오류가 발생했습니다.', '')
      }
    }

    const updateGeofence = async () => {
      state.layer.geofenceLayer.getSource().clear()
      const geofenceList = await proxy.$GeofenceUtils.getAllGeofence()
      for (let geofence of geofenceList.data) {
        let figureInfo = geofence
        if (geofence.type == 'circle') {
          figureInfo = {
            id: geofence._id,
            droneName: geofence.droneName,
            name: geofence.name,
            type: 'circle',
            inclusion: geofence.inclusion,
            position: {
              lng: geofence.position.lng,
              lat: geofence.position.lat
            },
            radius: geofence.radius
          }
        } else {
          figureInfo = {
            id: geofence._id,
            droneName: geofence.droneName,
            name: geofence.name,
            type: 'polygon',
            inclusion: geofence.inclusion,
            position: geofence.position
          }
        }
        console.log(figureInfo, geofence)
        const feature = proxy.$OlUtils.createGeofenceFeatures(figureInfo, props.mainState.ctrlDroneList)
        feature.set('prop', figureInfo)
        state.layer.geofenceLayer.getSource().addFeature(feature)
      }
    }

    return {
      ...toRefs(state),
      closeModal,
      createGeofence,
      modifyGeofence,
      deleteGeofence,
      updateGeofence
    }
  }
}
</script>

<style scoped></style>
